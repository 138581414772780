import React from "react";
import { Button, Spin, Row, Col, Card, Modal } from 'antd';
import { DownloadOutlined } from "@ant-design/icons";
import "../styles/DrftReqStyles.css";
import "../styles/CommonStyles.css";
import {
  viewStudyText,
  newStudyText,
  drftReqText,
} from "../constants/displayText";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate } from "react-router-dom";
import { urls, pageLinks } from "../constants/urls";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";

export default function ReqDetailComponent({ userDet, reqData, id, filters, adminUser }) {
  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  const userType = localStorage.getItem("userType")
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {

  }, []);

  const jsonDwl = () => {
    const fileName = `request_${id}`
    const json = JSON.stringify(reqData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const cardClick = (study) => {
    navigate(`${pageLinks.VW_STUDY}/${Object.values(study._id)}`, {
      state: { details: { ...study } },
    });
  };
  const fileClick = (fName) => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let query = {
          filename: fName,
          request_id: id
        };
        let config = {
          headers: { Authorization: `Bearer ${response.accessToken}` },
          params: query,
          responseType: 'blob'
        };
        setLoading(true)
        axios.get(`${apiUrl}${urls.ADM_FILE_DOWNLOAD}`, config).then((response) => {
          setLoading(false)
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = fName;
          alink.click();
        })
        .catch(() =>{
          console.log("error")
        })
      })
      .catch((e) => {
        navigate('/')
      })
  }
  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <Col xs={12} xl={12}>
            <Row>
              <div className="filterStyleReq w-100">
                <span className="filterTextStyle">
                  {drftReqText.FILTERED}:{" "}
                </span>
                <span className="studyCntStyle">
                  {reqData ? (
                    <span>
                      {reqData?.filter[0]?.filters?.length > 0 ||
                        reqData?.filter_datamodalities?.length > 0 ||
                        reqData?.filter_instrument?.length > 0 ? (
                        <span>
                          {reqData && reqData.filtered_count === "" ||
                            reqData && reqData.filtered_count === null ||
                            reqData && reqData.filtered_count === undefined ? (
                            <span>{reqData && reqData.total_count}</span>
                          ) : (
                            <span>{reqData && reqData.filtered_count}</span>
                          )}/{reqData && reqData.total_count}{" "}
                          {viewStudyText.STUDIES_NOW}{" "}
                          {reqData && reqData.filtered_count === "" ||
                            reqData && reqData.filtered_count === null ||
                            reqData && reqData.filtered_count === undefined ? null : (
                            <span>
                              {reqData?.filter.map((filterGroup, index) => (
                                <span key={index}>
                                  {index !== 0 ? 'or ' : null}
                                  {filterGroup?.filters?.map((filter, innerIndex) => (
                                    <span key={innerIndex} className="contentHeadingTextStyle">
                                      {innerIndex !== 0 ? 'and ' : null}
                                      {filter.filterName && <span>{filter.filterName}: </span>}
                                      {filter.operator}{" "}
                                      {filter.value1 && filter.value1.toString().replaceAll("^", ",")}{" "}
                                      {filter.value2 && (
                                        <span>
                                          {constantValues.AND} {filter.value2}
                                        </span>
                                      )}
                                    </span>
                                  ))}
                                </span>
                              ))}
                              <span>
                                {reqData && reqData.filter_datamodalities && reqData.filter_datamodalities.length > 0 ? (
                                  <span>
                                    <span
                                      className="contentHeadingTextStyle"
                                    >
                                      Data Modalities:{" "}
                                    </span>
                                    <span>include </span>
                                    {reqData && reqData.filter_datamodalities && reqData.filter_datamodalities.map((i) => (
                                      <span>{i},</span>
                                    ))}{" "}
                                  </span>
                                ) : null}
                              </span>
                              <span>
                                {reqData && reqData.filter_instrument && reqData.filter_instrument.length > 0 ? (
                                  <span>
                                    <span
                                      className="contentHeadingTextStyle"
                                    >
                                      Instrument:{" "}
                                    </span>
                                    <span>include </span>
                                    {reqData && reqData.filter_instrument && reqData.filter_instrument.map((i) => (
                                      <span> {i},</span>
                                    ))}{" "}
                                  </span>
                                ) : null}
                              </span>
                            </span>
                          )}
                          <span>.</span>
                        </span>
                      ) : (
                        <span>
                          {reqData && reqData.total_count}/
                          {reqData && reqData.total_count}{" "}
                          {viewStudyText.STUDIES_NOW}.{" "}
                        </span>
                      )}
                    </span>
                  ) : null}
                </span>
              </div>
            </Row>
            <Row>
              {reqData &&
                reqData.data &&
                reqData.data.map((i) => (
                  <Col span={12}>
                    <div>
                      {" "}
                      <Card
                        title={i.title}
                        className="cardStyleStatistics"
                        style={{
                          cursor: "pointer",
                          opacity: i.disabled ? 0.3 : 1,
                          border: i.disabled
                            ? "none"
                            : "1px solid #1890FF",
                          marginLeft: "5%",
                          marginTop: "2%",
                          paddingRight: "0%",
                          width: "90%",
                        }}
                        headStyle={{
                          font: "normal normal 600 14px/22px Arial",
                          paddingLeft: "5%",
                          color: "#262626"
                        }}
                        onClick={() => cardClick(i)}
                      >
                        <div className="height100">
                          <div>
                            {" "}
                            <span className="cardSampleStyle">
                              {" "}
                              {reqData?.filters?.length > 0 ||
                                reqData?.filter_datamodalities?.length > 0 ||
                                reqData?.filter_instrument?.length > 0 ? "Filtered Sample Size" : newStudyText.SAMPLE_SIZE}

                            </span>{" "}
                            <span>
                              {reqData?.filters?.length > 0 ||
                                reqData?.filter_datamodalities?.length > 0 ||
                                reqData?.filter_instrument?.length > 0 ?
                                reqData?.sample_ids_count
                                  ?.filter(p => p.dataset_id === i.dataset_id && p.release_version === i.release_version)
                                  ?.length > 0 ?
                                  reqData?.sample_ids_count
                                    ?.filter(p => p.dataset_id === i.dataset_id && p.release_version === i.release_version)[0]
                                    ?.participant_count :
                                  i.sample_size :
                                i.sample_size}</span>{" "}
                          </div>
                          {i.description.length > 50 ? (
                            <span>
                              {i.description.substring(0, 80)}...
                            </span>
                          ) : (
                            <span>{i.description}</span>
                          )}
                        </div>
                        <div className="mt-5">
                          {" "}
                          <span>v {i.release_version} </span>
                          <span
                            className="clinicalInnerStyle"
                          >
                            {i.visibility &&
                              i.visibility.charAt(0).toUpperCase() +
                              i.visibility.slice(1)}
                          </span>
                        </div>
                      </Card>{" "}
                    </div>{" "}
                  </Col>
                ))}
            </Row>
          </Col>
          <Col xs={12} xl={12}>
            <div style={{ background: "#F5F5F5 0% 0% no-repeat padding-box", height: "85vh", opacity: "1", marginLeft: "5%", paddingLeft: "3%", paddingRight: "3%", paddingTop: "3%" }}>
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  Requestor Name
                </div>
                <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", minHeight: "20px", marginTop: "1%", marginBottom: "2%", marginLeft: "2%" }}>
                  {userDet && userDet.name}
                </div>
              </div>
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  Requestor Email
                </div>
                <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", minHeight: "20px", marginTop: "1%", marginBottom: "2%", marginLeft: "2%" }}>
                  {userDet && userDet.mail}
                </div>
              </div>
              <div style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                marginLeft: "2%", marginRight: "2%", marginBottom: "4%"
              }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  Requestor Role
                </div>
                <div style={{
                  font: "normal normal normal 14px/22px Arial",
                  color: "#595959", minHeight: "20px", marginTop: "1%", marginBottom: "2%", marginLeft: "2%"
                }}>
                  {userDet && userDet.role}
                </div>
              </div>
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  Requestor Affiliation
                </div>
                <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", minHeight: "20px", marginTop: "1%", marginBottom: "2%", marginLeft: "2%" }}>
                  {userDet && userDet.affiliation}
                </div>
              </div>
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  Request Description
                </div>
                <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", minHeight: "20px", marginTop: "1%", marginBottom: "2%" }}>
                  {reqData && reqData.request_data && reqData.request_data.req_desc}
                </div>
              </div>
              {/* <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                    <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                      How will you use this data
                    </div>
                    <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", marginTop: "1%", minHeight: "20px", marginLeft: "2%", marginBottom: "4%" }}>
                      {reqData && reqData.request_data && reqData.request_data.data_usage}
                    </div>
                  </div> */}
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  Mobile Number
                </div>
                <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", marginTop: "1%", minHeight: "20px", marginLeft: "2%", marginBottom: "4%" }}>
                  {reqData && reqData.request_data && reqData.request_data.mobile_number}
                </div>
              </div>
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>
                <div className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  What is your role in this project?
                </div>
                <div style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", marginTop: "1%", minHeight: "20px", marginLeft: "2%", marginBottom: "4%" }}>
                  {reqData && reqData.request_data && reqData.request_data.project_role}
                </div>
              </div>
              <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "4%" }}>

                <span className="reqHeaderStyle" style={{ marginLeft: "2%" }}>
                  OBI Compute
                </span>
                <span style={{ paddingLeft: "25%", font: "normal normal normal 14px/22px Arial", color: "#595959" }}>
                  {reqData && reqData.request_data && reqData.request_data.analytics ?
                    <span >Yes</span> : <span>No</span>}</span>
              </div>
              {reqData && reqData.request_data && reqData.request_data.file_list &&
                reqData.request_data.file_list.map((i) =>
                (<div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "2%", marginTop: "2%" }}>
                  <Button onClick={() => fileClick(i)}
                    style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", border: "none" }}>
                    <DownloadOutlined /> {i}
                  </Button>
                </div>))}
              {adminUser === true && <div style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", marginLeft: "2%", marginRight: "2%", marginBottom: "2%", marginTop: "4%" }}>
                <Button onClick={() => jsonDwl()} style={{ font: "normal normal normal 14px/22px Arial", color: "#595959", border: "none" }}>
                  <DownloadOutlined /> Request_{id}.json
                </Button>
              </div>}
            </div>
          </Col>
        </Row>
      </Col>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading}
      >
        <Spin />
      </Modal>
    </div>

  );
}
