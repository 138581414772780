import React from "react";
import { Row, Col, Breadcrumb, Select, Button, message } from "antd";
import axios from "axios";
import { PlusSquareOutlined, UserOutlined } from "@ant-design/icons";
import { urls, pageLinks } from "../constants/urls";
import { NewStudyStyle } from "../styles/NewStudy";
import { viewStudyText } from "../constants/displayText";
import "../styles/ViewStudyStyle.css";
import "../styles/DetailedStudyStyle.css";
import StudyDetailComp from "../components/StudyDetail";
import VideoLinkComp from "../components/VideoLink";
import { useLocation, Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import { constantValues } from "../constants/constantValues";

const apiUrl = process.env.REACT_APP_API_URL;

export default function DetailedStudy() {
  const params = useParams()
  const { instance } = useMsal();
  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(`${apiUrl}/${urls.EXPLORE_DATASET}/${params.id}`)
      .then((response) => {
        if (response.status === 200) {
          setAllDetails(response.data);
          setStudyDetail(response.data)
          let allVersions = response.data.release_version_list.map((i) => {
            return { value: i.release_version, label: i.release_version };
          });
          setVersionOptions(allVersions);
          setVersion(response.data.release_version)
        }
        else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
  }, [params]);

  const [studyDetail, setStudyDetail] = React.useState();
  const [allDetails, setAllDetails] = React.useState([]);
  const [version, setVersion] = React.useState("");
  const [versionOptions, setVersionOptions] = React.useState([]);
  const location = useLocation();

  const handleChange = (value) => {
    setVersion(value);
    let selectedVersion = allDetails.release_version_list.filter((i) => i.release_version === value);
    let slctedVersionId = selectedVersion[0]._id 
    navigate(`${pageLinks.EXPLORE_STUDY}/${Object.values(slctedVersionId)}`);
  };



  function onAddCohort() {
    localStorage.setItem('studyId',params.id)
    instance.loginRedirect(accessTokenRequest).catch((e) => {
      console.log(e);
    });
  }

  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <div style={NewStudyStyle.headerStyle}>
            <div>
              <Breadcrumb>
                <UserOutlined className="userOutlinedStyle" />{" "}
               <Breadcrumb.Item key="/explore"><Link to="/explore">Browse Studies</Link></Breadcrumb.Item>
               <Breadcrumb.Item key="details">Study Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Row>
              <Col xs={18} xl={18}>
                <div className="paddingTitle"
                  style={{
                    ...NewStudyStyle.titleStyle,
                  }}
                >
                  {studyDetail && studyDetail.title}
                </div>
              </Col>
              <Col xs={6} xl={6}>
                <div className="versionStyle">
                  <Select
                    onChange={handleChange}
                    className="mr-10"
                    options={versionOptions}
                    value={version}
                  />

                  <Button
                    className="cohertStyle"
                    onClick={() => onAddCohort()}
                  >
                    {viewStudyText.ADD_COHORT} <PlusSquareOutlined />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Row>

        <Row>
          <Col xs={24} xl={24}>
            <Row>
              <Col xs={18} xl={18}>
                <StudyDetailComp details={{ ...studyDetail }}></StudyDetailComp>
              </Col>
              <Col xs={6} xl={6}>
                <VideoLinkComp details={{ ...studyDetail }}></VideoLinkComp>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
