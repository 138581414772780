export const urls = {
    DESCRIPTION_UPLOAD_URL:"admin/uploadfile/description",
    SAMPLE_UPLOAD_URL:"admin/uploadfile/samples",
    SUBJECT_UPLOAD_URL:"admin/uploadfile/participants",
    NEW_STUDY_SAVE_URL:"admin/save",
    GET_ALL_DATASET_URL:"user/dataset",
    GET_STUDY_DETAIL_URL:"user/dataset/",
    GET_CUSTOM_FILTER_URL:"user/custom-filter-popup-data",
    GET_UPDATE_STUDY_URL:"admin/updatefilecheck",
    UPDATE_STUDY_URL: "admin/updatefile",
    UPLOAD_URL: "admin/uploadfile",
    VIEW_STUDY:"/viewStudy",
    ADD_NEW_COHORT: "user/create-cohort",
    GET_COHORT_LIST:"user/cohort-list",
    SUMMARY_STATISTICS:"user/custom-summary-statistics",
    ADD_STUDY_COHORT:"user/add-remove-dataset-to-cohort",
    FILTER_SUMMARY_STATISTICS:"user/filter-summary-statistics",
    SAVE_SUMMARY_STATISTICS: "user/custom-save-summary-statistics",
    ADD_BASKET:"user/add-to-basket",
    REQ_BASKET:"user/req-basket",
    SAVE_REQ: "user/save-request",
    SEND_REQ: "user/send-request",
    USER_LOGIN:"user/login",
    EXPLORE_DATASET: "explore/dataset",
    EXPLORE_STUDY: "explore/study/",
    EXPLORE_CUSTOM_FILTER:"explore/custom-filter-popup-data",
    RETRIEVE_COHORT: "user/retrieve-cohort",
    ADM_REQ_URL: "admin/requests",
    USER_REQ_URL: "user/requests",
    ADM_REQ_DETAILS:"admin/req-detail",
    ADM_REQ_UPD: "admin/request-status-update",
    ADM_FILE_DOWNLOAD: "/admin/file-download",
    DELETE_COHORT:"/user/delete-cohort",
    DELETE_STUDY: "admin/delete-dataset",
    DAC_REQ_DETAILS:"admin/dac-req-detail",
    SAVE_COHORT_NAME:"user/update-cohortname"
  };

export const pageLinks = {
  VIEW_STUDY:"/viewStudy",
  VW_STUDY:"/studies",
  UPDATE_STUDY:"/update",
  NEW_RELEASE:"/new-release",
  NEW_STUDY:"/new",
  MY_COHORTS:"/cohort",
  REQ_BASKET:"/request-basket",
  MY_REQUESTS:"/my-requests",
  ADMIN_REQUEST: "/admin-request",
  LOGIN: "/", 
  EXPLORE_STUDY:"/explore",
  ADM_REQ_DETAILS: "/details",
  REVIEW_COMMENTS:"review-comments",
  DAC_REQUEST:"dac-request",
  STEERING_REQUEST:"steering-request"
}