import React from "react";
import { Row, Col, Tooltip, Button } from "antd";
import {
  LinkOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "../styles/DetailedStudyStyle.css";
import  "../styles/CommonStyles.css";
import {
  detailedStudyVideoText,
  viewStudyText,
} from "../constants/displayText";

import StudyGrpInfo from "./StudyGrpInfo";

export default function VideoLink(props) {
  const [subGrp, setSubGrp] = React.useState(false);
  const [grpInfo, setgrpInfo] = React.useState({});
  const publications =
    props.details &&
    props.details.publications &&
    props.details.publications.length > 0
      ? props.details.publications.map((j) => (
          <div className="publicationsStyle">
           
            {" "}
            <a
              className="breakWordWrap"
              href={j.uri}
              target="_blank"
            >
               <span>
                {j.uri}
                </span>{" "}
                <span>
                <LinkOutlined />
                </span>
              
            </a>
            
          </div>
        ))
      : "";

  function subGrpOnFocus(j) {
    let details = {
      grpName: j.group_name || null,
      grpCnt: j.sub_group_count || 0,
      incCri: j.inclusion_criteria || null,
      excCri: j.exclusion_criteria || null,
      primaryDiagnosis: j.primary_diagnosis || null,
    };
    setgrpInfo({ ...details });
    setSubGrp(true);
  }

  const sendData = (param) => {
    setSubGrp(param);
  };

  const tags =
    props.details &&
    props.details.participant_groups &&
    props.details.participant_groups.length > 0
      ? props.details.participant_groups.map((j) => (
          <div className="pb-2%">
            <span
            className="pl-3 pr-3 subGrpStyles pr-0"
            >
              {j.group_name}
            </span>{" "}
            {(j.inclusion_criteria && j.inclusion_criteria.length > 0) ||
            (j.exclusion_criteria && j.exclusion_criteria.length > 0) ||
            (j.primary_diagnosis && j.primary_diagnosis.length > 0) ? (
              <span  onClick={() => subGrpOnFocus(j)}> <InfoCircleOutlined /> </span>
            ) : (
              ""
            )}
          </div>
        ))
      : "";

  const videoLink = detailedStudyVideoText.DEFAULT_VIDEO_LINK;
  const videoId =
    props.details && props.details.video_uri
      ? props.details.video_uri.split("=")[1]
      : null;

  const video_uri =
    props.details && props.details.video_uri && props.details.video_uri !== null
      ? videoLink.concat(videoId)
      : "";

  return !subGrp ? (
    <div className="videoBgStyle">
      <Col xs={24} xl={24}>
        {props.details &&
        props.details.video_uri &&
        props.details.video_uri !== null ? (
          <Row className="videoLinkStyle">
            <Col xs={24} xl={24}>
              <span className="vidLinkStyle">
                {detailedStudyVideoText.VIDEO_LINK}
              </span>
            </Col>
          </Row>
        ) : null}{" "}
        {props.details &&
        props.details.video_uri &&
        props.details.video_uri !== null ? (
          <Row className="videoPreviewStyle">
            <Col xs={24} xl={24}>
              <iframe
                width="100%"
                src={video_uri}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
        ) : null}
        <Row className="videoPreviewStyle">
          <Col xs={8} xl={8}>
            <span className="doiStyle">{viewStudyText.DOI}</span>
          </Col>
          <Col xs={16} xl={16} className="mt-2 mb-2">
            <a
              target="_blank"
              className="doiButtonStyle"
              href={props.details.release_doi}
            >
              {props.details && props.details.release_doi
                ? props.details.release_doi
                : ""}{" "}
              <LinkOutlined />
            </a>
          </Col>
        </Row>
        <Row className="videoLinkStyle">
          <Col xs={8} xl={8}>
            <span className="vidLinkStyle">
              {detailedStudyVideoText.PUBLICATIONS}
            </span>
          </Col>
          <Col xs={16} xl={16}>
            <Row>
              <Col xs={24} xl={24}>
                {publications}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="videoLinkStyle">
          <Col xs={8} xl={8}>
            <div className="vidLinkStyle pl-0 ml-1">
              {detailedStudyVideoText.PARTICIPANT_GROUPS}
            </div>
          </Col>
          <Col xs={16} xl={16} className="mt-2 mb-2">
            {tags}
          </Col>
        </Row>
      </Col>
    </div>
  ) : (
    <StudyGrpInfo details={{ ...grpInfo }} sendDt={sendData}></StudyGrpInfo>
  );
}
