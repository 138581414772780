import React from "react";
import DetailedStudyComp from "../../components/ExpDetailedStudy";
import { Row, Col } from "antd";
import "../../styles/PageStyle.css";
import { useLocation } from "react-router-dom";
import "../../styles/CommonStyles.css";
import { Space, Image } from "antd";
import SideBarStyle from "../../styles/SideBar"
import { ProfileOutlined } from "@ant-design/icons";
import { sideBarText } from "../../constants/displayText";
import { pageLinks, urls } from "../../constants/urls";
import { useNavigate } from "react-router-dom";

export default function DetailedStudyPage(props) {
  const location = useLocation();
  const navigate = useNavigate()
  const onImage = () => {
    navigate('/')
  }

  return (
    <div>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle" style={{  height: "120%" }}>
            <Image style={{...SideBarStyle.logoStyle, cursor: "pointer" }} preview={false} src={process.env.PUBLIC_URL+"/static/media/logo.png"} alt="Brain Logo" onClick={onImage}/>
            <div style={SideBarStyle.eachMenuStyle}>
              <Space>
                <ProfileOutlined style={SideBarStyle.iconStyle} />
              </Space>
              <a href={pageLinks.EXPLORE_STUDY} style={SideBarStyle.menuStyle}>
                {sideBarText.BROWSE_STUDIES}{" "}
              </a>
            </div>
          </div>
        </Col>
        <Col xs={21} xl={21}>
          <div className="contentStyle height120">
            <DetailedStudyComp></DetailedStudyComp>
          </div>
        </Col>
      </Row>
    </div>
  );
}
