import React from "react";
import DacReqDetail from "../components/DacReqDetail";
import { Row, Col, Divider } from "antd";
import "../styles/PageStyle.css"

export default function DacReqDetailPage(props) {

  return (
    <div> <div>
      <Row>
        <Col xs={24} xl={24}>
          <div style={{border:"none"}}>
            <DacReqDetail />
          </div>
        </Col>
      </Row>
    </div>
    </div>
  );
}
