import React from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Card,
  Input,
  Modal,
  message,
  Spin,
  Menu,
  Alert,
  Checkbox,
  Dropdown,
  Tabs,
  Select
} from "antd";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import {
  LikeOutlined,
  DislikeOutlined,
  SecurityScanOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  statisticsText
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import '../styles/StatisticsStyle.css'
import "../styles/ViewStudyStyle.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate, useParams } from "react-router-dom";
import ReqDetailComponent from "./ReqDetailComponent";

const apiUrl = process.env.REACT_APP_API_URL;
const { TextArea } = Input;

export default function ReqBask(props) {

  const { instance, accounts } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  const navigate = useNavigate();
  const params = useParams()
  const [loading, setLoading] = React.useState(false);
  const [memberAdd, setMemberAdd] = React.useState([]);
  const userType = localStorage.getItem("userType")

  React.useEffect(() => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let query = {
          request_id: params.id,
        };
        let config = {
          headers: { Authorization: `Bearer ${response.accessToken}` },
          params: query,
        };
        setLoading(true)
        axios.get(`${apiUrl}/${urls.ADM_REQ_DETAILS}`, config).then((response) => {
          if (response.status === 200) {
            setLoading(false)
            setReqData(response.data);
            if (response.data.data) {

              if (response.data.user_data) {
                setUserDet(response.data.user_data)
              }
              // if (
              //   response.data.filter !== null &&
              //   response.data.filter !== undefined
              // ) {
              //   let prevFilter = response.data.filter;
              //   let prevFilterArray = Object.entries(prevFilter);
              //   let newArr = prevFilterArray.map((i) => {
              //     let val = i[1].split("^");

              //     let commonValues = {
              //       filterName: i[0],
              //       operator: val[0],
              //     };

              //     if (val.length === 2) {
              //       return {
              //         ...commonValues,
              //         value1: val[1],
              //       };
              //     }

              //     if (val.length > 2 && (val[0] === "include" || val[0] === "exclude")) {
              //       return {
              //         ...commonValues,
              //         value1: val.slice(1).join("^"),
              //       };
              //     }

              //     return {
              //       ...commonValues,
              //       value1: val[1],
              //       value2: val[2],
              //     };
              //   });
              //   let formattedNewArr = [...newArr];
              //   formattedNewArr = formattedNewArr.map((i) => {
              //     i.filterName = statisticsText.FILTER_NAMES[i.filterName];
              //     return i;
              //   });
              //   setFilters(formattedNewArr);
              // }
            }
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
      })
      .catch((e) => {
          localStorage.setItem('page', window.location.href)
          instance.loginRedirect(accessTokenRequest).catch((e) => {
            console.log(e);
          });
          localStorage.setItem("session", true)     
       })
  }, []);

  const [reqData, setReqData] = React.useState();
  const [userDet, setUserDet] = React.useState();
  const [reqTitle, setReqTitle] = React.useState("");
  const [dacModal, setDacModal] = React.useState(false);
  const [isDecisionOpen, setIsDecisionOpen] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [decisionNote, setDecisionNote] = React.useState("");
  const [dacList, setDacList] = React.useState([]);
  const [dacMember, setDacMember] = React.useState([]);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [mailContent, setMailContent] = React.useState("");
  const [errorMessages, setErrorMessages] = React.useState([]);

  const onUpdate = (fnName) => {
    setStatus(fnName)
    setIsDecisionOpen(true)
  }

  const onSkip = () => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let data = {
          req_status: status,
          user_id: localStorage.getItem('userObjId')
        }
        let query = {
          request_id: params.id,
          
        };
        let config = {
          headers: { "Authorization": `Bearer ${response.accessToken}` },
          params: query
        }
        axios.put(`${apiUrl}/${urls.ADM_REQ_UPD}`, data, config)
          .then((response) => {
            if (response.status === 200) {
              navigate(`${pageLinks.ADMIN_REQUEST}`, { state: { statusUpd: true, requestTitle: reqTitle } })
            }
            else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
      })
      .catch((e) => {
        navigate('/')
       })
  }

  const onSubmit = () => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let data = {
          req_status: status,
          decision_note: decisionNote,
          user_id: localStorage.getItem('userObjId')
        }
        let query = {
          request_id: params.id,
          
        };
        let config = {
          headers: { "Authorization": `Bearer ${response.accessToken}` },
          params: query
        }
        axios.put(`${apiUrl}/${urls.ADM_REQ_UPD}`, data, config)
          .then((response) => {
            if (response.status === 200) {
              navigate(`${pageLinks.ADMIN_REQUEST}`, { state: { statusUpd: true, requestTitle: reqTitle } })
            }
            else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
      })
      .catch((e) => {
        navigate('/')
       })
  }

  const onInpDesc = (event) => {
    setDecisionNote(event.target.value)
  }

  const onSendDAC = () => {
    try {
    axios.get(`${apiUrl}/user/get-members`)
      .then((response) => {
        console.log("response", response)
        let members = response.data.map(i => i.email_id)
        setDacList(response.data)
        setDacMember(members.map((i) => ({
          emailId: i,
        })));
      })
      .catch(() =>{
        console.log("error")
      })
    setDacModal(true) }
    catch(error) {
      
    }
  }
  const onCloseDac = () => {
    setDacModal(false)
  }

  const onInputDac = (value, index) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value.target.value);
    setMemberAdd((prevMemberAdd) => {
      const updatedMemberAdd = [...prevMemberAdd];
      updatedMemberAdd[index].emailId = value.target.value;
      return updatedMemberAdd;
    });
    setErrorMessages((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = isValidEmail ? null : 'Invalid email format';
      return updatedErrors;
    });
  }
  const onInputName = (value, index) => {
    setMemberAdd((prevMemberAdd) => {
      const updatedMemberAdd = [...prevMemberAdd];
      updatedMemberAdd[index].email_name = value.target.value;
      return updatedMemberAdd;
    });
  }

  const onSendLink = () => {
    setLoading(true)
    const hasMissingMemberType = dacMember.filter((i) => i.memberSelected === true).some((member) => !member.hasOwnProperty('memberType'));
    const hasMissingNewMemberType = memberAdd.some((member) => !member.hasOwnProperty('memberType'));
    if (hasMissingMemberType || hasMissingNewMemberType) {
      message.error('Select Member Type for all members');
    }
    else {
      setDacModal(false)
      let payload = {
        email_data: dacMember.filter((i) => i.memberSelected === true).concat(memberAdd.filter((i) => i.emailId !== "")),
        email_content: mailContent,
        admin_user_id: localStorage.getItem('userObjId')
      }
      axios.put(`${apiUrl}/user/send-email/dac-member?req_id=${params.id}`, payload).then((response) => {
        if (response.status === 200) {
          message.success("Sent link to members successfully")
        }
        else {
          message.error("Sending link failed")
        }
        
      })
      .catch(() =>{
        console.log("error")
      })
    }
    setLoading(false)
  }

  const onSendLinkSC = () => {
    setDacModal(false)
    let payload = {
      email_data: dacMember.filter((i) => i.memberSelected === true).concat(memberAdd),
      email_content: mailContent,
      admin_user_id: localStorage.getItem('userObjId')
    }
    setLoading(true)
    axios.put(`${apiUrl}/user/send-email/sc-members?req_id=${params.id}`, payload).then((response) => {
      if (response.status === 200) {
        message.success("Sent link to members successfully")
      }
      else {
        message.error("Sending link failed")
      }
    })
    .catch(() =>{
      console.log("error")
    })
    setLoading(false)
  }

  const onDeleteClick = (user) => {
    axios.delete(`${apiUrl}/user/delete-member/${user}`).then((response) => {
      if (response.status === 200) {
        message.success("Deleted members successfully")
        onSendDAC()
      }
      else {
        message.error("Deleting members failed")
      }
    })
    .catch(() =>{
      console.log("error")
    })
  }

  const menu = (
    <Menu>
      {reqData && reqData.request_data.dac_link === true ?
        <Menu.Item key="1" onClick={() => onSendDAC()}>Send to DAC</Menu.Item>
        : <Menu.Item key="1" disabled>Send to DAC</Menu.Item>}

      {reqData && reqData.request_data.sc_link === true ?
        <Menu.Item key="2" onClick={() => onSendDAC()} >Send to Steering Commitee</Menu.Item> :
        <Menu.Item key="2" onClick={() => onSendDAC()} disabled >Send to Steering Commitee</Menu.Item>
      }
    </Menu>
  );

  const adminDefaultBtns = (
    <div>
      <div style={{ display: "flex" }}>
      <div className="mr-2">
        <Button style={{
            textWrap: "noWrap", textTransform: "capitalize",
            background: reqData && reqData.request_data && reqData.request_data.status === 'Approved' ?
              "#F6FFED" : reqData && reqData.request_data && reqData.request_data.status === "Rejected" ? "#FFF2E8" :
                reqData && reqData.request_data &&
                  reqData.request_data.status === "pending" ?
                  "#D2CECE80" : "#FDBCA18A",
            color: reqData &&
              reqData.request_data && reqData.request_data.status === 'Approved' ?
              "#006633" : reqData && reqData.request_data &&
                reqData.request_data.status === "Rejected" ? "#FF0000" : reqData && reqData.request_data &&
                  reqData.request_data.status === "pending" ?
                "#808080" : "#FF8000",
            borderRadius: "2px",
            border: reqData && reqData.request_data && reqData.request_data.status === 'Approved' ?
              "1px solid #B7EB8F" : reqData && reqData.request_data && reqData.request_data.status === "Rejected" ? "1px solid #FFBB96"
                : reqData && reqData.request_data && reqData.request_data.status === "pending" ?
                  "1px solid #80808080" : "1px solid #FF4A0080"
          }}>
          {reqData && reqData.request_data && reqData.request_data.status}
        </Button>
      </div>
        <div style={{ display: "flex" }} >
          <Button className="mr-1" type={reqData && reqData.request_data.status === "rejected" ? "primary" : null}
            style={{ border: "1px solid #1890FF", borderRadius: "2px" }}
            onClick={() => onUpdate("rejected")}
          >
            <span style={{
              color: reqData && reqData.request_data.status === "rejected" ?
                "#FFFFFF" : "#1890FF"
            }}>
              Reject</span>
            <DislikeOutlined style={{
              color: reqData && reqData.request_data.status === "rejected" ?
                "#FFFFFF" : "#1890FF"
            }} />
          </Button>
          <Dropdown overlay={menu}>
            <Button type={reqData && reqData.request_data.status === "Review Under DAC" ||
              reqData && reqData.request_data.status === "Review under SC" ||
              reqData && reqData.request_data.status === "Review Under Admin" ? "primary" : null}
              style={{ marginLeft: "3%", border: "1px solid #1890FF", borderRadius: "2px" }}
            // onClick={() => onUpdate("review")}
            >
              <span style={{
                color: reqData && reqData.request_data.status === "Review Under DAC" ||
                  reqData && reqData.request_data.status === "Review under SC" ||
                  reqData && reqData.request_data.status === "Review Under Admin" ?
                  "#FFFFFF" : "#1890FF"
              }}>Review </span>
              <SecurityScanOutlined style={{
                color: reqData &&
                  reqData.request_data.status === "Review Under DAC" ||
                  reqData && reqData.request_data.status === "Review under SC" ||
                  reqData && reqData.request_data.status === "Review Under Admin"
                  ? "#FFFFFF" : "#1890FF"
              }} />
            </Button>
          </Dropdown></div>

        <Button className="ml-3" type={reqData && reqData.request_data.status === "approved" ? "primary" : null}
          style={{
            border: "1px solid #1890FF",
            borderRadius: "2px"
          }}
          onClick={() => onUpdate("approved")}
        >
          <span style={{
            color: reqData && reqData.request_data.status === "approved" ?
              "#FFFFFF" : "#1890FF"
          }}>Approve  </span><LikeOutlined
            style={{ color: reqData && reqData.request_data.status === "approved" ? "#FFFFFF" : "#1890FF" }} />
        </Button>
      </div>
    </div>
  )

  return (
    <div>
      <Col xs={24} xl={24}>
        <div>
          <Row>
            <Col xs={12} xl={12}>
              <div className="titleStyle pt-2"
              >
                <span> {reqData && reqData.request_data && reqData.request_data.req_title} </span>
              </div>
            </Col>

            <Col xs={12} xl={12} style={{ marginBottom: "2%", marginTop: "2%" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                {adminDefaultBtns}
                {reqData && reqData.request_data && reqData.request_data.dac_comments &&
                  reqData.request_data.dac_comments.length > 0 ? <Button
                    style={{
                      border: "1px solid #1890FF",
                      borderRadius: "2px", color: "#1890FF", marginLeft: "4%"
                    }}
                    onClick={() => {
                      if (userType === "dac") {
                        setReviewModal(true)
                      }
                      else {
                        localStorage.setItem("prev-page", window.location.href)
                        navigate(`/review-comments/${params.id}/${localStorage.getItem("userObjId")}`)
                      }
                    }}
                  >
                  Review Comments
                </Button> : null}
              </div>
            </Col>
          </Row>
          <Divider className="dividerStyle m-0"> </Divider>
          <ReqDetailComponent userDet={userDet} reqData={reqData} id={params.id} adminUser={true}/>
        </div>
        <Modal
          closable={false}
          width={600}
          centered={true}
          footer={null}
          title={
            <div>
              <Col xs={24} xl={24}>
                {reqData && reqData.audit_history && reqData.audit_history.map ((i) => (
                                  <Row className="mb-2">
                                  <div className="w-100">
                                  <div className="d-flex flex-row justify-content-between">
                                    <div>{i.admin_username}</div>
                                    <div>{i.date}</div>
                                  </div>
                                  <div>Status: <b style={{textTransform:"capitalize"}}>{i.admin_status}</b></div>
                                  <div>{i.admin_decision_note}</div>
                                  </div>
                                </Row>
                ))}
                <Row>
                  <Col xs={20} xl={20}>
                    <div><span className="filterSampleHdStyle">
                      Decision note
                    </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          }
          open={isDecisionOpen}
        >
          {" "}
          <div>
            <TextArea style={{ width: "75%" }}
              placeholder="Note about Decision"
              onChange={onInpDesc}
            />
          </div>
          <Divider />
          <div style={{ left: "70%", position: "relative" }}>
            <Button onClick={onSkip} style={{ right: "2%" }}>Skip</Button>
            <Button type="primary" onClick={onSubmit}>Submit</Button>
          </div>
        </Modal>

        <Modal
          // closable={false}
          width={800}

          centered={true}
          footer={null}
          title={
            <div>
              <Col xs={24} xl={24}>
                <Row>
                  <Col xs={20} xl={20}>
                    <div><span className="filterSampleHdStyle">
                      {reqData && reqData.request_data.dac_link === true ? "Send Link to DAC Members" : "Send Link to Steering Committee Members"}
                    </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          }
          open={dacModal}
          onCancel={onCloseDac}
        >
          {" "}
          <div style={{ height: "47vh", overflowY: "auto" }}>
            {dacList.map((i, index) => (
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row justify-content-between w-50">
                  <div>{i.email_id}</div>
                  <div className="mr-2">{i.name}</div>
                  </div>


                <div className="d-flex w-50">
                  {reqData && reqData.request_data.dac_link === true ?
                    <Select className="mr-3 mb-4" style={{ border: "1px solid black", width: "300px" }}
                      onChange={(e) => {
                        let dac = dacMember
                        dac[index]['memberType'] = e
                        setDacMember(dac)
                      }}
                      placeholder="Select member Type"
                      options={[
                        { value: "RP", label: "Research Representative" },
                        { value: "POR", label: "Privacy Officer Representative" },
                        { value: "CR", label: "Community Representative" },
                      ]} /> : null}
                  <Checkbox className="mt-1"
                    onChange={(e) => {
                      let dac = dacMember
                      dac[index]['memberSelected'] = e.target.checked
                      setDacMember(dac)
                    }} />
                  <DeleteOutlined
                    className="mr-1 ml-4 mt-2"
                    onClick={() => onDeleteClick(i._id)}
                  />
                </div>

              </div>
            ))}
            <Button onClick={() => {
              setMemberAdd((prevMemberAdd) => [...prevMemberAdd, { emailId: '', memberType: '' }]);
            }} type="primary" className="mb-2 mt-2">Add More</Button>
            {memberAdd.map((email, index) => (
              <div key={index}>
                <Input className="mb-2 mt-2"
                  style={{ border: "1px solid black", width: "99%" }}
                  placeholder="Add email Id"
                  // value={email.emailId}
                  onChange={(e) => onInputDac(e, index)}
                />
                <Input className="mb-2 mt-2"
                  style={{ border: "1px solid black", width: "99%" }}
                  placeholder="Enter Name"
                  onChange={(e) => onInputName(e, index)}
                />
                {errorMessages[index] && (
                  <Alert message={errorMessages[index]} type="error" showIcon />
                )}
                {reqData && reqData.request_data.dac_link === true ? (
                  <Select
                    style={{ width: "99%", marginBottom: "4%", border: "1px solid black" }}
                    onChange={(e) => {
                      setMemberAdd((prevMemberAdd) => {
                        const updatedMemberAdd = prevMemberAdd.map((item, i) =>
                          i === index ? { ...item, memberType: e } : item
                        );
                        console.log("updatedMemberAdd")
                        return updatedMemberAdd;
                      });
                    }}
                    placeholder="Select member Type"
                    options={[
                      { value: "RP", label: "Research Representative" },
                      { value: "POR", label: "Privacy Officer Representative" },
                      { value: "CR", label: "Community Representative" },
                    ]}
                  />
                ) : null}
              </div>
            ))}
            <TextArea placeholder="Enter mail content" rows={4} style={{ border: "1px solid black", width: "99%" }}
              onChange={(e) => {
                console.log("e.target.value", e.target.value)
                setMailContent(e.target.value)
              }} />


            <div style={{ display: "flex", justifyContent: "end", marginTop: "2%" }}>
              <Button style={{ right: "2%" }} type="primary" onClick={() => {
                if (reqData && reqData.request_data.dac_link === true) {
                  onSendLink()
                }
                else {
                  onSendLinkSC()
                }
              }}
                disabled={errorMessages.some((error) => error !== null)}>Submit</Button>
            </div>
          </div>
        </Modal>
        <Modal
          closable={false}
          width={null}
          centered={true}
          footer={null}
          title={null}
          open={loading}
        >
          <Spin />
        </Modal>
      </Col>
    </div>
  );
}
