import React from "react";
import SideBarComp from "../components/sideBarUser";
import ReqBasketComp from "../components/ReqBasket";
import { Row, Col } from "antd";
import "../styles/PageStyle.css"
import { Navigate, Route, useLocation } from 'react-router-dom'

export default function ReqBasket(props) {

  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('role'));
  const [cohortList, setCohortList] = React.useState(JSON.parse(localStorage.getItem('cohList')));
  const location = useLocation()

  const getCohortDetails = (param) => {
    setCohortList(param);
  };

  return (
    <div> {isAuth ? 
    (<div style={{height:"100%vh"}}>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle">
            <SideBarComp pgName={{page:"my-req"}} getCohort={getCohortDetails} cohortList={{cohort: cohortList}}></SideBarComp>
          </div>
        </Col>
        <Col xs={21} xl={21}>
          {location &&  location.state && location.state.details && location.state.details.reqBskt ?
          <div className="contentStyle">
            <ReqBasketComp details={{reqBskt:true}} getCohort={getCohortDetails}></ReqBasketComp>
          </div> :
          <div className="contentStyle">
            <ReqBasketComp getCohort={getCohortDetails}></ReqBasketComp>
          </div> }
        </Col>
      </Row>
    </div> ):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
