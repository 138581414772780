// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .filterStyleReq {
    padding-left: 2%;
    color: #595959
  }

  .filterTextStyle {
    font: normal normal 600 14px/22px;
  }

  .reqHeaderStyle {
    font: normal normal 600 16px/24px;
    color: #262626
  }`, "",{"version":3,"sources":["webpack://./src/styles/DrftReqStyles.css"],"names":[],"mappings":"EAAE;IACE,gBAAgB;IAChB;EACF;;EAEA;IACE,iCAAiC;EACnC;;EAEA;IACE,iCAAiC;IACjC;EACF","sourcesContent":["  .filterStyleReq {\n    padding-left: 2%;\n    color: #595959\n  }\n\n  .filterTextStyle {\n    font: normal normal 600 14px/22px;\n  }\n\n  .reqHeaderStyle {\n    font: normal normal 600 16px/24px;\n    color: #262626\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
