import React from "react";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Card,
  Divider,
  Input,
  Modal,
  message,
  Select,
} from "antd";
import axios from "axios";
import "../styles/ViewStudyStyle.css";
import NewStudyStyle from "../styles/NewStudy";
import {
  FilterOutlined,
  PlusSquareOutlined,
  SelectOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  newStudyText,
  viewStudyText,
  detailedStudyText,
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import { useNavigate } from "react-router-dom";
import "../styles/CommonStyles.css";
import { constantValues } from "../constants/constantValues";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import dayjs from 'dayjs'

const { RangePicker } = DatePicker;

const { Search } = Input;

const apiUrl = process.env.REACT_APP_API_URL;

export default function ViewStudies(props) {
  const { instance } = useMsal();
  React.useEffect(() => {
    let updatedParams = {
      page_num: pageNum,
    };
    apiCall(updatedParams, true);
  }, []);

  const [pageNum, setPageNum] = React.useState(0);
  const [filteredCnt, setFilteredCnt] = React.useState();
  const [items, setItems] = React.useState([]);
  const [moreData, setMoreData] = React.useState(true);
  const [filterPage, setFilterPage] = React.useState(false);
  const [totalStudies, setTotalStudies] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [customFilterOpt, setCustomFilterOpt] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [filterVal, setFilterVal] = React.useState(
    viewStudyText.SELECT_FILTER_TOPIC
  );

  const navigate = useNavigate();

  function onOpenStudyDetails(i) {
    navigate(`${pageLinks.EXPLORE_STUDY}/${Object.values(i._id)}`);
  }

  const handleChange = (value) => {
    let existingFilter = filters.filter((i) => i.filterName === value);
    if (existingFilter.length === 0) {
      setFilters([...filters, { filterName: value }]);
    }
    setFilterVal(viewStudyText.SELECT_FILTER_TOPIC);
  };

  const onSearch = (value) => {
    setSearchText(value);
    let updatedParams = {};
    updatedParams = paramsFunc(null);
    updatedParams.page_num = 0;
    apiCall(updatedParams, false, value);
  };

  function handleOperatorFilter(filterName, event) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        i.operator = event;
      }
      return i;
    });
    setFilters(newFilters);
  }

  function handleValue(filterName, value, event) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        if (value) {
          i.value1 = event.target.value;
        } else {
          i.value2 = event.target.value;
        }
      }
      return i;
    });
    setFilters(newFilters);
  }

  function handleValueDate(filterName, value, date, dateStr) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        if (value) {
          i.value1 = dateStr;
        } else {
          i.value2 = dateStr;
        }
      }
      return i;
    });
    setFilters(newFilters);
  }

  function handleDateRange(date, dateStr) {
    let removeExistingDate = filters.filter(
      (i) => i.filterName !== viewStudyText.DATE_RANGE
    );
    if (dateStr[0] !== "" && dateStr[1] !== "") {
      let newFilter = {};
      newFilter = {
        filterName: viewStudyText.DATE_RANGE,
        operator: constantValues.BETWEEN,
        value1: `${dateStr[0]}-01-01`,
        value2: `${dateStr[1]}-12-31`,
      };
      setFilters([...removeExistingDate, newFilter]);
      onClickApply(newFilter, true);
    } else {
      if (removeExistingDate.length === 0) {
        setFilters([]);
        setFilteredCnt();
      } else {
        setFilters(removeExistingDate);
      }
      onClickApply(null, false, true, removeExistingDate);
    }
  }

  function filterClick() {
    setFilterPage(true);
    let updatedParams = {};
    let config = {
      params: updatedParams,
    };
    axios
      .get(`${apiUrl}/${urls.EXPLORE_CUSTOM_FILTER}`, config)
      .then((response) => {
        if (response.status === 200) {
          setCustomFilterOpt(response.data);
        }
        else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
  }

  function onCloseModal() {
    setFilterPage(false);
  }

  function onCancel() {
    let cancelledCustomFilter = [];
    cancelledCustomFilter = filters.filter(
      (i) => i.filterName === viewStudyText.DATE_RANGE
    );
    setFilters(cancelledCustomFilter);
    setFilteredCnt();
    let updatedParams = {};
    if (cancelledCustomFilter.length > 0) {
      updatedParams = paramsFunc(cancelledCustomFilter, true);
    }
    updatedParams.page_num = 0;
    apiCall(updatedParams, false);
    setAppliedFilters(cancelledCustomFilter)
  }
  const sex = viewStudyText.SEX_OPTIONS;

  const paramsFunc = (newFilter, onCancel, removeDt, removedFilter) => {
    let consFilters = [];
    if (onCancel) {
      consFilters = [...newFilter];
    } else if (newFilter !== null && newFilter !== undefined) {
      consFilters = [newFilter];
    } else if (removeDt) {
      consFilters = removedFilter;
    } else {
      consFilters = [...filters];
    }
    setAppliedFilters(consFilters)
    let updatedParams = {};
    updatedParams =
      consFilters.length === 1 && consFilters[0] === undefined
        ? {}
        : consFilters
            .filter((value) => Object.keys(value).length !== 0)
            .reduce((obj, item) => {
              return item.operator === constantValues.BETWEEN
                ? Object.assign(obj, {
                    [item.filterName
                      .toLowerCase()
                      .replace(
                        / +/g,
                        ""
                      )]: `${item.operator}^${item.value1}^${item.value2}^${item.lastOperator}`,
                  })
                : Object.assign(obj, {
                    [item.filterName
                      .toLowerCase()
                      .replace(/ +/g, "")]: `${item.operator}^${item.value1}^${item.lastOperator}`,
                  });
            }, {});
    return updatedParams;
  };

  const apiCall = (updatedParams, fetchDt, searchTxt) => {
    if (searchTxt !== null && searchTxt !== undefined) {
      updatedParams.search = searchTxt;
    } else {
      updatedParams.search = searchText;
    }

    let config = {
      params: updatedParams,
    };
    axios.get(`${apiUrl}/${urls.EXPLORE_DATASET}`, config).then((response) => {
      if (response.status === 200) {
        if (!fetchDt) {
          setPageNum(1);
        } else {
          setPageNum(pageNum + 1);
        }
        if (response.data.data.length > 0) {
          setMoreData(true);
          setTotalStudies(response.data.total);
          setFilteredCnt(response.data.filtered_count);
          let formattedData = response.data.data;
          if (fetchDt) {
            let allItems = items.concat(formattedData);
            setItems(allItems);
          } else {
            setItems(formattedData);
            setFilteredCnt(response.data.filtered_count);
          }
          if (response.data.data.length < 20) {
            setMoreData(false);
          }
        } else {
          setMoreData(false);
          if (!fetchDt) {
            setItems([]);
            setTotalStudies(response.data.total);
            setFilteredCnt(response.data.filtered_count);
          }
        }
      } else {
        message.error(`${constantValues.ERR_MSG}`);
      }
    })
    .catch(() =>{
      console.log("error")
    })
  };

  const onClickApply = (newFilter, dateRange, removeDt, removedFilter) => {
    let updatedParams = {};
    if (dateRange) {
      updatedParams = paramsFunc(newFilter);
    } else if (removeDt) {
      updatedParams = paramsFunc(null, false, true, removedFilter);
    } else {
      updatedParams = paramsFunc(null);
    }
    updatedParams.page_num = 0;
    apiCall(updatedParams, false);
    setFilterPage(false);
  };

  const fetchData = () => {
    let updatedParams = {};
    updatedParams = paramsFunc();
    updatedParams.page_num = pageNum;
    apiCall(updatedParams, true);
  };

  function handleGenderSelect(filterName, value) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        i.value1 = value.join("^");
      }
      return i;
    });
    setFilters(newFilters);
  }

  const onChangeSearch = (event) => {
    setSearchText(event.target.value);
  };

  function onAddCohort() {
    localStorage.setItem("savedFilters", JSON.stringify(filters));
    localStorage.setItem("searchTxt", searchText);
    instance.loginRedirect(accessTokenRequest).catch((e) => {
      console.log(e);
    });
  }

  const onCloseFilter = (filter) => {
    let activeFilter = filters.filter(
      (i) => i.filterName !== filter.filterName
    );
    setFilters([...activeFilter]);
  };

  const hasNullOrUndefined = /* subGrp.some(subGroup => {
    return subGroup. */filters.some(filter => {
      const { operator, value1, value2, filterName } = filter;
      if (filterName!== "" && (!operator || operator && operator.trim() === "")) {
        return true; // Operator is null, undefined, or ""
      }
  
      if (filterName!== "" && (!value1 || value1 && value1.trim() === "")) {
        return true; // Value1 is null, undefined, or ""
      }
  
      if (filterName!== "" && operator === "BETWEEN") {
        if (!value2 || value2.trim() === "") {
          return true; // Value2 is null, undefined, or ""
        }
      }
  
      return false;
    });

  const itemDiv = (
    <Col xs={24} xl={24}>
      <div className="padding2">
        <Row>
          {items &&
            items.map((i) => (
              <Col span={12}>
                <div className="paddingTop2">
                  <Card
                    title={i.title}
                    extra={
                      <div className="d-flex flex-row">
                        <div className="mr=0">
                          v{i.release_version}&nbsp;
                        </div>
                        <div className="visibilityTextStyle">
                          {i.visibility &&
                            i.visibility.charAt(0).toUpperCase() +
                              i.visibility.slice(1)}
                        </div>
                      </div>
                    }
                    className="cardStyle"
                    style={{ width:"98%"
                    }}
                    headStyle={{background: "#FAFAFA 0% 0% no-repeat padding-box",
                    border: "1px solid #D9D9D9",
                    font: "normal normal 600 14px/22px Arial"}}
                    bodyStyle={{  background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #D9D9D9",
                    paddingLeft: "0%",
                    paddingRight: "0%",
                    height: "10%",}}
                  >
                    <Row className="pl-3">
                      <Col xs={10} xl={10}>
                        <span className="marginRight contentHeadingTextStyle"
                        >
                          {newStudyText.SAMPLE_SIZE}:
                        </span>
                        <span className="marginRight">
                          {i.sample_size}
                        </span>
                      </Col>
                      <Col xs={7} xl={7}>
                        <span
                          className="marginRight contentHeadingTextStyle"
                        >
                          {newStudyText.START_DATE}
                          :
                        </span>
                          <span>{i.start_date}</span>
                      </Col>
                      <Col xs={7} xl={7}>
                        <span
                          className="marginRight contentHeadingTextStyle"
                        >
                          {newStudyText.END_DATE}
                          :
                        </span>
                          {i.end_date === null ? <span>Ongoing</span> :<span>{i.end_date}</span> }
                      </Col>
                    </Row>
                    <p className="pl-3"
                    >
                      {i.description || ""}
                    </p>
                    <Row className="pl-3">
                      <Col xs={12} xl={12}>
                        <span className="contentHeadingTextStyle">
                          {newStudyText.DOMAINS}
                        </span>
                      </Col>
                      <Col xs={12} xl={12}>
                        <span className="contentHeadingTextStyle">
                          {newStudyText.MODALITIES}
                        </span>
                      </Col>
                    </Row>
                    <Row className="pl-3 mb-5"
                    >
                      <Col xs={12} xl={12}>
                        <div className="flexWrapStyle">
                          {i.domains.map((j) => (
                            <span className="marginRight5" style={{...NewStudyStyle.domainStyle,}}
                            >
                              {j}
                            </span>
                          ))}
                        </div>
                      </Col>
                      <Col xs={12} xl={12}>
                        <div className="flexWrapStyle">
                          {i.data_modalities.map((j) => (
                            <span className="marginRight5" style={{...NewStudyStyle.modalityStyle,}}
                            >
                              {j.modality}
                            </span>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Row xs={24} xl={24} className="pl-3">
                      <Col xs={12} xl={12}>
                        <Button>
                          <a href={i.release_doi} target="_blank">
                            {" "}
                            {i.release_doi} <SelectOutlined rotate={90} />{" "}
                          </a>
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        xl={12}
                        className="cardFooterBtnStyle"
                      >
                        {" "}
                        <Button onClick={() => onAddCohort()} className="mb-2">
                          {viewStudyText.ADD_COHORT} <PlusSquareOutlined />
                        </Button>
                        <Button
                          type={constantValues.PRIMARY}
                          className="ml-5"
                          onClick={() => onOpenStudyDetails(i)}
                        >
                          {viewStudyText.DETAILS}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </Col>
  );

  const filterPg = (
    <div>
      <div className="filterHdOuterStyle">
        <span className="filterSampleHdStyle">
          {viewStudyText.FILTERED_SAMPLES}:
        </span>
        <span className="filterCntStyle">
          {filters.length > 0 ? (
            <span>
              {filteredCnt === "" ||
              filteredCnt === null ||
              filteredCnt === undefined ? (
                <span>{totalStudies}</span>
              ) : (
                <span> {filteredCnt}</span>
              )}{" "}
              /{totalStudies}
            </span>
          ) : (
            <span>
              {totalStudies}/{totalStudies}
            </span>
          )}
        </span>
        <span>
          <Button onClick={onCloseModal}>
            <CloseOutlined />
          </Button>
        </span>
      </div>
      <Divider style={{ margin: "1% 0% 1% 0%" }} />
      <div className="filtersOutStyle filterOverFlowStyle"
      >
        {filters.length > 0 ? (
          <div>
            {" "}
            {filters
              .filter((x) => x.filterName !== viewStudyText.DATE_RANGE)
              .map((i) => (<div>
                <Row>
                <div className="filterNameOutStyle">
                  <div className="filterNmInnStyle">
                    <span className="filterNmTxtStyle">
                      {i.filterName}
                    </span>
                  </div>

                  {i.filterName === viewStudyText.AGE ||
                  i.filterName === viewStudyText.WEIGHT ||
                  i.filterName === viewStudyText.SAMPLE_SIZE ||
                  i.filterName === viewStudyText.ACQUISITION_DATE ? (
                    <div className="filterOperStyle">
                      <Select
                        className="filterOperSelectStyle"
                        onChange={(e) => handleOperatorFilter(i.filterName, e)}
                        options={viewStudyText.OPERATOR_OPTIONS}
                        value={i.operator}
                      />
                    </div>
                  ) : (
                    <div className="filterOperStyle">
                      <Select
                        className="filterOperSelectStyle"
                        onChange={(e) => handleOperatorFilter(i.filterName, e)}
                        options={viewStudyText.OPERATOR_INC_EXC_OPT}
                        value={i.operator}
                      />
                    </div>
                  )}
                  {i.filterName === viewStudyText.AGE ||
                  i.filterName === viewStudyText.WEIGHT ||
                  i.filterName === viewStudyText.SAMPLE_SIZE ||
                  i.filterName === viewStudyText.ACQUISITION_DATE ? (
                    <div className="w-30 filterOperStyle"
                    >
                      {" "}
                      {i.filterName === viewStudyText.ACQUISITION_DATE ? (
                        <DatePicker
                        className="hw100"
                          format={constantValues.YYYMMDD}
                          style={{ height: "35px"}}
                          onChange={(date, dateStr) =>
                            handleValueDate(i.filterName, true, date, dateStr)
                          }
                          value={i.value1 ? dayjs(i.value1, 'YYYY-MM-DD'): ""}
                        />
                      ) : (
                        <Input
                          type={constantValues.NUMBER}
                          step="1"
                          className="filterVal1Style"
                          onChange={(e) => handleValue(i.filterName, true, e)}
                          value={i.value1}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="width42 filterOperStyle"
                    >
                      <Select
                        mode={constantValues.MULTIPLE}
                        allowClear
                        className="filterOperSelectStyle"
                        placeholder={constantValues.PLEASE_SELECT}
                        onChange={(e) => handleGenderSelect(i.filterName, e)}
                        options={(() => {
                          switch (i.filterName) {
                            case viewStudyText.SEX:
                              return sex.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            case newStudyText.DATA_MODALITIES:
                              return customFilterOpt.data_modalities.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.EXPERIMENTAL_COND:
                              return customFilterOpt.experimental_condition.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.INSTRUMENT:
                              return customFilterOpt.instrument.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            case viewStudyText.OTHER_DIAGNOSES:
                              return customFilterOpt.other_diagnoses.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.PRIMARY_DIAGNOSIS:
                              return customFilterOpt.primary_diagnosis.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.RELEASE_VERSION:
                              return customFilterOpt.release_version.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.SUBJECT_GROUPS:
                              return customFilterOpt.participant_groups.map(
                                (a) => ({ value: a, label: a })
                              );
                            case newStudyText.TIMELINES:
                              return customFilterOpt.timelines.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            default:
                              return null;
                          }
                        })()}
                        value={i.value1 ? i.value1.split("^") : []}
                      />
                    </div>
                  )}
                  {i.operator === constantValues.BETWEEN ? (
                    <div className="filterAmpOutStyle">
                      <span className="filterAmpSty"> &amp; </span>
                    </div>
                  ) : null}
                  {i.operator === constantValues.BETWEEN ? (
                    <div className="w-30 filterOperStyle"
                    >
                      {" "}
                      {i.filterName === viewStudyText.ACQUISITION_DATE ? (
                        <DatePicker className="hw100"
                          format={constantValues.YYYMMDD}
                          style={{ height: "35px"}}
                          onChange={(date, dateStr) =>
                            handleValueDate(i.filterName, false, date, dateStr)
                          }
                          value={i.value2 ? dayjs(i.value2, 'YYYY-MM-DD'): ""}
                        />
                      ) : (
                        <Input
                          type={constantValues.NUMBER}
                          className="filterVal1Style"
                          onChange={(e) => handleValue(i.filterName, false, e)}
                          value={i.value2}
                        />
                      )}
                    </div>
                  ) : null}
                                    <div style={{ marginLeft: "3%", marginTop: "1%" }}>
                    {" "}
                    <CloseOutlined onClick={() => onCloseFilter(i)} />{" "}
                  </div>
                </div>

                </Row>
                {(i.operator === null || i.operator === undefined || i.value1 === null || i.value1 === undefined) && 
              <div style={{color : "red"}}>Select / Enter proper value for all fields</div> } </div>
              ))}{" "}
          </div>
        ) : (
          <div className="filterNoneStyle">
            {" "}
            {constantValues.NO_FILTER_ADDED}{" "}
          </div>
        )}
      </div>
      <Divider style={{ margin: "1% 0% 1% 0%" }} />
      <div className="filterHdOuterStyle">
        <Select className="w-30"
          onChange={handleChange}
          defaultValue={filterVal}
          options={viewStudyText.CUSTOM_FILTER_OPTIONS}
          value={filterVal}
        />
        <Button className="marginLeft40" onClick={onCancel}>
          {constantValues.CLEAR}
        </Button>
        <Button className="marginLeft3"
          type={constantValues.PRIMARY} disabled={hasNullOrUndefined}
          onClick={() => onClickApply(null, false)}
        >
          {constantValues.APPLY}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Col xs={24} xl={24}>
        <div className="headerStyle">
          <Row>
            <Col xs={20} xl={20}>
              <div className="headerLineStyle">
                <span className="headerSpanStyle">
                  <Search
                    className="searchStyle"
                    placeholder={constantValues.SEARCH}
                    onSearch={onSearch}
                    onChange={onChangeSearch}
                    enterButton
                    value={searchText}
                  />
                </span>
                <span className="headerSpanStyle">
                  <RangePicker
                    picker={constantValues.YEAR}
                    onChange={(date, dateStr) => handleDateRange(date, dateStr)}
                  />
                </span>
              </div>
            </Col>
            <Col xs={4} xl={4}>
              <div className="marginTop10">
                <span>
                  {" "}
                  <Button type={constantValues.PRIMARY} onClick={filterClick}>
                    {viewStudyText.CUSTOM_FILTER}
                    <FilterOutlined />
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} xl={24}>
              {" "}
              {appliedFilters.length > 0 ? (
                <div className="studyCntStyle">
                  <span className="contentHeadingTextStyle">
                    {viewStudyText.SHOWING}:{" "}
                  </span>{" "}
                  <span>
                    {filteredCnt === "" ||
                    filteredCnt === null ||
                    filteredCnt === undefined ? (
                      <span>{totalStudies}</span>
                    ) : (
                      <span> {filteredCnt}</span>
                    )}{" "}
                    / {totalStudies} {viewStudyText.STUDIES_NOW}
                    {filteredCnt === "" ||
                    filteredCnt === null ||
                    filteredCnt === undefined
                      ? null
                      : appliedFilters[0] !== undefined && appliedFilters.map((i) => (
                        (i!== undefined ? 
                          <span>
                            ,{" "}
                            <span
                              className="contentHeadingTextStyle"
                            >
                              {i.filterName}:{" "}
                            </span>{" "}
                            {i.operator}{" "}
                            {i.value1 && i.value1.replaceAll("^", ",")}{" "}
                            {i.value2 ? (
                              <span>
                                {constantValues.AND} {i.value2}
                              </span>
                            ) : null}
                          </span>   : null )
                        ))}
                    <span>.</span>
                  </span>
                </div>
              ) : (
                <div className="studyCntStyle">
                  <span className="contentHeadingTextStyle">
                    {viewStudyText.SHOWING}:{" "}
                  </span>{" "}
                  <span>
                    {filteredCnt === "" ||
                    filteredCnt === null ||
                    filteredCnt === undefined ? (
                      <span>{totalStudies}</span>
                    ) : (
                      <span> {filteredCnt}</span>
                    )}{" "}
                    / {totalStudies} {viewStudyText.STUDIES_NOW}
                    {viewStudyText.NO_FILTERS_ADDED}{" "}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={24} xl={24}>
            <InfiniteScroll
              dataLength={items.length}
              next={fetchData}
              hasMore={moreData}
              loader={
                <h4 className="centreText">{viewStudyText.LOADING}</h4>
              }
              endMessage={null}
            >
              {itemDiv}
            </InfiniteScroll>
          </Col>
        </Row>
      </Col>
      <Modal
        closable={false}
        width={700}
        centered={true}
        footer={null}
        title={null}
        open={filterPage}
      >
        {filterPg}
      </Modal>
    </div>
  );
}
