import { NewStudyStyle } from "../styles/NewStudy";
import "../styles/CommonStyles.css";
import { detailedStudyText, newStudyText } from "../constants/displayText";
import { Row, Col, Button, Tooltip } from "antd";
import React from "react";
import "../styles/DetailedStudyStyle.css";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function StudyDetail(props) {
  const [descExpanded, setDescExpanded] = React.useState(false);
  const contributors =
    props.details &&
    props.details.contributors &&
    props.details.contributors.length > 0
      ? props.details.contributors.map((i) => i.name).join(", ")
      : "";

  const timelines =
    props.details &&
    props.details.timelines &&
    props.details.timelines.length > 0
      ? props.details.timelines.map((i) => i).join(", ")
      : "";

  const domains =
    props.details && props.details.domains && props.details.domains.length > 0
      ? props.details.domains.map((j) => (
          <span className="mr-1 pl-1 pr-1 mb-2"
            style={{
              ...NewStudyStyle.domainStyle,
            }}
          >
            {j}
          </span>
        ))
      : "";

  const modalities =
    props.details &&
    props.details.data_modalities &&
    props.details.data_modalities.length > 0
      ? props.details.data_modalities.map((j) => (
            <div style={{display:"flex",flexDirection:"row", marginRight:"2%"}}>
            <span className="mr-1 mr-2 pl-2 pr-2 mb-2" style={{ ...NewStudyStyle.modalityStyle, 
                 }}>{j.modality}</span>
            <Tooltip
                placement="topRight"
                title={ <div>
                  <div>{detailedStudyText.DATA_FORMATS}:</div>
                  <div>{j.data_formats ? j.data_formats.map(k=> <div>{k}</div>) : null}</div>
                  <div>{detailedStudyText.SAMPLE_SIZE}:</div>
                  <div>{j.sample_size ? j.sample_size : null}</div>
                  <div>{detailedStudyText.TOTAL_BYTES}:</div>
                  <div>{j.total_bytes ? formatBytes(j.total_bytes) : null}</div>
                </div>}
                >
            <InfoCircleOutlined style={{marginRight:"5%"}}/></Tooltip>
            </div>
        ))
      : "";

  const handleDescExp = () => {
    setDescExpanded(!descExpanded);
  };
 
  function formatBytes(a,b=2){if(!+a)return"0 Bytes";
  const c=0>b?0:b,
  d=Math.floor(Math.log(a)/Math.log(1024));
  return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`}

  return (
    <Col xs={24} xl={24}>
      <Row className="backgroundStyle">
        <Col xs={12} xl={12}>
          <Row>
            <Col xs={8} xl={8}>
              <span style={NewStudyStyle.divTextStyle}>
                {newStudyText.SAMPLE_SIZE}
              </span>
            </Col>
            <Col xs={16} xl={16}>
              <span
                style={{
                  ...NewStudyStyle.uploadedDataStyle,
                  ...NewStudyStyle.padding8,
                }}
              >
                {props.details && props.details.sample_size
                  ? props.details.sample_size
                  : ""}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={12}>
          <Row>
            <Col xs={8} xl={8}>
              <span style={NewStudyStyle.divTextStyle}>
                {newStudyText.VERSION}
              </span>
            </Col>
            <Col xs={16} xl={16}>
              <span
                style={{
                  ...NewStudyStyle.uploadedDataStyle,
                  ...NewStudyStyle.padding8,
                }}
              >
                {props.details && props.details.release_version
                  ? props.details.release_version
                  : ""}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="backgroundStyle">
        <Col xs={3} xl={3}>
          <span style={NewStudyStyle.divTextStyle}>
            {newStudyText.DESCRIPTION}
          </span>
        </Col>
        <Col xs={21} xl={21}>
          <div
            style={{
              ...NewStudyStyle.uploadedDataStyle,
              ...NewStudyStyle.padding8,
            }}
          >
            {" "}
            {props.details && props.details.description ? (
              descExpanded ? (
                <div>
                  <span
                    style={{
                      ...NewStudyStyle.uploadedDataStyle,
                    }}
                  >
                    {props.details && props.details.description
                      ? props.details.description
                      : ""}
                  </span>
                  <Button
                    className="readMoreStyle"
                    onClick={handleDescExp}
                  >
                    {detailedStudyText.READ_LESS}
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <span
                    style={{
                      ...NewStudyStyle.uploadedDataStyle,
                      
                    }}
                  >
                    {props.details && props.details.description
                      ? props.details.description.substring(0, 1000)
                      : ""}
                  </span>
                  {props.details && props.details.description && props.details.description.length > 1000
                      ?
                  <Button
                    className="readMoreStyle"
                    onClick={handleDescExp}
                  >
                    {detailedStudyText.READ_MORE}
                  </Button> : null}
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>

      <Row className="backgroundStyle">
        <Col xs={3} xl={3}>
          <span style={NewStudyStyle.divTextStyle}>
            {newStudyText.CONTRIBUTORS}
          </span>
        </Col>
        <Col xs={21} xl={21}>
          <div
            style={{
              ...NewStudyStyle.uploadedDataStyle,
              ...NewStudyStyle.padding8, 
            }}
          >
            {contributors}
          </div>
        </Col>
      </Row>

      <Row className="backgroundStyle">
        <Col xs={3} xl={3}>
          <span style={NewStudyStyle.divTextStyle}>{newStudyText.DOMAINS}</span>
        </Col>
        <Col xs={21} xl={21}>
          <div
            style={{
              ...NewStudyStyle.uploadedDataStyle,
              ...NewStudyStyle.padding8,
              flexWrap: "wrap"
            }}
          >
            {domains}
          </div>
        </Col>
      </Row>

      <Row className="backgroundStyle">
        <Col xs={3} xl={3}>
          <span style={NewStudyStyle.divTextStyle}>
            {newStudyText.DATA_MODALITIES}
          </span>
        </Col>
        <Col xs={21} xl={21}>
          <div 
            style={{
              ...NewStudyStyle.uploadedDataStyle,
              ...NewStudyStyle.padding8,
              ...NewStudyStyle.dispFlex,
              flexWrap: "wrap"
            }}
          >
            {modalities}
          </div>
        </Col>
      </Row>

      <Row className="backgroundStyle">
        <Col xs={12} xl={12}>
          <Row>
            <Col xs={8} xl={8}>
              <span style={NewStudyStyle.divTextStyle}>
                {newStudyText.START_DATE}
              </span>
            </Col>
            <Col xs={16} xl={16}>
              <span
                style={{
                  ...NewStudyStyle.uploadedDataStyle,
                  ...NewStudyStyle.padding8,
                }}
              >
                {props.details && props.details.start_date
                  ? props.details.start_date
                  : ""}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={12}>
          <Row>
            <Col xs={8} xl={8}>
              <span style={NewStudyStyle.divTextStyle}>
                {props.details && props.details.ongoing_date
                  ? newStudyText.ONGOING_DATE
                  : newStudyText.END_DATE}
              </span>
            </Col>
            <Col xs={16} xl={16}>
              <span
                style={{
                  ...NewStudyStyle.uploadedDataStyle,
                  ...NewStudyStyle.padding8,
                }}
              >
                {props.details && props.details.ongoing_date
                  ? props.details.ongoing_date
                  : props.details.end_date
                  ? props.details.end_date
                  : props.details.start_date
                  ? detailedStudyText.ONGOING
                  : props.details.description
                  ? detailedStudyText.PENDING
                  : ""}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="backgroundStyle">
        <Col xs={3} xl={3}>
          <span style={NewStudyStyle.divTextStyle}>
            {newStudyText.TIMELINES}
          </span>
        </Col>
        <Col xs={21} xl={21}>
          <span
            style={{
              ...NewStudyStyle.uploadedDataStyle,
              ...NewStudyStyle.padding8,
            }}
          >
            {timelines}
          </span>
        </Col>
      </Row>

      <Row
        className="backgroundStyle mb-2"
      >
        <Col xs={12} xl={12}>
          <Row>
            <Col xs={8} xl={8}>
              <span style={NewStudyStyle.divTextStyle}>
                {newStudyText.TOTAL_FILES}
              </span>
            </Col>
            <Col xs={16} xl={16}>
              <span
                style={{
                  ...NewStudyStyle.uploadedDataStyle,
                  ...NewStudyStyle.padding8,
                }}
              >
                {props.details && props.details.total_files
                  ? props.details.total_files
                  : ""}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={12}>
          <Row>
            <Col xs={8} xl={8}>
              <span style={NewStudyStyle.divTextStyle}>
                {newStudyText.TOTAL_FILE_SIZE}
              </span>
            </Col>
            <Col xs={16} xl={16}>
              <span
                style={{
                  ...NewStudyStyle.uploadedDataStyle,
                  ...NewStudyStyle.padding8,
                }}
              >
                {props.details && props.details.total_file_size
                  ? formatBytes(props.details.total_file_size)
                  : ""}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
