import React from "react";
import {
  ProfileOutlined,
  LikeOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { sideBarText } from "../constants/displayText";
import { Space, Image, Button } from "antd";
import SideBarStyle from "../styles/SideBar";
import { urls, pageLinks } from "../constants/urls";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

export default function SideBar(props) {
  const { instance } = useMsal();
  const navigate = useNavigate()
  const onLogout = () => {
    localStorage.clear();
    localStorage.setItem("session", true)
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/", 
    });
  };
const onImage = () => {
  navigate(`${pageLinks.VW_STUDY}`)
}
  return (
    <div>
      <Image
        style={{...SideBarStyle.logoStyle, cursor: "pointer" }}
        preview={false}
        src={process.env.PUBLIC_URL + "/static/media/logo.png"}
        alt="Brain Logo"
        onClick={onImage}
      />
      <div style={{paddingBottom: "7%", paddingLeft: "1.2%" }}>
        <a
          href={urls.VIEW_STUDY}
          style={{
            font: "normal normal normal 14px/24px Arial",
            color:
              props.pgName && props.pgName.page === "browseStudies"
                ? "#0065A4"
                : "#595959",
            paddingTop: "5%",
            paddingBottom: "5%",
            borderRadius: "8px",
            width: "83%",
            textDecoration:"none"
          }}
        >
          <ProfileOutlined style={{ paddingLeft: "9%", paddingRight: "2%" }} />
          {sideBarText.BROWSE_STUDIES}{" "}
        </a>
      </div>
      <div style={{ paddingBottom: "7%", paddingLeft: "1.2%" }}>
        <a
          href={pageLinks.ADMIN_REQUEST}
          style={{
            font: "normal normal normal 14px/24px Arial",
            color:
              props.pgName && props.pgName.page === "requests"
                ? "#0065A4"
                : "#595959",
            paddingTop: "5%",
            paddingBottom: "5%",
            borderRadius: "8px",
            width: "83%",
            textDecoration:"none"
          }}
        >
          <LikeOutlined style={{ paddingLeft: "9%", paddingRight: "2%" }} />
          <span style={{paddingTop:"3%"}}>{sideBarText.REQUEST}</span>
        </a>
      </div>
      <div style={{ paddingLeft: "1.2%" }}>
        <a
          style={{
            font: "normal normal normal 14px/24px Arial",
            color: "#595959",
            paddingTop: "4%",
            paddingBottom: "5%",
            borderRadius: "8px",
            width: "83%",
          }}
        >
          <LogoutOutlined
            style={{ ...SideBarStyle.iconStyle, paddingLeft: "9%" }}
          />
          <span
            onClick={() => onLogout()}
            style={{
              ...SideBarStyle.menuStyle,
              paddingLeft: "1%",
              border: "none",
              backgroundColor: "none",
              font: "normal normal normal 14px/24px Arial",
            }}
          >
            {sideBarText.LOGOUT}
          </span>
        </a>
      </div>
    </div>
  );
}
