import React from "react";
import { pageLinks } from "../constants/urls"

import { Navigate, useNavigate } from "react-router-dom";

export default function MyCohort(props) {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(`${pageLinks.VW_STUDY}`)
  },[])
  
  return (
    <div> 
    </div>
  );
}
