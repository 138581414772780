import React from "react";

import { Row, Col, Image, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import { pageLinks } from "../constants/urls"

export default function MyCohort(props) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = React.useState(null);
  const onLogin = async () => {    
      instance.loginRedirect(accessTokenRequest).catch((e) => {
        console.log(e);
      });
      localStorage.setItem("session", true)
  };
  const onExplore = () => {
    if(localStorage.getItem('authenticated')){
      navigate(`${pageLinks.VW_STUDY}`);
    }
    else {
      if(localStorage.getItem("session")) {
        onLogin()
      }
      else {
    navigate(`${pageLinks.EXPLORE_STUDY}`); }
    }
  };

  return (
    <div style={{ display: "flex", height:"100%vh",paddingLeft:"0%",marginLeft:"0%"}}>
          <div
            style={{
              flex: "0 0 40%",
              display: "flex",
            }}
          >
            <img
              style={{ maxWidth: "100%",marginLeft:"0%",paddingLeft:"0%", height:"100vh" }}
              src={process.env.PUBLIC_URL+"/static/media/LoginLogo.png"}
              alt="Brain image"
            />
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 10%",
            }}
          >
            <h1>Welcome to OBI's Brain-CODE Data Discovery Platform!</h1>
            <p>
              Here you can explore neuroscience research data managed by the
              Ontario Brain Institute (OBI). Once you have selected research
              studies of interest and created your data cohort, you can request
              access! Data are available as either Public access data or
              Controlled access data depending on the sensitivity and governance
              policies established by OBI. For more information about OBI data
              access policies please refer to the{" "}
              <a href="#">Governance Policies</a>.
            </p>
            <p>
              Before you explore data and submit requests for access, please
              make sure that you've read and agree to the{" "}
              <a href="#">Terms of Use</a>.
            </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {" "}
            <Button
              type="primary"
              style={{ borderRadius: "2px" }}
              onClick={() => onExplore()}
            >
              Explore Data
            </Button>{" "}
            <Button
              type="primary"
              style={{ marginLeft: "10px", borderRadius: "2px" }}
              onClick={() => onLogin()}
            >
              Login
            </Button>
          </div>
          </div>
    </div>
  );
}
