import React from "react";
import SteeringRequest from "../components/SteeringRequest";
import { Row, Col, Divider } from "antd";
import "../styles/PageStyle.css"

export default function SteeringCommitteePage(props) {

  return (
    <div> <div>
      <Row>
        <Col xs={24} xl={24}>
          <div className="contentStyle" style={{ border:"none"}}>
            <SteeringRequest />
          </div>
        </Col>
      </Row>
    </div>
    </div>
  );
}
