import React, {useState} from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Modal,
  message,
  Spin,
  Radio,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export default function DacSteeringLogin() {
    const [email, setEmail] = useState("");
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const handleEmailSubmit = async () => {
                    setShowOtpInput(true);

    };

    const handleOtpSubmit = async () => {
                    onCancel()
    }

    const onCancel = () => {

    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleEmailSubmit(); // Trigger the Next button click
        }
      };

    const handleOtpPress = (e) => {
        if (e.key === 'Enter') {
            handleOtpSubmit(); // Trigger the Next button click
        }
      };
   return (
    <div>
                <Modal
            visible={true}
            onCancel={onCancel}
            footer={null}
            title={"Let's get you verified first"}
            closable={false}
            maskClosable={false}
        >
            {showOtpInput ? (
                <div>
                    <Input
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        onKeyPress={handleOtpPress}
                        className="login-field"
                    />
                    <Button type="primary" onClick={handleOtpSubmit} style={{ marginRight: "4%", marginTop: "5%", backgroundColor:"#D2D3F2", color:"#000000" }}>
                        Verify OTP
                    </Button>
                    <div style={{marginTop:"2%"}}><i> *Didn’t get the OTP? Check your spam folder</i></div>
                </div>
            ) : (
                <div>
                    <Input
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="login-field"
                    />
                    <Button 
                        type="primary" 
                        onClick={handleEmailSubmit} 
                        style={{ marginTop: "5%", backgroundColor:"#D2D3F2", color:"#000000" }}
                    >
                        {loading ? <div><LoadingOutlined /> Next </div>: "Next" }
                    </Button>
                </div>
            )}
        </Modal>
    </div>
  );
}
