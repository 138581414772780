import React from "react";
import "../styles/CommonStyles.css";
import "../styles/ViewStudyStyle.css";
import { newStudyText, viewStudyText, statisticsText } from "../constants/displayText";
import { DatePicker, Button, Input, Select, message, Divider, Modal, Spin, Row, Col } from "antd";
import { constantValues } from "../constants/constantValues";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import { urls } from "../constants/urls";
import "../styles/StatisticsStyle.css";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom";

const sex = viewStudyText.SEX_OPTIONS;
const apiUrl = process.env.REACT_APP_API_URL;

export default function CustomFilter(props) {
  const { instance, accounts, inProgress, idToken } = useMsal();
  const navigate = useNavigate();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  React.useEffect(() => {
    if (props.details) {
      let savedFilters = props.details
      setSubGrp([...savedFilters]);
    } 
    if (props.custFilter) {
      setCustomFilterOpt(props.custFilter);
    }
    if(props.cohId){
      setCohortId(props.cohId)
    }
  }, [props]);

  const [filterVal, setFilterVal] = React.useState(
    viewStudyText.SELECT_FILTER_TOPIC
  );

  const [customFilterOpt, setCustomFilterOpt] = React.useState();
  const [cohortId, setCohortId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [subGrp, setSubGrp] = React.useState([]);

  const handleChange = (value, subGroupIndex) => {
    setFilterVal(viewStudyText.SELECT_FILTER_TOPIC);
     const existingFilterIndex = subGrp[subGroupIndex] && subGrp[subGroupIndex].filters &&
                                  subGrp[subGroupIndex].filters.findIndex(
      (filter) => filter.filterName === value
    );
  
    if (existingFilterIndex === -1) {
      setSubGrp((prevSubGroups) => {
        const updatedSubGroups = [...prevSubGroups];
        const currentSubGroup = updatedSubGroups[subGroupIndex];
        currentSubGroup.filters.push({ filterName: value });
        return updatedSubGroups;
      });
    }

    props.sendDt({ subGroups: subGrp });
  };

  const clearSubGrp = (grpIndex) => {
    setSubGrp((prevSubGrp) => {
      const updatedSubGrp = [...prevSubGrp];
      updatedSubGrp.splice(grpIndex, 1);
      return updatedSubGrp;
    });
  }

  function handleOperatorFilter(filterName, event, grpIndex) {
    setSubGrp((prevSubGrp) => {
      const updatedSubGrp = [...prevSubGrp];
      if (grpIndex >= 0 && grpIndex < updatedSubGrp.length) {
        const updatedFilters = [...updatedSubGrp[grpIndex].filters];
        updatedFilters.forEach((filter) => {
          if (filter.filterName === filterName) {
            filter.operator = event;
          }
        });
        updatedSubGrp[grpIndex].filters = updatedFilters;
      }
      return updatedSubGrp;
    });
  }
  

  function handleGenderSelect(filterName, value, grpIndex) {
    setSubGrp((prevSubGrp) => {
      const updatedSubGrp = [...prevSubGrp];
      if (grpIndex >= 0 && grpIndex < updatedSubGrp.length) {
        const updatedFilters = [...updatedSubGrp[grpIndex].filters];
        updatedFilters.forEach((filter) => {
          if (filter.filterName === filterName) {
            filter.value1 = value.join("^");
          }
        });
        updatedSubGrp[grpIndex].filters = updatedFilters;
      }
      return updatedSubGrp;
    });
  }
  

  function handleValue(filterName, value, event, grpIndex) {
    setSubGrp((prevSubGrp) => {
      const updatedSubGrp = [...prevSubGrp];
      if (grpIndex >= 0 && grpIndex < updatedSubGrp.length) {
        const updatedFilters = [...updatedSubGrp[grpIndex].filters];
        updatedFilters.forEach((filter) => {
          if (filter.filterName === filterName) {
            if (value) {
              filter.value1 = event.target.value;
            } else {
              filter.value2 = event.target.value;
            }
          }
        });
        updatedSubGrp[grpIndex].filters = updatedFilters;
      }
      return updatedSubGrp;
    });
  }
  

  function handleValueDate(filterName, value, date, dateStr, grpIndex) {
    setSubGrp((prevSubGrp) => {
      const updatedSubGrp = [...prevSubGrp];
      if (grpIndex >= 0 && grpIndex < updatedSubGrp.length) {
        const updatedFilters = [...updatedSubGrp[grpIndex].filters];
        updatedFilters.forEach((filter) => {
          if (filter.filterName === filterName) {
            if (value) {
              filter.value1 = dateStr;
            } else {
              filter.value2 = dateStr;
            }
          }
        });
        updatedSubGrp[grpIndex].filters = updatedFilters;
      }
      return updatedSubGrp;
    });
  }
  

  const onClickApply = () => {
try {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    let updatedParams = {};
  
      let query = {
        cohort_id: cohortId,
      };
    let config = {
      headers: {"Authorization" : `Bearer ${response.accessToken}`},
      params: query,
    }

  let updatedFilters = subGrp.map(subGroup => {
    return {
      filters: subGroup.filters
        .filter(item => item.filterName.trim() !== '') // Remove empty filter names
        .map(item => ({
          ...item,
          filterName: item.filterName.toLowerCase().replace(/ +/g, "")
        }))
    };
  });
  
    updatedParams.filters= updatedFilters
    updatedParams.cohort_name = props.cohName


    if(props.dataModality.length > 0){
        updatedParams = { ...updatedParams, datamodalities: props.dataModality };
      }
        if(props.instr.length > 0){
          updatedParams = { ...updatedParams, instrument:props.instr };
        }
    
    setLoading(true)
    axios
      .put(`${apiUrl}/${urls.SAVE_SUMMARY_STATISTICS}`, updatedParams, config)
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          props.sendDt({ filterData: response.data });
          props.sendDt({ isFilterOpen: false });
        } else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
    }
    catch(error) {

    }
  };

  const onCloseFilter = (filterName, subGroupIndex) => {
    const subgroupToUpdateIndex = subGrp.findIndex((subgroup, index) => index === subGroupIndex);
    if (subgroupToUpdateIndex !== -1) {
      const subgroupToUpdate = subGrp[subgroupToUpdateIndex];
      const updatedFilters = subgroupToUpdate.filters.filter((filter) => filter.filterName !== filterName.filterName);
      const updatedSubgroup = {
        ...subgroupToUpdate,
        filters: updatedFilters,
      };
      setSubGrp((prevSubGrp) => {
        const updatedSubGrp = [...prevSubGrp];
        updatedSubGrp[subgroupToUpdateIndex] = updatedSubgroup;
        return updatedSubGrp;
      });
      props.sendDt({ subGrp: [...subGrp] });
    }
  };

  function onCancel() {
    try {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    let updatedParams = {};
    //updatedParams.cohort_id = cohortId;
    let query = {
      cohort_id: cohortId,
    };
    if(props.dataModality.length > 0){
      updatedParams.datamodalities = props.dataModality
    }
      if(props.instr.length > 0){
        updatedParams.instrument = props.instr
      }
    updatedParams.cohort_name = props.cohName
    let config = {
      headers: {"Authorization" : `Bearer ${response.accessToken}`},
      params: query,
      //params: updatedParams
    }
    updatedParams.filters = []
    setLoading(true)
    axios
      .put(`${apiUrl}/${urls.SAVE_SUMMARY_STATISTICS}`, updatedParams, config)
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          props.sendDt({ filterData: response.data });
          props.sendDt({ filters: [] });
          setSubGrp([]);
        } else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
    }
    catch(error) {

    }
  }

  const hasNullOrUndefined = subGrp.some(subGroup => {
    return subGroup.filters.some(filter => {
      const { operator, value1, value2, filterName } = filter;
      if (filterName!== "" && (!operator || operator && operator.trim() === "")) {
        return true; // Operator is null, undefined, or ""
      }
  
      if (filterName!== "" && (!value1 || value1 && value1.trim() === "")) {
        return true; // Value1 is null, undefined, or ""
      }
  
      if (filterName!== "" && operator === "BETWEEN") {
        if (!value2 || value2.trim() === "") {
          return true; // Value2 is null, undefined, or ""
        }
      }
  
      return false;
    });
  });

  React.useEffect (() => {
    if(subGrp.length === 0) {
      document.querySelector("#add-sub-grp").click();
    }
  },[subGrp])

  return (
    <div>
      <Divider className="dividerStyle" > </Divider>
      <div
        className="filtersOutStyle filterOverFlowStyle"
          style ={{
          height:"330px"
        }}
      >

        {subGrp && subGrp.map((grp, index) => 
        <div className="d-flex flex-row w-100">
        <div className= "mb-2 mr-2 w-100" style={{border:"1px solid #b9b2b2", borderRadius:"6px", paddingLeft:"2%"}}>
        <div>
        {grp.filters.length > 0 ? (
          <div className="w-100">
            {" "}
            {grp.filters
              .filter((x) => x.filterName !== viewStudyText.DATE_RANGE && x.filterName !== "")
              .map((i) => ( <div>
                <Row>
                <div className="d-flex flex-row pt-2 w-100">
                  <div className="w-50 filterNmInnStyle">
                    <span className="filterNmTxtStyle">
                      {i.filterName}
                    </span>
                  </div>

                  {i.filterName === viewStudyText.AGE ||
                  i.filterName === viewStudyText.WEIGHT ||
                  i.filterName === viewStudyText.SAMPLE_SIZE ||
                  i.filterName === viewStudyText.ACQUISITION_DATE ? (
                    <div className="filterOperStyle">
                      <Select
                        className="filterOperSelectStyle"
                        onChange={(e) => handleOperatorFilter(i.filterName, e, index)}
                        options={viewStudyText.OPERATOR_OPTIONS}
                        value={i.operator}
                      />
                    </div>
                  ) : (
                    <div className="filterOperStyle">
                      <Select
                        className="filterOperSelectStyle"
                        onChange={(e) => handleOperatorFilter(i.filterName, e, index)}
                        options={viewStudyText.OPERATOR_INC_EXC_OPT}
                        value={i.operator}
                      />
                    </div>
                  )}
                  {i.filterName === viewStudyText.AGE ||
                  i.filterName === viewStudyText.WEIGHT ||
                  i.filterName === viewStudyText.SAMPLE_SIZE ||
                  i.filterName === viewStudyText.ACQUISITION_DATE ? (
                    <div
                      className="width30 filterOperStyle"
                    >
                      {" "}
                      {i.filterName === viewStudyText.ACQUISITION_DATE ? (
                        <DatePicker className="hw100"
                          format={constantValues.YYYMMDD}
                          style={{height: "35px"}}
                          onChange={(date, dateStr) =>
                            handleValueDate(i.filterName, true, date, dateStr, index)
                          }
                          value={i.value1 ? dayjs(i.value1, 'YYYY-MM-DD'): ""}
                        />
                      ) : (
                        <Input
                          type={constantValues.NUMBER}
                          step="1"
                          className="filterVal1Style"
                          onChange={(e) => handleValue(i.filterName, true, e, index)}
                          value={i.value1}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className="width42 filterOperStyle"
                    >
                      <Select
                        mode={constantValues.MULTIPLE}
                        allowClear
                        className="filterOperSelectStyle"
                        placeholder={constantValues.PLEASE_SELECT}
                        onChange={(e) => handleGenderSelect(i.filterName, e, index)}
                        options={(() => {
                          switch (i.filterName) {
                            case viewStudyText.SEX:
                              return sex.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            case newStudyText.DATA_MODALITIES:
                              return (
                                customFilterOpt &&
                                customFilterOpt.data_modalities.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case viewStudyText.EXPERIMENTAL_COND:
                              return customFilterOpt &&customFilterOpt.experimental_condition.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.INSTRUMENT:
                              return customFilterOpt &&customFilterOpt.instrument.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            case viewStudyText.OTHER_DIAGNOSES:
                              return customFilterOpt &&customFilterOpt.other_diagnoses.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.PRIMARY_DIAGNOSIS:
                              return customFilterOpt &&customFilterOpt.primary_diagnosis.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.RELEASE_VERSION:
                              return customFilterOpt &&customFilterOpt.release_version.map(
                                (a) => ({ value: a, label: a })
                              );
                            case viewStudyText.PARTICIPANT_GROUPS:
                              return customFilterOpt &&customFilterOpt.participant_groups.map(
                                (a) => ({ value: a, label: a })
                              );
                            case newStudyText.TIMELINES:
                              return customFilterOpt &&customFilterOpt.timelines.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            default:
                              return null;
                          }
                        })()}
                        value={i.value1 ? i.value1.toString().split("^") : []}
                      />
                    </div>
                  )}
                  {i.operator === constantValues.BETWEEN ? (
                    <div className="filterAmpOutStyle">
                      <span className="filterAmpSty"> &amp; </span>
                    </div>
                  ) : null}
                  {i.operator === constantValues.BETWEEN ? (
                    <div className="w-30 filterOperStyle"
                    >
                      {" "}
                      {i.filterName === viewStudyText.ACQUISITION_DATE ? (
                        <DatePicker className="hw100"
                          format={constantValues.YYYMMDD}
                          style={{height: "35px"}}
                          onChange={(date, dateStr) =>
                            handleValueDate(i.filterName, false, date, dateStr, index)
                          }
                          value={i.value2 ? dayjs(i.value2, 'YYYY-MM-DD'): ""}
                        />
                      ) : (
                        <Input
                          type={constantValues.NUMBER}
                          className="filterVal1Style"
                          onChange={(e) => handleValue(i.filterName, false, e, index)}
                          value={i.value2}
                        />
                      )}
                    </div>
                  ) : null}
                                    <div className="custom-filter-close">
                    {" "}
                    <CloseOutlined onClick={() => onCloseFilter(i, index)} className="me-2"/>{" "}
                  </div>
                </div>


                </Row>
                {(i.operator === null || i.operator === undefined || i.value1 === null || i.value1 === undefined) && 
                <div style={{color : "red"}}>Select / Enter proper value for all fields</div> } </div>
              ))}{" "}
          </div>
        ) : (
          <div className="filterNoneStyle" style={{marginBottom:"0%"}}>
            {" "}
            {constantValues.NO_FILTER_ADDED}{" "}
          </div>
        )}
      </div>
      <div className="filterHdOuterStyle pl-0 mb-1">
        <Select className="w-30"
          onChange={(e) => handleChange(e,index)}
          defaultValue={filterVal}
          // options={viewStudyText.CUSTOM_FILTER_OPTIONS.filter(
          //   (i) =>
          //     i.value !== "Instrument" && i.value !== "Data Modalities" && i.value !== "Release Version"
          // )}
          options = {customFilterOpt && customFilterOpt.filter_headers && customFilterOpt.filter_headers.map((filter) => {
            const label = statisticsText.FILTER_NAMES[filter.replace("_","")] || filter;
            return { value: statisticsText.FILTER_NAMES[filter.replace("_","")] || filter, label };
          })}
          value={filterVal}
          style={{ marginLeft:"0%"}}
        />

      </div> 
      </div>
      <CloseOutlined onClick={() => clearSubGrp(index)} className="mr-2"/>
      </div>
      )}
        <div style={{ justifyContent: "end", display: "flex", marginBottom: "1%" }}>
          <Button type="primary" id="add-sub-grp" onClick={() => {
            setSubGrp((prevSubGroups) => [...prevSubGroups, { filters: [{ filterName: "" }] }]);
          }
          }>Add Sub Group</Button>
        </div>
      </div>
      <div style={{display:"flex", justifyContent:"end"}}>
      <Button style={{ marginLeft: "20%" }} onClick={onCancel}>
          {constantValues.CLEAR}
        </Button>
        <Button className="marginLeft3" disabled={hasNullOrUndefined}
          type={constantValues.PRIMARY}
          onClick={() => onClickApply()}
        >
          {constantValues.APPLY}
        </Button>
      </div>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading}
      >
        <Spin />
      </Modal>
    </div>
  );
}
