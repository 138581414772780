import React from "react";
import SideBarUser from "../../components/sideBarUser";
import SideBarComp from "../../components/SideBar";
import ViewStudyComp from "../../components/ViewStudyList";
import { Row, Col } from "antd";
import "../../styles/PageStyle.css";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../../authConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urls, pageLinks } from "../../constants/urls";
import { constantValues } from "../../constants/constantValues";
import { message, Modal, Spin } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;

export default function ViewStudyPage(props) {
  const navigate = useNavigate();
  React.useEffect(() => {
    RequestAccessToken();
  }, []);

  const [userRole, setuserRole] = React.useState("");
  const [cohortList, setCohortList] = React.useState([]);
  const [cohortVal, setCohortVal] = React.useState(false);
  const [cohortNames, setCohortNames] = React.useState([]);
  const [isAuth, setIsAuth] = React.useState(false);
  const { instance, accounts, inProgress, idToken } = useMsal();
  const [loading, setLoading] = React.useState(false);

  function RequestAccessToken() {
    findRole();
  }

  async function findRole() {
    const tokRequest = {
      ...accessTokenRequest,
      account: accounts[0],
    };

    let authAccessToken;
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        authAccessToken = response.accessToken;
        let config = {
          headers: { Authorization: `Bearer ${authAccessToken}` },
          params: {
            userid: response && response.account.idTokenClaims.oid,
            user_name: response.account.name,
            mail_id: response.account.username,
          },
        };
        setLoading(true);
        try {
        axios.get(`${apiUrl}/${urls.USER_LOGIN}`, config).then((response) => {
          setLoading(false);
          if (response.status === 200) {
            if (response.data.new_user === true) {
              instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/", 
              });
            } else {
              setCohortList(response.data.cohort_list);
              localStorage.setItem(
                "cohList",
                JSON.stringify(response.data.cohort_list)
              );
              setCohortVal(true);
              localStorage.setItem(
                "userObjId",
                Object.values(response.data._id)
              );
              setIsAuth(true);
              localStorage.setItem("authenticated", true);
              localStorage.setItem("role", response.data.role);
              setuserRole(response.data.role);
              if(localStorage.getItem("studyId")!== null && localStorage.getItem("studyId")!==undefined ) {
                let id = localStorage.getItem("studyId")
                localStorage.removeItem("studyId")
                navigate(`${pageLinks.VW_STUDY}/${id}`)
              }
            }
          } else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
      } catch (e){

          console.log("Error in logging in ", e)
      }
      })
      .catch((e) => {
        instance.acquireTokenPopup(tokRequest).then((response) => {
          authAccessToken = response.accessToken;
          let config = {
            headers: { Authorization: `Bearer ${authAccessToken}` },
            params: {
              userid: response && response.account.idTokenClaims.oid,
              user_name: response.account.name,
              mail_id: response.account.username,
            },
          };
          setLoading(true);
          axios.get(`${apiUrl}/${urls.USER_LOGIN}`, config).then((response) => {
            setLoading(false);
            if (response.status === 200) {
              if (response.data.new_user === true) {
                instance.logoutPopup({
                  postLogoutRedirectUri: "/",
                  mainWindowRedirectUri: "/", 
                });
              } else {
                setCohortList(response.data.cohort_list);
                localStorage.setItem(
                  "cohList",
                  JSON.stringify(response.data.cohort_list)
                );
                setCohortVal(true);
                localStorage.setItem(
                  "userObjId",
                  Object.values(response.data._id)
                );
                setIsAuth(true);
                localStorage.setItem("authenticated", true);
                localStorage.setItem("role", response.data.role);
                setuserRole(response.data.role);
                if(localStorage.getItem("studyId")!== null && localStorage.getItem("studyId")!==undefined ) {
                  let id = localStorage.getItem("studyId")
                  localStorage.removeItem("studyId")
                  navigate(`${pageLinks.VW_STUDY}/${id}`)
                }
                else if(localStorage.getItem('page')!== null && localStorage.getItem('page')!== undefined) {
                  let url = localStorage.getItem('page')
                  window.location.href= url
                }
              }
            } else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
        })
         .catch((error) => {
          alert("Pop up is blocked, Please allow popup in browser settings")
        }) 
      })
  }

  const getCohortDetails = (param) => {
    setCohortList(param);
  };

  return (
    <div>
      {isAuth && cohortVal && (userRole === "admin" || userRole === "user") ? (
        <div>
          <Row>
            {userRole === "admin" ? (
              <Col xs={3} xl={3}>
                <div className="siderStyle">
                  <SideBarComp pgName={{ page: "browseStudies" }}></SideBarComp>
                </div>
              </Col>
            ) : (
              <Col xs={3} xl={3}>
                <div className="siderStyle">
                  <SideBarUser
                    pgName={{ page: "browseStudies" }}
                    cohortList={{ cohort: cohortList }}
                    getCohort={getCohortDetails}
                    reqBasket={{}}
                  ></SideBarUser>
                </div>
              </Col>
            )}
            <Col xs={21} xl={21}>
              <div className="contentStyle">
                {userRole === "admin" ? (
                  <ViewStudyComp details={{ role: true }}></ViewStudyComp>
                ) : userRole === "user" ? (
                  <ViewStudyComp
                    details={{ role: false }}
                    getCohort={getCohortDetails}
                  ></ViewStudyComp>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div> </div>
      )} 
      {loading ?
        (<div style={{marginLeft:"50%",marginTop:"20%"}}> <Spin /> </div> ) : null}
    </div>
  );
}
