import React from "react";
import SideBarComp from "../components/SideBar";
import AdmReqComp from "../components/AdminRequest";
import { Row, Col } from "antd";
import "../styles/PageStyle.css"
import { Navigate, Route, useLocation } from 'react-router-dom'

export default function AdminRequest(props) {
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('role'));
  const location = useLocation()
  return (
    <div> {isAuth ? 
    (<div>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle">
            <SideBarComp pgName={{page:"requests"}}></SideBarComp>
          </div>
        </Col>
        <Col xs={21} xl={21}>
          {location && location.state && location.state.statusUpd ?
          <div className="contentStyle">
            <AdmReqComp reqUpd={{status:true, reqTitle:location.state.requestTitle }}></AdmReqComp>
          </div> :
          <div className="contentStyle">
            <AdmReqComp></AdmReqComp>
          </div> }
        </Col>
      </Row>
      </div> ):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
