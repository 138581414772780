// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .contentStyle {
        color: #108ee9;
        background-color: #ffffff;
        border: 1px solid #D9D9D9;
        height: 100%;
      }
    
      .siderStyle {
        height: 100%;
        background-color: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 1px 0px 0px #DEDEDE;
        opacity: 1;
        border: 1px solid #D9D9D9;
      }
`, "",{"version":3,"sources":["webpack://./src/styles/PageStyle.css"],"names":[],"mappings":";IACI;QACI,cAAc;QACd,yBAAyB;QACzB,yBAAyB;QACzB,YAAY;MACd;;MAEA;QACE,YAAY;QACZ,qDAAqD;QACrD,+BAA+B;QAC/B,UAAU;QACV,yBAAyB;MAC3B","sourcesContent":["\n    .contentStyle {\n        color: #108ee9;\n        background-color: #ffffff;\n        border: 1px solid #D9D9D9;\n        height: 100%;\n      }\n    \n      .siderStyle {\n        height: 100%;\n        background-color: #FFFFFF 0% 0% no-repeat padding-box;\n        box-shadow: 1px 0px 0px #DEDEDE;\n        opacity: 1;\n        border: 1px solid #D9D9D9;\n      }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
