import React from "react";
import { Row, Col, Tabs, Button } from "antd";
import {
  detailedStudyVideoText,
  viewStudyText,
} from "../constants/displayText";
import { CloseOutlined } from "@ant-design/icons";
import "../styles/ViewStudyStyle.css";
import "../styles/CommonStyles.css";
import StickyBox from "react-sticky-box";

export default function StudyGrpInfo(props) {
  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 1 }}>
      <DefaultTabBar {...props} />
    </StickyBox>
  );

  const items = [
    {
      key: "1",
      label: detailedStudyVideoText.INCLUSION_CRITERIA,
      children: (
        <ol className="subGrpStyle">
          {" "}
          {props.details.incCri !== null
            ? props.details.incCri.map((i) => <li>{i}</li>)
            : detailedStudyVideoText.NO_DATA}
        </ol>
      ),
    },
    {
      key: "2",
      label: detailedStudyVideoText.EXCLUSION_CRITERIA,
      children: (
        <ol className="subGrpStyle">
          {" "}
          {props.details.excCri !== null
            ? props.details.excCri.map((i) => <li>{i}</li>)
            : detailedStudyVideoText.NO_DATA}
        </ol>
      ),
    },
    {
      key: "3",
      label: viewStudyText.PRIMARY_DIAGNOSIS,
      children: (
        <ol className="subGrpStyle">
          {" "}
          {props.details.primaryDiagnosis !== null
            ? props.details.primaryDiagnosis.map((i) => <li>{i}</li>)
            : detailedStudyVideoText.NO_DATA}
        </ol>
      ),
    },
  ];
  
  return (
    <div className="filterPgStyle">
      <div className="filterPgInnerStyle">
        <div className="filterBgStyle pl-2">
          <Col xs={24} xl={24}>
            <Row className="paddingTop2">
              <Col xs={20} xl={10}>
                <span
                  className="subGrpNameSty"
                >
                  {" "}
                  {props.details.grpName}
                </span>
              </Col>
              <Col xs={10} xl={10}>
                <span className="subGrpTextSty">
                  {" "}
                  {detailedStudyVideoText.SUB_PER_GRP_CNT}: {props.details.grpCnt}{" "}
                </span>
              </Col>
              <Col xs={4} xl={4}>
                <Button
                  onClick={() => {
                    props.sendDt(false);
                  }}
                >
                  {" "}
                  <CloseOutlined />{" "}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={24}>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  renderTabBar={renderTabBar}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </div>
  );
}
