import React from "react";
import axios from "axios";
import { newStudyText } from "../constants/displayText";
import {
  Breadcrumb,
  Button,
  Select,
  Upload,
  message,
  Row,
  Col,
  Modal,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import {
  UploadOutlined,
  ProfileOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import StudyDetailComp from "./StudyDetail";
import { useLocation, Link, useParams } from "react-router-dom";
import { NewStudyStyle } from "../styles/NewStudy";
import { pageLinks, urls } from "../constants/urls";
import { constantValues } from "../constants/constantValues";
import "../styles/CommonStyles.css";

const apiUrl = process.env.REACT_APP_API_URL;
const { Dragger } = Upload;

const { confirm } = Modal;

export default function NewStudy(props) {
  const navigate = useNavigate();
  const params = useParams();
  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  React.useEffect(() => {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => { 
      setAuthTok(response.accessToken)
    })
    .catch((e) => {
      navigate('/')
     })
    if (props && props.details && props.details.updStudy) {
      instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
      let config = {
        headers: { Authorization: `Bearer ${response.accessToken}` },
      };
      axios
        .get(`${apiUrl}/${urls.GET_STUDY_DETAIL_URL}${params.id}`, config)
        .then((response) => {
          if (response.status === 200) {
            setNewDetails(response.data);
            setUploadResp(response.data);
            setDatasetId(response.data.dataset_id);
            setSelectedVersion(response.data.release_version);
            setVersion(response.data.release_version);
            setSampleSizeValue(response.data.sample_size);
            setTotalFiles(response.data.total_files);
            setTotalFileSize(response.data.total_file_size);
            let allVersions = response.data.release_version_list.map((i) => {
              return { value: i.release_version, label: i.version };
            });
            setAllVersions(allVersions);
            setFile({ name: response.data.desc_file_name });
            setFileSamp({ name: response.data.sample_file_name });
            setFileSub({ name: response.data.sub_file_name });
            setVisibility(response.data.visibility);
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
        })
        .catch((e) => {
          navigate('/')
         })
    } else if (
      location &&
      location.state &&
      location.state.details &&
      location.state.details.newRelease
    ) {
      setNewDetails(location.state.details);
      setVersion(
        location.state.details.release_version
          ? location.state.details.release_version
          : ""
      );
      setSampleSizeValue(
        location.state.details.sample_size
          ? location.state.details.sample_size
          : ""
      );
      setTotalFiles(
        location.state.details.total_files
          ? location.state.details.total_files
          : ""
      );
      setTotalFileSize(
        location.state.details.total_file_size
          ? location.state.details.total_file_size
          : ""
      );
      location.state.details.desc_file_name
        ? setFile({ name: location.state.details.desc_file_name })
        : setFile(null);
      if (location.state.details.dataset_type === constantValues.DESCRIPTION) {
        setUploadResp(location.state.details);
      }
      location.state.details.samp_file_name
        ? setFileSamp({ name: location.state.details.samp_file_name })
        : setFileSamp(null);
      location.state.details.sub_file_name
        ? setFileSub({ name: location.state.details.sub_file_name })
        : setFileSub(null);
      setDatasetId(location.state.details.dataset_id);
    }
  }, [params]);
  const location = useLocation();
  const [file, setFile] = React.useState(null);
  const [fileSub, setFileSub] = React.useState(null);
  const [fileSamp, setFileSamp] = React.useState(null);
  const [visibility, setVisibility] = React.useState(
    "admin-only"
  );
  const [version, setVersion] = React.useState("");
  const [allVersions, setAllVersions] = React.useState("");
  const [selectedVersion, setSelectedVersion] = React.useState("");
  const [descUpdated, setDescUpdated] = React.useState(false);
  const [subUpdated, setSubUpdated] = React.useState(false);
  const [sampleUpdated, setSampleUpdated] = React.useState(false);
  const [datasetId, setDatasetId] = React.useState("");
  const [uploadResp, setUploadResp] = React.useState({});
  const [updUploadResp, setUpdUploadResp] = React.useState();
  const [newDetails, setNewDetails] = React.useState();
  const [totalFiles, setTotalFiles] = React.useState(0);
  const [totalFileSize, setTotalFileSize] = React.useState(0);
  const [sampleSizeValue, setSampleSizeValue] = React.useState(0);
  const [updatedFile, setUpdatedFile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [authTok, setAuthTok] = React.useState("");
  const [isConfirmationVisible, setIsConfirmationVisible] = React.useState(false);
  const [newValue, setNewValue] = React.useState("");

  const uploadProps = {
    name: "file",
    headers: {
      authorization: `Bearer ${authTok}`,
    },

    onChange: (info) => {
      setLoading(true);
      if (info.file.status === constantValues.DONE) {
        setLoading(false);
        var descData = {};
        if (
          info.file.response.dataset_type ===
          newStudyText.DESCRIPTION.toLowerCase()
        ) {
          // var currentFileSize = info.file.response.data.total_file_size;
          // setTotalFileSize(currentFileSize);
          descData = info.file.response.data;
          setUploadResp(info.file.response.data);
          setVersion(info.file.response.data.release_version);
          setDatasetId(info.file.response.data.dataset_id);
          // var sampleSizeVal = info.file.response.data.sample_size;
          // setSampleSizeValue(sampleSizeVal);
          setFile(info.file);
        } else if (
          info.file.response.dataset_type ===
          constantValues.SUBJECTS.toLowerCase()
        ) {
          setFileSub(info.file);
          var sampleSizeVal = info.file.response.data.sample_size;
          setSampleSizeValue(sampleSizeVal);
        } else {
          setFileSamp(info.file);
          var currentFileCount = info.file.response.data.total_files;
          setTotalFiles(currentFileCount);
          var currentFileSize = info.file.response.data.total_file_size;
          setTotalFileSize(currentFileSize);
        }
        let addedFields = {
          sample_size: sampleSizeVal || sampleSizeValue,
          total_files: currentFileCount || totalFiles,
          total_file_size: currentFileSize || totalFileSize,
        };
        message.success(`${constantValues.SUCCESS_FILE_UPLOAD_MSG}`);
        if (
          descData === null ||
          descData === undefined ||
          JSON.stringify(descData) === "{}"
        ) {
          descData = uploadResp;
        }
        let newDetails = Object.assign(descData, addedFields);
        setNewDetails(newDetails);
      } else if (info.file.status === constantValues.ERROR) {
        setLoading(false);
        if (
          info.file.response.dataset_type === constantValues.DESCRIPTION
        ) {
          setFile("");
        } else if (
          info.file.response.dataset_type === constantValues.SAMPLES
        ) {
          setFileSamp("");
        } else {
          setFileSub("");
        }
        message.error(`${info.file.name} ${info.file.response.error}`);
      }
    },
  };
  const uploadUpdateProps = {
    name: "file",
    headers: {
      authorization: `Bearer ${authTok}`,
    },

    beforeUpload: (file) => {
      setUpdatedFile(file);
    },

    onChange: (info) => {
      setLoading(true);
      if (info.file.status === constantValues.DONE) {
        setLoading(false);
        setUpdUploadResp(info.file.response);
        showConfirm(info.file.response, info.file);
      } else if (info.file.status === constantValues.ERROR) {
        setLoading(false);
        message.error(`${info.file.name} ${info.file.response.error}`);
      }
    },
  };

  const showConfirm = (modalMsg, file) => {
    confirm({
      title: modalMsg.title,
      icon: <ExclamationCircleFilled />,
      content: modalMsg.msg,
      cancelButtonProps: {
        style: { display: modalMsg.buttons.length > 1 ? "" : "none" },
      },
      onOk() {
        if (modalMsg.title === newStudyText.DIFF_VERSIONS) {
          return;
        } else if (modalMsg.title === newStudyText.NEW_VERSION_MSG) {
          const formData = new FormData();

          formData.append("file", updatedFile);
          setLoading(true);
          instance
          .acquireTokenSilent(tokRequest)
          .then((response) => {
          axios
            .post(
              `${apiUrl}/${urls.UPLOAD_URL}/${modalMsg.dataset_type}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${response.accessToken}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.status === 200) {
                let newRelFields = {};

                if (response.data.dataset_type === constantValues.DESCRIPTION) {
                  newRelFields = response.data.data;
                  newRelFields.desc_file_name = response.data.filename;
                  newRelFields.dataset_type = response.data.dataset_type;
                } else if (
                  response.data.dataset_type === constantValues.SAMPLES
                ) {
                  newRelFields = {
                    total_files: response.data.data.total_files,
                    total_file_size: response.data.data.total_file_size
                  };
                  newRelFields.samp_file_name = response.data.filename;
                  newRelFields.dataset_type = response.data.dataset_type;
                } else {
                  newRelFields.sample_size = response.data.sample_size
                  newRelFields.sub_file_name = response.data.filename;
                  newRelFields.dataset_type = response.data.dataset_type;
                }
                navigate(`${pageLinks.VW_STUDY}${pageLinks.NEW_RELEASE}`, {
                  state: { details: { newRelease: true, ...newRelFields } },
                });
              } else {
                message.error(`${constantValues.ERR_MSG}`);
              }
            })
            .catch(() =>{
              console.log("error")
            })
            })
            .catch((e) => {
              navigate('/')
             })
        } else {
          const formData = new FormData();

          formData.append("file", updatedFile);
          setLoading(true);
          instance
            .acquireTokenSilent(tokRequest)
            .then((response) => {
          axios
            .post(
              `${apiUrl}/${urls.UPDATE_STUDY_URL}/${modalMsg.dataset_type}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${response.accessToken}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.status === 200) {
                var descData = {};
                if (response.data.dataset_type === constantValues.DESCRIPTION) {
                  // var currentFileSize = response.data.data.total_file_size;
                  // setTotalFileSize(currentFileSize);
                  descData = response.data.data;
                  setUploadResp(response.data.data);
                  setVersion(response.data.data.release_version);
                  setDatasetId(response.data.data.dataset_id);
                  // var sampleSizeVal = response.data.data.sample_size;
                  // setSampleSizeValue(sampleSizeVal);
                  setFile(file);
                  setDescUpdated(true);
                  setSelectedVersion(response.data.data.release_version);
                } else if (
                  response.data.dataset_type === constantValues.SAMPLES
                ) {
                  setFileSamp(file);
                  setSampleUpdated(true);
                  var currentFileCount = response.data.data.total_files;
                  setTotalFiles(currentFileCount);
                  var currentFileSize = response.data.data.total_file_size;
                  setTotalFileSize(currentFileSize);
                  setSelectedVersion(version);
                } else {
                  setFileSub(file);
                  setSubUpdated(true);
                  var sampleSizeVal = response.data.data.sample_size;
                  setSampleSizeValue(sampleSizeVal);
                  setSelectedVersion(version);
                }
                let addedFields = {
                  sample_size: sampleSizeVal || sampleSizeValue,
                  total_files: currentFileCount || totalFiles,
                  total_file_size: currentFileSize || totalFileSize,
                };
                if (
                  descData.dataset_id === null ||
                  descData.dataset_id === undefined
                ) {
                  descData = uploadResp;
                }

                let newDet = Object.assign(descData, addedFields);
                setNewDetails(newDet);
                message.success(`${constantValues.SUCCESS_FILE_UPDATE_MSG}`);
              } else {
                message.error(`${constantValues.ERR_MSG}`);
              }
            })
            .catch(() =>{
              console.log("error")
            })
            })
            .catch((e) => {
              navigate('/')
             })
        }
      },
      onCancel() {},
      width: 700,
      centered: true,
    });
  };

  const handleChange = (value, oldValue) => {
    if(oldValue === "admin-only"){
      setIsConfirmationVisible(true)
      setNewValue(value)
    }
    else {    setVisibility(value); }
  };

  const onSaveBtn = (event) => {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    let query = {
      dataset_id: datasetId,
      release_version: version,
    };
    let data = {
      visibility: visibility || "",
      sample_size: sampleSizeValue || 0,
      total_files: totalFiles || 0,
      total_file_size: totalFileSize || 0,
      desc_file_name: file.name || "",
      sample_file_name: fileSamp.name || "",
      sub_file_name: fileSub.name || "",
    };
    let config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
      params: query,
    };
    axios
      .put(`${apiUrl}/${urls.NEW_STUDY_SAVE_URL}`, data, config)
      .then((response) => {
        if (response.status === 200) {
          message.success(`${constantValues.SUCCESS_SAVE_MSG}`);
          navigate('/studies')
        }
        else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
  };

  const onDeleteBtn = () => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
      let config = {
        headers: { Authorization: `Bearer ${response.accessToken}` },
      };
      axios
        .delete(`${apiUrl}/${urls.DELETE_STUDY}/${params.id}`, config)
        .then((response) => {
          if(response.status === 200){
            if(response.data.release_version_list.length > 0) {
              let slctedVersionId = response.data.release_version_list[0]
              navigate(`${pageLinks.VW_STUDY}${pageLinks.UPDATE_STUDY}/${Object.values(slctedVersionId._id)[0]}`)
            }
            else if(response.data.release_version_list.length === 0){
              navigate(`${pageLinks.VW_STUDY}`)
            }
          }
          else {
            message.error(`${constantValues.ERR_MSG}`)
          }
        })
        .catch(() =>{
          console.log("error")
        })
      })
      .catch((e) => {
        navigate('/')
       })
  }

  function disableSaveBtn() {
    if (
      (file !== null &&
        file !== undefined &&
        fileSub !== null &&
        fileSub !== undefined &&
        fileSamp !== null &&
        fileSamp !== undefined &&
        visibility !== "" &&
        visibility !== newStudyText.SELECT_VISIBILITY) ||
      (props && props.details && props.details.updStudy)
    ) {
      return false;
    }
    return true;
  }

  const handleVersionChange = (value) => {
    if (value === "newVersion") {
      navigate(`${pageLinks.VW_STUDY}${pageLinks.NEW_RELEASE}`, {
        state: { details: { newRelease: true } },
      });
    }
    else if (props.details.updStudy) {
      setSelectedVersion(value);
      let selectedVersion = newDetails.release_version_list.filter(
        (i) => i.release_version === value
      );
      let slctedVersionId = selectedVersion.length > 0 && selectedVersion[0]._id;
      navigate(`${pageLinks.VW_STUDY}${pageLinks.UPDATE_STUDY}/${Object.values(slctedVersionId)}`);
    }
  };

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = newStudyText.BREADCRUMBVALUES;

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (index === pathSnippets.length - 1) {
      return (
        <Breadcrumb.Item key={url}>{breadcrumbNameMap[url]}</Breadcrumb.Item>
      );
    }
    else if(url === "/studies/update"){
      return (
        <Breadcrumb.Item key={url}>{breadcrumbNameMap[url]}</Breadcrumb.Item>
      );
    }
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [].concat(extraBreadcrumbItems);

  const onConfirmVisibility = () => {
    setVisibility(newValue)
    setIsConfirmationVisible(false)
  }

  const onCancelVisibility = () => {
    setIsConfirmationVisible(false)
  }

  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <div style={NewStudyStyle.headerStyle}>
            <div>
              <Breadcrumb>
                <ProfileOutlined style={NewStudyStyle.breadCrumbStyle} />{" "}
                {breadcrumbItems}
              </Breadcrumb>
            </div>
            <div style={NewStudyStyle.titleStyle}>
              {uploadResp && uploadResp.title ? uploadResp.title : ""}
            </div>
            {props && props.details && props.details.updStudy ? (
              <Select
                onChange={handleVersionChange}
                defaultValue={version}
                value={`${constantValues.VERSION} ${selectedVersion}`}
                style={NewStudyStyle.versionStyle}
                options={[
                  ...allVersions,
                  { label: "+ New Release", value: "newVersion" },
                ]}
              />
            ) : null}
            <Button
              type={constantValues.PRIMARY}
              style={NewStudyStyle.buttonStyle}
              onClick={onSaveBtn}
              disabled={disableSaveBtn()}
            >
              {newStudyText.SAVE}
            </Button>
            <Button
              type={constantValues.PRIMARY}
              style={{...NewStudyStyle.buttonStyle, marginLeft:"1%"}}
              onClick={onDeleteBtn}
              disabled={!props?.details?.updStudy}
            >
              Delete
            </Button>
          </div>
        </Row>
        <Row>
          <Col xs={8} xl={8}>
            <div>
              <div style={NewStudyStyle.leftTextStyle}>
                {newStudyText.VISIBILITY}
              </div>
              <div className="marginLeft3">
                <Select
                  onChange={(value) => handleChange(value,visibility)}
                  value={visibility}
                  style={NewStudyStyle.visibilityStyle}
                  options={newStudyText.VISIBILITY_OPTIONS}
                />
              </div>
              <div style={NewStudyStyle.leftTextStyle}>
                {newStudyText.UPLOAD_DESC_JSON_FILE}
              </div>
              <div className="marginLeft3" >
                {props && props.details && props.details.updStudy ? (
                  <div>
                    <Dragger
                    className="noBorder"
                      
                      {...uploadUpdateProps}
                      showUploadList={false}
                      action={`${apiUrl}/${urls.GET_UPDATE_STUDY_URL}/${constantValues.DESCRIPTION}^${datasetId}^${selectedVersion}`}
                    >
                      {" "}
                      <Row>
                        <div style={NewStudyStyle.updateDivStyle}>
                          {descUpdated
                            ? file
                              ? file.name
                              : ""
                            : newDetails && newDetails.desc_file_name
                            ? newDetails.desc_file_name
                            : ""}{" "}
                        </div>{" "}
                      </Row>{" "}
                      <Row>
                        <div style={NewStudyStyle.updateBtnStyle}>
                          {" "}
                          <Button className="noBorder">
                            {" "}
                            <UploadOutlined className="pr-5"
                            />{" "}
                            {constantValues.UPDATE}{" "}
                          </Button>
                        </div>{" "}
                      </Row>
                    </Dragger>
                  </div>
                ) : (
                  <Upload
                    {...uploadProps}
                    showUploadList={false}
                    action={`${apiUrl}/${urls.DESCRIPTION_UPLOAD_URL}`}
                  >
                    {!file ? (
                      <Button
                        style={NewStudyStyle.uploadBtn}
                        icon={<UploadOutlined />}
                      >
                        {newStudyText.CLICK_TO_UPLOAD}
                      </Button>
                    ) : (
                      <Button style={NewStudyStyle.uploadBtn}>
                        {file.name}
                      </Button>
                    )}
                  </Upload>
                )}
              </div>

              <div style={NewStudyStyle.leftTextStyle}>
                {newStudyText.UPLOAD_SUB_JSON_FILE}
              </div>
              <div className="marginLeft3">
                {props && props.details && props.details.updStudy ? (
                  <div>
                    <Dragger className="noBorder"
                      
                      {...uploadUpdateProps}
                      showUploadList={false}
                      action={`${apiUrl}/${urls.GET_UPDATE_STUDY_URL}/${constantValues.SUBJECTS}^${datasetId}^${selectedVersion}`}
                    >
                      {" "}
                      <Row>
                        <div style={NewStudyStyle.updateDivStyle}>
                          {subUpdated
                            ? fileSub
                              ? fileSub.name
                              : ""
                            : newDetails && newDetails.sub_file_name
                            ? newDetails.sub_file_name
                            : ""}{" "}
                        </div>{" "}
                      </Row>{" "}
                      <Row>
                        <div style={NewStudyStyle.updateBtnStyle}>
                          {" "}
                          <Button className="noBorder">
                            {" "}
                            <UploadOutlined className="pr-5"
                            />{" "}
                            {constantValues.UPDATE}{" "}
                          </Button>
                        </div>{" "}
                      </Row>
                    </Dragger>
                  </div>
                ) : (
                  <Upload
                    {...uploadProps}
                    action={`${apiUrl}/${urls.SUBJECT_UPLOAD_URL}`}
                    showUploadList={false}
                  >
                    {" "}
                    {!fileSub ? (
                      <Button
                        style={NewStudyStyle.uploadBtn}
                        icon={<UploadOutlined />}
                      >
                        {newStudyText.CLICK_TO_UPLOAD}
                      </Button>
                    ) : (
                      <Button style={NewStudyStyle.uploadBtn}>
                        {fileSub.name}
                      </Button>
                    )}
                  </Upload>
                )}
              </div>
              <div style={NewStudyStyle.leftTextStyle}>
                {newStudyText.UPLOAD_SAMPLE_JSON_FILE}
              </div>
              <div className="marginLeft3">
                {props && props.details && props.details.updStudy ? (
                  <div>
                    <Dragger className="noBorder"
                      {...uploadUpdateProps}
                      showUploadList={false}
                      action={`${apiUrl}/${urls.GET_UPDATE_STUDY_URL}/${constantValues.SAMPLES}^${datasetId}^${selectedVersion}`}
                    >
                      {" "}
                      <Row>
                        <div style={NewStudyStyle.updateDivStyle}>
                          {sampleUpdated
                            ? fileSamp
                              ? fileSamp.name
                              : ""
                            : newDetails && newDetails.sample_file_name
                            ? newDetails.sample_file_name
                            : ""}{" "}
                        </div>{" "}
                      </Row>{" "}
                      <Row>
                        <div style={NewStudyStyle.updateBtnStyle}>
                          {" "}
                          <Button className="noBorder">
                            {" "}
                            <UploadOutlined className="pr-5"
                            />{" "}
                            {constantValues.UPDATE}{" "}
                          </Button>
                        </div>{" "}
                      </Row>
                    </Dragger>
                  </div>
                ) : (
                  <Upload
                    {...uploadProps}
                    action={`${apiUrl}/${urls.SAMPLE_UPLOAD_URL}`}
                    showUploadList={false}
                  >
                    {!fileSamp ? (
                      <Button
                        style={NewStudyStyle.uploadBtn}
                        icon={<UploadOutlined />}
                      >
                        {newStudyText.CLICK_TO_UPLOAD}
                      </Button>
                    ) : (
                      <Button style={NewStudyStyle.uploadBtn}>
                        {fileSamp.name}
                      </Button>
                    )}
                  </Upload>
                )}
              </div>
            </div>
          </Col>
          <Col xs={14} xl={14} style={NewStudyStyle.rightStyle}>
            <StudyDetailComp details={{ ...newDetails }}></StudyDetailComp>
          </Col>
        </Row>
      </Col>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading}
      >
        <Spin />
      </Modal>
      <Modal
        title="Confirmation"
        visible={isConfirmationVisible}
        onOk={onConfirmVisibility}
        onCancel={onCancelVisibility}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Please confirm changing the visibility of this dataset</p>
      </Modal>
    </div>
  );
}
