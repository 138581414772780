import React from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Divider,
  Card,
  Input,
  Modal,
  message,
  Spin
} from "antd";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import { FilterOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Bar, Pie } from "react-chartjs-2";
import {
  viewStudyText,
  newStudyText,
  statisticsText,
} from "../constants/displayText";
import { urls } from "../constants/urls";
import Chart from "chart.js/auto";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import "../styles/CommonStyles.css";
import { constantValues } from "../constants/constantValues";
import CustomFilter from "./CustomFilter";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { pageLinks } from "../constants/urls";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Statistics(props) {
  const navigate = useNavigate();
  const params = useParams();
  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  const [statisticsResp, setStatisticsResp] = React.useState({});
  const [filters, setFilters] = React.useState([]);
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  React.useEffect(() => {
    setDataModalityValues([]);
    setInstrValues([]);
    let cohList = JSON.parse(localStorage.getItem("cohList"));
    let reqBstTrue = cohList.filter((i) => i.in_request_basket === true);
    if (reqBstTrue.length > 0) {
      setReqBskt(true);
    }

    setSlctedCohort(params.cohortId);
    setEditTitle(false);
    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let query = {
            cohort_id: params.cohortId,
            logic_status: 1
          };
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: query,
          };
          setLoading(true)

          axios
            .get(`${apiUrl}/${urls.SUMMARY_STATISTICS}`, config)
            .then((response) => {
              setLoading(false)
              if (response.status === 200) {
                let respData = response.data;
                setStatisticsResp(respData);
                if (respData?.timelines?.length > 0) {
                  setSelectedModality(respData.timelines);
                  setModDropDownValue(statisticsText.TIMELINES_PARTICIPANTS)
                  setModValue(newStudyText.TIMELINES)
                }
                else {
                  setSelectedModality(respData.group);
                  setModDropDownValue(statisticsText.GROUP_PARTICIPANTS)
                  setModValue(statisticsText.GRP)
                }
                setNewCohortName(response.data.cohort_name);
                setCohName(response.data.cohort_name);
                let dataMod =
                  respData?.modalities_dropdown?.length> 0 ? respData?.modalities_dropdown[0]?.modalities?.map((i) => {
                    return {
                      value: i,
                      label: i,
                    };
                  }) : [];
                setDataModalityOptions(dataMod);
                let instrCond =
                  respData?.instrument_dropdown?.length> 0 ? respData?.instrument_dropdown[0]?.instrument_dropdown?.map((i) => {
                    return {
                      value: i,
                      label: i,
                    };
                  }) : [];
                setInstrOptions(instrCond);
                if (respData?.filter_instrument?.length > 0) {
                  setInstrSelectedValues(respData.filter_instrument);
                  setInstrAppliedValues(respData.filter_instrument);
                  setInstrValues(respData.filter_instrument);
                  setInstr(respData.filter_instrument)
                }
                else {
                  setInstrSelectedValues([]);
                  setInstrAppliedValues([])
                  setInstrValues([]);
                }

                if (respData?.filter_datamodalities?.length) {
                  setDataModalitySelectedValues(respData.filter_datamodalities);
                  setDataModalityAppliedValues(respData.filter_datamodalities);
                  setDataModalityValues(respData.filter_datamodalities);
                  setDataModality(respData.filter_datamodalities)
                }
                else {
                  setDataModalitySelectedValues([]);
                  setDataModalityAppliedValues([])
                  setDataModalityValues([]);
                }

                if (respData?.filter !== null && respData?.filter !== undefined) {
                  const updatedArray = respData?.filter?.map(group => ({
                    filters: group.filters.map(filter => ({
                      ...filter,
                      filterName: statisticsText.FILTER_NAMES[filter.filterName] || filter.filterName
                    }))
                  }));
                  setFilters(updatedArray);
                  setAppliedFilters(updatedArray)
                }
                else {
                  setDataModalitySelectedValues([]);
                  setInstrSelectedValues([]);
                  let filters = [];
                  setFilters([...filters]);
                }
              }
              else {
                message.error(`${constantValues.ERR_MSG}`);
              }
            })
          .catch(() =>{
            console.log("error")
          })
        })
        .catch((e) => {
          navigate('/')
        })
    }
    catch (error) {

    }
  }, [props]);

  const [loading, setLoading] = React.useState(false);
  const [selectedModality, setSelectedModality] = React.useState();
  const [modValue, setModValue] = React.useState();
  const [modDropDownValue, setModDropDownValue] = React.useState(
    statisticsText.TIMELINES_PARTICIPANTS
  );
  const [editTitle, setEditTitle] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [customFilterOpt, setCustomFilterOpt] = React.useState([]);
  const [dataModalityOptions, setDataModalityOptions] = React.useState();
  const [instrOptions, setInstrOptions] = React.useState();
  const [newCohortName, setNewCohortName] = React.useState("Cohort1");
  const [dataModalityValues, setDataModalityValues] = React.useState([]);
  const [dataModality, setDataModality] = React.useState("");
  const [instr, setInstr] = React.useState("");
  const [instrValues, setInstrValues] = React.useState([]);
  const [dataModalitySelectedValues, setDataModalitySelectedValues] = React.useState([]);
  const [instrSelectedValues, setInstrSelectedValues] = React.useState([]);
  const [dataModalityAppliedValues, setDataModalityAppliedValues] = React.useState([]);
  const [instrAppliedValues, setInstrAppliedValues] = React.useState([]);
  const [slctedCohort, setSlctedCohort] = React.useState("");
  const [cohName, setCohName] = React.useState("");
  const [reqBskt, setReqBskt] = React.useState(false);

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      }`;
  }

  const handleModalityChange = (value) => {
    setModDropDownValue(value);
    if (value === newStudyText.TIMELINES.toLowerCase()) {
      let selectedMod = statisticsResp.timelines;
      setSelectedModality(selectedMod);
      setModValue(newStudyText.TIMELINES);
    } else {
      let selectedMod = statisticsResp.group;
      setSelectedModality(selectedMod);
      setModValue(statisticsText.GRP);
    }
  };

  // function calcHeightOld(param, paramId) {
  //   switch (param) {
  //     case "primary_diagnosis":
  //       return statisticsResp &&
  //         statisticsResp.primary_diagnosis &&
  //         statisticsResp.primary_diagnosis.length >= 0 &&
  //         statisticsResp.primary_diagnosis.length <= 2 ?
  //         130 + statisticsResp.primary_diagnosis.length * 70 :
  //         statisticsResp.primary_diagnosis.length >= 3 && statisticsResp.primary_diagnosis.length <= 7
  //         ? 50 + statisticsResp.primary_diagnosis.length * 50
  //         : statisticsResp &&
  //           statisticsResp.primary_diagnosis &&
  //           statisticsResp.primary_diagnosis.length > 8
  //           ? 350 + (statisticsResp.primary_diagnosis.length - 8) * 40
  //           : 350;
  //     case "instrument_statistics":
  //       return statisticsResp &&
  //         statisticsResp.instrument_statistics &&
  //         statisticsResp.instrument_statistics.length >= 0 &&
  //         statisticsResp.instrument_statistics.length <= 2 ?
  //         130 + statisticsResp.instrument_statistics.length * 70 :
  //         statisticsResp && statisticsResp.instrument_statistics
  //         && statisticsResp.instrument_statistics.length >= 3 &&
  //          statisticsResp.instrument_statistics.length <= 7
  //         ? 50 + statisticsResp.instrument_statistics.length * 50
  //         : statisticsResp &&
  //           statisticsResp.instrument_statistics &&
  //           statisticsResp.instrument_statistics.length > 8
  //           ? 350 + (statisticsResp.instrument_statistics.length - 8) * 40
  //           : 350;
  //     case "exp_condn_statistics":
  //       return statisticsResp &&
  //         statisticsResp.exp_condn_statistics &&
  //         statisticsResp.exp_condn_statistics.length >= 0 &&
  //         statisticsResp.exp_condn_statistics.length <= 2 ?
  //         130 + statisticsResp.exp_condn_statistics.length * 70 :
  //         statisticsResp &&
  //         statisticsResp.exp_condn_statistics && 
  //         statisticsResp.exp_condn_statistics.length >= 3 && 
  //         statisticsResp.exp_condn_statistics.length <= 7
  //         ? 50 + statisticsResp.exp_condn_statistics.length * 50
  //         : statisticsResp &&
  //           statisticsResp.exp_condn_statistics &&
  //           statisticsResp.exp_condn_statistics.length > 8
  //           ? 350 + (statisticsResp.exp_condn_statistics.length - 8) * 40
  //           : 350;
  //     case "other_diagnoses":
  //       return statisticsResp &&
  //         statisticsResp.other_diagnoses &&
  //         statisticsResp.other_diagnoses.length >= 0 &&
  //         statisticsResp.other_diagnoses.length <= 2 ?
  //         130 + statisticsResp.other_diagnoses.length * 70 :
  //         statisticsResp &&
  //         statisticsResp.other_diagnoses && 
  //         statisticsResp.other_diagnoses.length >= 3 && statisticsResp.other_diagnoses.length <= 7
  //         ? 50 + statisticsResp.other_diagnoses.length * 50
  //         : statisticsResp &&
  //           statisticsResp.other_diagnoses &&
  //           statisticsResp.other_diagnoses.length > 8
  //           ? 350 + (statisticsResp.other_diagnoses.length - 8) * 40
  //           : 350;
  //     case "demographics":
  //       let label =
  //         statisticsResp &&
  //         statisticsResp.demographics.filter((i) => i._id === paramId);
  //       let length = label && label[0].data && label[0].data.length;
  //       return length >= 0 && length <= 3 ? 130 + length * 40 : length > 3 && length <= 7
  //         ? 50 + length * 40
  //         : length >= 8
  //           ? 350 + (length - 8) * 50
  //           : 350;
  //     case "Timelines":
  //       return statisticsResp &&
  //         statisticsResp.timelines &&
  //         statisticsResp.timelines.length >= 0 &&
  //         statisticsResp.timelines.length <= 1 ?
  //         130 + statisticsResp.timelines.length * 60 :
  //         statisticsResp && 
  //         statisticsResp.timelines && 
  //         statisticsResp.timelines.length >= 2 && statisticsResp.timelines.length <= 7
  //         ? 50 + statisticsResp.timelines.length * 50
  //         : statisticsResp &&
  //           statisticsResp.timelines &&
  //           statisticsResp.timelines.length > 8
  //           ? 350 + (statisticsResp.timelines.length - 8) * 40
  //           : 350;
  //     case "Group":
  //       return statisticsResp &&
  //         statisticsResp.group &&
  //         statisticsResp.group.length >= 0 &&
  //         statisticsResp.timelines.length <= 2 ?
  //         130 + statisticsResp.timelines.length * 70 :
  //         statisticsResp && 
  //         statisticsResp.timelines && 
  //         statisticsResp.timelines.length >= 3 && statisticsResp.timelines.length <= 7
  //         ? 50 + statisticsResp.group.length * 50
  //         : statisticsResp &&
  //           statisticsResp.group &&
  //           statisticsResp.group.length > 8
  //           ? 350 + (statisticsResp.group.length - 8) * 40
  //           : 350;
  //   }
  // }

  function calcHeight(param, paramId) {
    switch (param) {
      case "primary_diagnosis":
        return 130 + statisticsResp?.primary_diagnosis?.length * 50
      case "instrument_statistics":
        return 130 + statisticsResp?.instrument_statistics?.length * 50
      case "exp_condn_statistics":
        return 130 + statisticsResp?.exp_condn_statistics?.length * 50
      case "other_diagnoses":
        return 130 + statisticsResp?.other_diagnoses?.length * 50
      case "demographics":
        let label = statisticsResp?.demographics?.filter((i) => i._id === paramId);
        let length = label[0]?.data?.length;
        return 130 + length * 50
      case "Timelines":
        return 130 + statisticsResp?.timelines?.length * 30
      case "Group":
        return 130 + statisticsResp?.group?.length * 30
    }
  }

  const filterData = (param) => {
    if (param?.isFilterOpen !== null && param?.isFilterOpen !== undefined) {
      setIsFilterOpen(param.isFilterOpen);
    } else if (param?.filterData !== null && param?.filterData !== undefined) {
      let updChart = param.filterData;
      setStatisticsResp({ ...updChart });
      setModValue(newStudyText.TIMELINES);
      setSelectedModality(updChart.timelines);
      setModDropDownValue(statisticsText.TIMELINES_PARTICIPANTS);
      if (param?.filterData?.filter !== null && param?.filterData?.filter !== undefined) {
        const updatedArray = param.filterData.filter.map(group => ({
          filters: group.filters.map(filter => ({
            ...filter,
            filterName: statisticsText.FILTER_NAMES[filter.filterName] || filter.filterName
          }))
        }));
        setAppliedFilters(updatedArray)
        setFilters(updatedArray)
      }
      else {
        setAppliedFilters([])
        setFilters([])
      }
      //setStatisticsResp(response.data);
      setCohName(param.filterData.cohort_name);
      setNewCohortName(param.filterData.cohort_name);
    } else if (param.filters !== undefined) {
      if (param.filters.length === 0) {
        setFilters([]);
        setAppliedFilters([])
      }
      setFilters(param.filters);
    }
  };

  const cardClick = (study) => {
    onSave(true);
    navigate(`${pageLinks.VW_STUDY}/${Object.values(study._id)}`, {
      state: { details: { ...study } },
    });
  };

  const onEditTitle = () => {
    setEditTitle(true);
  };

  const onSaveTitle = () => {
    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let query = {
            cohort_id: slctedCohort,
            new_name: newCohortName
          };
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: query,
          };

          axios.put(`${apiUrl}/${urls.SAVE_COHORT_NAME}`, {}, config).then((response) => {
            if (response.status === 200) {
              setCohName(newCohortName)
              localStorage.setItem(
                "cohList",
                JSON.stringify(response.data.cohort_list)
              );
              props.getCohort([...response.data.cohort_list]);
            }
          })
          .catch(() =>{
            console.log("error")
          })
        })
        .catch((e) => {
          navigate('/')
        })
    }
    catch (error) {

    }
    setEditTitle(false);
  };

  const onFilterClick = () => {
    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let updatedParams = {
            logic_status: "1"
          };
          updatedParams.cohort_id = slctedCohort;
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: updatedParams,
          };
          axios
            .get(`${apiUrl}/${urls.GET_CUSTOM_FILTER_URL}`, config)
            .then((response) => {
              if (response.status === 200) {
                setCustomFilterOpt(response.data);
                setIsFilterOpen(true);
              }
              else {
                message.error(`${constantValues.ERR_MSG}`);
              }
            })
            .catch(() =>{
              console.log("error")
            })
        })
        .catch((e) => {
          navigate('/')
        })
    }
    catch (error) {

    }
  };

  const onSave = (autoSave) => {
    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let updatedParams = {};
          if (dataModalityValues !== "" && dataModalityValues?.length > 0) {
            updatedParams = {
              ...updatedParams,
              datamodalities: dataModalityValues,
            };
          }
          if (instrValues !== "" && instrValues.length > 0) {
            updatedParams = {
              ...updatedParams,
              instrument: instrValues,
            };
          }

          let query = {
            cohort_id: slctedCohort,
          };
          let config = {
            headers: { "Authorization": `Bearer ${response.accessToken}` },
            params: query,
          }

          let updatedFilters = filters?.map(subGroup => {
            return {
              filters: subGroup.filters
                .filter(item => item.filterName.trim() !== '') // Remove empty filter names
                .map(item => ({
                  ...item,
                  filterName: item.filterName.toLowerCase().replace(/ +/g, "")
                }))
            };
          });

          updatedParams.filters = updatedFilters
          updatedParams.cohort_name = newCohortName
          setLoading(true)
          axios
            .put(`${apiUrl}/${urls.SAVE_SUMMARY_STATISTICS}`, updatedParams, config)
            .then((response) => {
              setLoading(false)
              if (response.status === 200) {
                setEditTitle(false);
                setStatisticsResp(response.data);
                setCohName(response.data.cohort_name);
                setNewCohortName(response.data.cohort_name);
                setDataModalityAppliedValues([...dataModalitySelectedValues])
                setInstrAppliedValues([...instrSelectedValues])
                setDataModality(dataModalitySelectedValues)
                setInstr(instrSelectedValues)
                if (!autoSave) {
                  message.success(`${constantValues.SUCCESS_SAVE_MSG}`);
                }
              } else {
                message.error(`${constantValues.ERR_MSG}`);
              }
            })
            .catch(() =>{
              console.log("error")
            })
        })
        .catch((e) => {
          navigate('/')
        })
    }
    catch (error) {

    }
  };

  function cohortNameChange(e) {
    setNewCohortName(e.target.value);
  }

  const onCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const addToBasket = () => {
    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let query = {
            cohort_id: slctedCohort,
            user_id: localStorage.getItem("userObjId"),
          };
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: query,
          };
          axios.put(`${apiUrl}/${urls.ADD_BASKET}`, {}, config).then((response) => {
            if (response.status === 200) {
              localStorage.setItem(
                "cohList",
                JSON.stringify(response.data.cohort_list)
              );
              navigate(`${pageLinks.REQ_BASKET}`);
            }
            else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
        })
        .catch((e) => {
          navigate('/')
        })
    }
    catch (error) {

    }
  };

  function handleDataModalityChange(value) {
    setDataModalityValues(value);
    setDataModalitySelectedValues(value);
  }

  function handleInstrChange(value) {
    setInstrValues(value);
    setInstrSelectedValues(value);
  }

  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <Col xs={18} xl={18}>
            <div className="titleStyle">
              {!editTitle ? (
                <div>
                  {" "}
                  <span> Cohort - {cohName} </span>
                  <Button style={{ border: "none" }} onClick={onEditTitle}>
                    <EditOutlined className="pl-2" />
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <Input
                    placeholder=""
                    style={{ width: "50%" }}
                    value={newCohortName}
                    onChange={(e) => cohortNameChange(e)}
                  />{" "}
                  <Button style={{ border: "none" }} type="primary"
                    onClick={onSaveTitle}>
                    Save
                  </Button>{" "}
                  <Button style={{ border: "none" }} onClick={() => setEditTitle(false)}>
                    Cancel
                  </Button>{" "}
                </div>
              )}
            </div>
          </Col>

          <Col xs={6} xl={6}>
            {/* {dataModalitySelectedValues.length > 0 ? (
              <Select
                mode={constantValues.MULTIPLE}
                allowClear
                options={dataModalityOptions}
                className="expCondStyle"
                onChange={(e) => handleDataModalityChange(e)}
                value={dataModalitySelectedValues}
              />
            ) : (
              <Select
                mode={constantValues.MULTIPLE}
                allowClear
                placeholder={statisticsText.DATA_MOD}
                options={dataModalityOptions}
                className="expCondStyle"
                onChange={(e) => handleDataModalityChange(e)}
              />
            )}
            {instrSelectedValues.length > 0 ? (
              <Select
                mode={constantValues.MULTIPLE}
                allowClear
                options={instrOptions}
                className="instrStyle"
                onChange={(e) => handleInstrChange(e)}
                value={instrSelectedValues}
              />
            ) : (
              <Select
                mode={constantValues.MULTIPLE}
                allowClear
                placeholder={statisticsText.COHORT_INSTR}
                options={instrOptions}
                className="instrStyle"
                onChange={(e) => handleInstrChange(e)}
              />
            )} */}

            <Button
              className="statisticsfilterStyle"
              onClick={onFilterClick}
              disabled={
                statisticsResp &&
                Object.keys(statisticsResp).length === 0 &&
                Object.getPrototypeOf(statisticsResp) === Object.prototype
              }
            >
              <FilterOutlined className="pt-0"/> <span className="pt-0">Filters</span>
            </Button>
            {/* <Button
              className="ml-2 mt-2"
              type={constantValues.PRIMARY}
              onClick={() => onSave()}
              disabled={
                statisticsResp &&
                Object.keys(statisticsResp).length === 0 &&
                Object.getPrototypeOf(statisticsResp) === Object.prototype
              }
            >
              Apply Filters
            </Button> */}
            <Button
              className="ml-2 mt-2"
              type={constantValues.PRIMARY}
              onClick={addToBasket}
              disabled={
                (statisticsResp && statisticsResp.filtered_count === 0) ||
                (statisticsResp &&
                  Object.keys(statisticsResp).length === 0 &&
                  Object.getPrototypeOf(statisticsResp) === Object.prototype) ||
                statisticsResp?.in_request_basket
              }
            >
              {statisticsText.ADD_TO_BASKET}
            </Button>
          </Col>
        </Row>
        {statisticsResp &&
          Object.keys(statisticsResp).length === 0 &&
          Object.getPrototypeOf(statisticsResp) === Object.prototype ? (
          <div
            className="showingTextStyle ml-1"
          >
            {" "}
            <Divider className="dividerStyle"> </Divider> No studies
            added to Cohort
          </div>
        ) : (
          <div>
            <Row>
              <div className="showingStyle"
                style={{
                  whiteSpace: "wrap",
                  width: "100%",
                }}
              >
                {" "}
                <span className="showingTextStyle">
                  {viewStudyText.SHOWING}:
                </span>{" "}
                <span className="studyCntStyle">
                  {statisticsResp ? (
                    <span>
                      {" "}
                      {appliedFilters.length > 0 ||
                        dataModalityAppliedValues.length > 0 ||
                        instrAppliedValues.length > 0 ? (
                        <span>
                          {statisticsResp.filtered_count === "" ||
                            statisticsResp.filtered_count === null ||
                            statisticsResp.filtered_count === undefined ? (
                            <span>{statisticsResp.total_count}</span>
                          ) : (
                            <span>{statisticsResp.filtered_count}</span>
                          )}/{statisticsResp.total_count}{" "}
                          {viewStudyText.STUDIES_NOW}{" "}
                          {statisticsResp.filtered_count === "" ||
                            statisticsResp.filtered_count === null ||
                            statisticsResp.filtered_count === undefined ? null : (
                            <span>
                              {appliedFilters.map((filterGroup, index) => (
                                <span key={index}>
                                  {index !== 0 ? 'or ' : null}
                                  {filterGroup.filters.map((filter, innerIndex) => (
                                    <span key={innerIndex} className="contentHeadingTextStyle">
                                      {innerIndex !== 0 ? 'and ' : null}
                                      {filter.filterName && <span>{filter.filterName}: </span>}
                                      {filter.operator}{" "}
                                      {filter.value1 && filter.value1.toString().replaceAll("^", ",")}{" "}
                                      {filter.value2 && (
                                        <span>
                                          {constantValues.AND} {filter.value2}
                                        </span>
                                      )}
                                    </span>
                                  ))}
                                </span>
                              ))}
                              <span>
                                {dataModalityAppliedValues.length > 0 ? (
                                  <span>
                                    <span
                                      className="contentHeadingTextStyle"
                                    >
                                      Data Modalities:{" "}
                                    </span>
                                    <span>include </span>
                                    {dataModalityAppliedValues.map((i) => (
                                      <span>{i},</span>
                                    ))}{" "}
                                  </span>
                                ) : null}
                              </span>
                              <span>
                                {instrAppliedValues.length > 0 ? (
                                  <span>
                                    <span
                                      className="contentHeadingTextStyle"
                                    >
                                      Instrument:{" "}
                                    </span>
                                    <span>include </span>
                                    {instrAppliedValues.map((i) => (
                                      <span> {i},</span>
                                    ))}{" "}
                                  </span>
                                ) : null}
                              </span>
                            </span>
                          )}
                          <span>.</span>
                        </span>
                      ) : (
                        <span>
                          {statisticsResp.total_count}/
                          {statisticsResp.total_count}{" "}
                          {viewStudyText.STUDIES_NOW}.{" "}
                        </span>
                      )}
                    </span>
                  ) : null}
                </span>
              </div>
            </Row>
            <Divider className="dividerStyle"> </Divider>
            <Row>
              <Col xs={16} xl={16} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                <div className="fileSizeDiv" style={{ flex: "0 0 47%" }}>
                  {" "}
                  <div className="fileSizeTextDiv">
                    Total Sample Size
                  </div>
                  <div className="totalFileStyle">
                    {" "}
                    {statisticsResp?.totalsamplesize}
                  </div>
                </div>
                <div className="fileSizeDiv" style={{ flex: "0 0 47%" }}>
                  {" "}
                  <div className="fileSizeTextDiv">
                    {newStudyText.TOTAL_FILE_SIZE}
                  </div>
                  <div className="totalFileStyle">
                    {" "}
                    {formatBytes(statisticsResp?.totalfilesize)}
                  </div>
                </div>
                {statisticsResp.totalsamplesize > 0 &&
                  ((statisticsResp?.age !== null &&
                    statisticsResp?.age !== undefined && Object.keys(statisticsResp?.age).length > 0) ||
                    (statisticsResp?.weight !== null &&
                      statisticsResp?.weight !== undefined && Object.keys(statisticsResp?.weight).length > 0)) &&
                  <div className="numDivStyle" style={{ flex: "0 0 96%" }}>
                    <div className="numTextStyle">
                      {statisticsText.PARTICIPANTS_BY_NUMERICAL}
                    </div>
                    <Col xs={24} xl={24}>
                      {statisticsResp?.age !== null &&
                        statisticsResp?.age !== undefined && Object.keys(statisticsResp?.age).length > 0 &&
                        <Row className="ageStyle">
                          <Col xs={6} xl={6}>
                            <span className="ageTextStyle">
                              {statisticsText.AGE}{" "}
                            </span>{" "}
                          </Col>{" "}
                          <Col xs={18} xl={18}>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.MIN}:{" "}
                              {statisticsResp && statisticsResp.age
                                ? statisticsResp.age.min
                                : null}
                            </span>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.MAX}:{" "}
                              {statisticsResp && statisticsResp.age
                                ? statisticsResp.age.max
                                : null}{" "}
                            </span>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.AVG}:{" "}
                              {statisticsResp && statisticsResp.age
                                ? (
                                  Math.round(statisticsResp.age.avg * 100) / 100
                                ).toFixed(0)
                                : null}{" "}
                            </span>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.SD}:{" "}
                              {statisticsResp && statisticsResp.age
                                ? (
                                  Math.round(statisticsResp.age.sd * 100) / 100
                                ).toFixed(1)
                                : null}{" "}
                            </span>
                          </Col>
                        </Row>}
                      {statisticsResp?.weight !== null &&
                        statisticsResp?.weight !== undefined && Object.keys(statisticsResp?.weight).length > 0 &&
                        <Row className="ageStyle">
                          <Col xs={6} xl={6}>
                            <span className="ageTextStyle">
                              {statisticsText.WEIGHT}{" "}
                            </span>{" "}
                          </Col>{" "}
                          <Col xs={18} xl={18}>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.MIN}:{" "}
                              {statisticsResp && statisticsResp.weight
                                ? statisticsResp.weight.min
                                : null}{" "}
                            </span>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.MAX}:{" "}
                              {statisticsResp && statisticsResp.weight
                                ? statisticsResp.weight.max
                                : null}{" "}
                            </span>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.AVG}:{" "}
                              {statisticsResp && statisticsResp.weight
                                ? (
                                  Math.round(statisticsResp.weight.avg * 100) /
                                  100
                                ).toFixed(0)
                                : null}{" "}
                            </span>
                            <span className="minStyle">
                              {" "}
                              {statisticsText.SD}:{" "}
                              {statisticsResp && statisticsResp.weight
                                ? (
                                  Math.round(statisticsResp.weight.sd * 100) /
                                  100
                                ).toFixed(1)
                                : null}{" "}
                            </span>
                          </Col>
                        </Row>}
                    </Col>
                  </div>}
                {statisticsResp?.totalsamplesize > 0 &&
                  statisticsResp?.demographics?.filter(f => f.data.length > 0)?.map((i) => (
                    <div className="chartOutDiv"
                      style={{
                        flex: "0 0 47%", marginTop: "2%",
                        height: calcHeight("demographics", i._id),
                      }}
                    >
                      <div className="chartInnerDiv"> {i._id} </div>
                      <Bar
                        type={statisticsText.BAR}
                        options={statisticsText.CHART_OPTIONS}
                        className="barStyle"
                        style={{ width: "30%" }}
                        data={{
                          labels: i.data.map((j) => j.value),
                          datasets: [
                            {
                              axis: constantValues.Y,
                              data: i.data.map((j) => j.count),
                              fill: false,
                              backgroundColor: i.data.map(
                                (j) => statisticsText.chartBgColour
                              ),
                              borderColor: i.data.map(
                                (j) => statisticsText.barColor
                              ),
                              borderWidth: 1,
                            },
                          ],
                        }}
                      />
                    </div>
                  ))}
                {statisticsResp?.totalsamplesize > 0 && statisticsResp?.modalities?.length > 0 &&
                  <div className="chartOutDiv" style={{ flex: "0 0 47%" }}>
                    <div className="chartInnerDiv">
                      {" "}
                      {statisticsText.MODALITY}{" "}
                    </div>
                    <Pie
                      data={{
                        labels:
                          statisticsResp &&
                            statisticsResp.modalities &&
                            statisticsResp.modalities.length > 0
                            ? statisticsResp.modalities.map((i) => i._id)
                            : [],
                        datasets: [
                          {
                            backgroundColor:
                              statisticsResp &&
                                statisticsResp.modalities &&
                                statisticsResp.modalities.length > 0
                                ? statisticsResp.modalities.map((i, index) => {
                                  if (index % 3 === 1) {
                                    let hue = 50 + (index + 1) * 40;
                                    let str = "rgba(255,0," + hue + ",0.5)";
                                    return str;
                                  } else if (index % 3 === 2) {
                                    let hue = 50 + (index + 1) * 40;
                                    let str = "rgba(" + hue + ",255,0,0.5)";
                                    return str;
                                  } else {
                                    let hue = 50 + (index + 1) * 40;
                                    let str = "rgba(0," + hue + ",255,0.5)";
                                    return str;
                                  }
                                })
                                : [],
                            data:
                              statisticsResp &&
                                statisticsResp.modalities &&
                                statisticsResp.modalities.length > 0
                                ? statisticsResp.modalities.map((i) => i.count)
                                : [],
                          },
                        ],
                      }}
                      options={{}}
                    />
                  </div>}

                {statisticsResp?.totalsamplesize > 0 &&
                  (statisticsResp?.timelines?.length > 0 || statisticsResp?.group?.length > 0) &&
                  <div className="clinicalOutStyle" style={{ flex: "0 0 47%" }}>
                    <div className="clinicalInnerStyle">
                      {statisticsText.GRPS_TIMELINES}
                    </div>
                    <Select
                      onChange={handleModalityChange}
                      className="clinicalSelectStyle"
                      options={statisticsText.CLINICAL_MODALITY_OPTIONS}
                      value={modDropDownValue}
                      disabled={statisticsResp?.timelines?.length <= 0 || statisticsResp?.group?.length <= 0}
                    />
                    <div style={{ height: calcHeight(modValue) }}>
                      <div className="modStyle"> {modValue} </div>
                      <Bar
                        type={statisticsText.BAR}
                        options={statisticsText.CHART_OPTIONS}
                        style={{ marginTop: "1%" }}
                        data={{
                          labels:
                            selectedModality !== null &&
                              selectedModality !== undefined
                              ? selectedModality.map((i) => i._id)
                              : [],
                          datasets: [
                            {
                              axis: constantValues.Y,
                              data:
                                selectedModality !== null &&
                                  selectedModality !== undefined
                                  ? selectedModality.map((i) => i.count)
                                  : [],
                              fill: false,
                              backgroundColor:
                                selectedModality !== null &&
                                  selectedModality !== undefined
                                  ? selectedModality.map(
                                    (i) => statisticsText.chartBgColour
                                  )
                                  : [],
                              borderColor:
                                selectedModality !== null &&
                                  selectedModality !== undefined
                                  ? selectedModality.map(
                                    (i) => statisticsText.barColor
                                  )
                                  : [],
                              borderWidth: 1,
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>}


                {statisticsResp?.totalsamplesize > 0 && statisticsResp?.instrument_statistics?.length > 0 &&
                  <div className="chartOutDiv"
                    style={{
                      flex: "0 0 47%",
                      height: calcHeight("instrument_statistics"),
                    }}
                  >
                    <div className="chartInnerDiv">
                      {" "}
                      Instruments
                    </div>
                    <Bar
                      type={statisticsText.BAR}
                      options={statisticsText.CHART_OPTIONS}
                      className="barStyle"
                      data={{
                        labels:
                          statisticsResp?.instrument_statistics
                            ? statisticsResp.instrument_statistics.map((i) => i._id)
                            : [],
                        datasets: [
                          {
                            axis: constantValues.Y,
                            data:
                              statisticsResp && statisticsResp.instrument_statistics
                                ? statisticsResp.instrument_statistics.map(
                                  (i) => i.count
                                )
                                : [],
                            fill: false,
                            backgroundColor:
                              statisticsResp && statisticsResp.instrument_statistics
                                ? statisticsResp.instrument_statistics.map(
                                  (j) => statisticsText.chartBgColour
                                )
                                : [],
                            borderColor:
                              statisticsResp && statisticsResp.instrument_statistics
                                ? statisticsResp.instrument_statistics.map(
                                  (j) => statisticsText.barColor
                                )
                                : [],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>}
                {statisticsResp?.totalsamplesize > 0 && statisticsResp?.exp_condn_statistics?.length > 0 &&
                  <div className="chartOutDiv"
                    style={{
                      flex: "0 0 47%",
                      height: calcHeight("exp_condn_statistics"),
                    }}
                  >
                    <div className="chartInnerDiv">
                      {" "}
                      Experimental Condition
                    </div>
                    <Bar
                      type={statisticsText.BAR}
                      options={statisticsText.CHART_OPTIONS}
                      className="barStyle"
                      data={{
                        labels:
                          statisticsResp && statisticsResp.exp_condn_statistics
                            ? statisticsResp.exp_condn_statistics.map((i) => i._id)
                            : [],
                        datasets: [
                          {
                            axis: constantValues.Y,
                            data:
                              statisticsResp && statisticsResp.exp_condn_statistics
                                ? statisticsResp.exp_condn_statistics.map(
                                  (i) => i.count
                                )
                                : [],
                            fill: false,
                            backgroundColor:
                              statisticsResp && statisticsResp.exp_condn_statistics
                                ? statisticsResp.exp_condn_statistics.map(
                                  (j) => statisticsText.chartBgColour
                                )
                                : [],
                            borderColor:
                              statisticsResp && statisticsResp.exp_condn_statistics
                                ? statisticsResp.exp_condn_statistics.map(
                                  (j) => statisticsText.barColor
                                )
                                : [],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>}
                {statisticsResp?.totalsamplesize > 0 && statisticsResp?.primary_diagnosis?.length > 0 &&
                  <div className="chartOutDiv"
                    style={{
                      flex: "0 0 47%",
                      height: calcHeight("primary_diagnosis"),
                    }}
                  >

                    <div className="chartInnerDiv">
                      {" "}
                      {viewStudyText.PRIMARY_DIAGNOSIS}{" "}
                    </div>
                    <Bar
                      type={statisticsText.BAR}
                      options={statisticsText.CHART_OPTIONS}
                      className="barStyle"
                      data={{
                        labels:
                          statisticsResp && statisticsResp.primary_diagnosis
                            ? statisticsResp.primary_diagnosis.map((i) => i._id)
                            : [],
                        datasets: [
                          {
                            axis: constantValues.Y,
                            data:
                              statisticsResp && statisticsResp.primary_diagnosis
                                ? statisticsResp.primary_diagnosis.map(
                                  (i) => i.count
                                )
                                : [],
                            fill: false,
                            backgroundColor:
                              statisticsResp && statisticsResp.primary_diagnosis
                                ? statisticsResp.primary_diagnosis.map(
                                  (j) => statisticsText.chartBgColour
                                )
                                : [],
                            borderColor:
                              statisticsResp && statisticsResp.primary_diagnosis
                                ? statisticsResp.primary_diagnosis.map(
                                  (j) => statisticsText.barColor
                                )
                                : [],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>}
                {statisticsResp?.totalsamplesize > 0 && statisticsResp?.other_diagnoses?.length > 0 &&
                  <div className="chartOutDiv"
                    style={{
                      flex: "0 0 47%",
                      height: calcHeight("other_diagnoses"),
                    }}
                  >
                    <div className="chartInnerDiv">
                      {" "}
                      {viewStudyText.OTHER_DIAGNOSES}{" "}
                    </div>
                    <Bar
                      type={statisticsText.BAR}
                      options={statisticsText.CHART_OPTIONS}
                      className="barStyle"
                      data={{
                        labels:
                          statisticsResp && statisticsResp.other_diagnoses
                            ? statisticsResp.other_diagnoses.map((i) => i.diagnosis)
                            : [],
                        datasets: [
                          {
                            axis: constantValues.Y,
                            data:
                              statisticsResp && statisticsResp.other_diagnoses
                                ? statisticsResp.other_diagnoses.map(
                                  (i) => i.count
                                )
                                : [],
                            fill: false,
                            backgroundColor:
                              statisticsResp && statisticsResp.other_diagnoses
                                ? statisticsResp.other_diagnoses.map(
                                  (j) => statisticsText.chartBgColour
                                )
                                : [],
                            borderColor:
                              statisticsResp && statisticsResp.other_diagnoses
                                ? statisticsResp.other_diagnoses.map(
                                  (j) => statisticsText.barColor
                                )
                                : [],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>}
              </Col>
              <Col xs={8} xl={8}>
                <div className="cardOuterStyle pt-2">
                  {" "}
                  {statisticsResp?.data?.map((i) => (
                    <Card
                      title={i.title}
                      className="cardStyleStatistics"
                      style={{
                        cursor: "pointer",
                        opacity: i.disabled ? 0.3 : 1,
                        border: i.disabled ? "none" : null,
                      }}
                      headStyle={{
                        font: "normal normal 600 14px/22px Arial",
                        paddingLeft: "5%",
                        color: "#262626"
                      }}
                      onClick={() => cardClick(i)}
                    >
                      <div className="height100">
                        <div>
                          {" "}
                          <span className="cardSampleStyle">
                            {" "}
                            {appliedFilters.length > 0 ||
                              dataModalityAppliedValues.length > 0 ||
                              instrAppliedValues.length > 0 ? <span>Filtered Sample Size</span> : <span>{newStudyText.SAMPLE_SIZE}</span>}
                          </span>{" "}
                          <span>{appliedFilters.length > 0 ||
                            dataModalityAppliedValues.length > 0 ||
                            instrAppliedValues.length > 0 ?
                            statisticsResp?.sample_ids_count
                              ?.filter(p => p.dataset_id === i.dataset_id && p.release_version === i.release_version)
                              ?.length > 0 ?
                              statisticsResp?.sample_ids_count
                                ?.filter(p => p.dataset_id === i.dataset_id && p.release_version === i.release_version)[0]
                                ?.participant_count :
                              i.sample_size
                            : i.sample_size}</span>{" "}
                        </div>
                        {i.description?.length > 150 ? (
                          <span>{i.description.substring(0, 150)}...</span>
                        ) : (
                          <span>{i.description}</span>
                        )}
                      </div>
                      <div className="mt-5">
                        {" "}
                        <span>v{i.release_version}</span>
                        <span className="clinicalInnerStyle">
                          {i.visibility?.charAt(0)?.toUpperCase() +
                            i.visibility?.slice(1)}
                        </span>
                      </div>
                    </Card>
                  ))}
                </div>
              </Col>
            </Row>{" "}
          </div>
        )}
      </Col>
      <Modal
        closable={false}
        width={800}
        centered={true}
        footer={null}
        title={
          <div>
            <Col xs={24} xl={24}>
              <Row>
                <Col xs={20} xl={20}>
                  <div>
                    <span className="filterSampleHdStyle">
                      {viewStudyText.FILTERED_SAMPLES}:
                    </span>
                    {statisticsResp.filtered_count === "" ||
                      statisticsResp.filtered_count === null ||
                      statisticsResp.filtered_count === undefined ? (
                      <span>{statisticsResp.total_count}</span>
                    ) : (
                      <span> {statisticsResp.filtered_count}</span>
                    )}{" "}
                    /{statisticsResp.total_count}
                  </div>
                </Col>
                <Col xs={4} xl={4}>
                  <Button type="primary"
                    onClick={onCloseFilter}
                  >
                    {" "}
                    Close {" "}
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        }
        open={isFilterOpen}
      >
        {" "}
        <div>
          <CustomFilter
            custFilter={{ ...customFilterOpt }}
            cohId={slctedCohort}
            details={filters}
            sendDt={filterData}
            dataModality={dataModality}
            instr={instr}
            cohName={newCohortName}
          ></CustomFilter>
        </div>
      </Modal>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading}
      >
        <Spin />
      </Modal>
    </div>
  );
}
