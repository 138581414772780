import React from "react";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Card,
  Divider,
  Input,
  Modal,
  Checkbox,
  message,
  Select
} from "antd";
import axios from "axios";
import dayjs from 'dayjs'
import "../styles/ViewStudyStyle.css";
import NewStudyStyle from "../styles/NewStudy";
import {
  FilterOutlined,
  PlusSquareOutlined,
  SelectOutlined,
  CloseOutlined,
  PlusOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  newStudyText,
  viewStudyText,
  detailedStudyText,
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import { useNavigate } from "react-router-dom";
import "../styles/CommonStyles.css";
import { constantValues } from "../constants/constantValues";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";

const { RangePicker } = DatePicker;

const { Search } = Input;

const apiUrl = process.env.REACT_APP_API_URL;

export default function ViewStudies(props) {
    const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  React.useEffect(() => {
    if (
      (localStorage.getItem("savedFilters") !== null &&
        localStorage.getItem("savedFilters") !== undefined) ||
      (localStorage.getItem("searchTxt") !== null &&
        localStorage.getItem("searchTxt") !== undefined)
    ) {
      instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
      let updatedParams = {};
      let config = {
        headers: { Authorization: `Bearer ${response.accessToken}` },
        params: updatedParams,
      };
      axios
        .get(`${apiUrl}/${urls.GET_CUSTOM_FILTER_URL}`, config)
        .then((response) => {
          if (response.status === 200) {
            let options = response.data
            let modalities = response.data.data_modalities
            modalities = modalities.flatMap(i => Object.values(i));
            let participants = response.data.participant_groups.flatMap(i => Object.values(i));
            options = {...options,data_modalities: modalities, participant_groups: participants}
            setCustomFilterOpt(options);
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
        })
        .catch((e) => {
          navigate('/')
         })
      let updatedPrms = {};
      if (
        localStorage.getItem("savedFilters") !== null &&
        localStorage.getItem("savedFilters") !== undefined
      ) {
        let savedFltr = JSON.parse(localStorage.getItem("savedFilters"));
        setFilters(savedFltr);
        setAppliedFilters(savedFltr)
        let dateFilter = savedFltr.filter(i => i.filterName === "Daterange")
        if(dateFilter.length > 0){
          let stDate = dateFilter[0].value1.replaceAll('-','/')
          let endDate = dateFilter[0].value2.replaceAll('-','/')
          setDateRange([dayjs(stDate, 'YYYY/MM/DD'), dayjs(endDate, 'YYYY/MM/DD')])
        } 
        updatedPrms = paramsFunc(savedFltr);
        localStorage.removeItem("savedFilters");
      }
      if(localStorage.getItem("searchTxt") !== null &&
        localStorage.getItem("searchTxt") !== undefined){
        updatedPrms.search = localStorage.getItem("searchTxt")
        setSearchText(localStorage.getItem("searchTxt"))
        localStorage.removeItem("searchTxt");
      }
      updatedPrms.page_num = 0;
      apiCall(updatedPrms, false);
      
    } else {
      let updatedParams = {
        page_num: pageNum,
      };
      apiCall(updatedParams, true);
    }
  }, []);

  const [pageNum, setPageNum] = React.useState(0);
  const [filteredCnt, setFilteredCnt] = React.useState();
  const [items, setItems] = React.useState([]);
  const [moreData, setMoreData] = React.useState(true);
  const [filterPage, setFilterPage] = React.useState(false);
  const [totalStudies, setTotalStudies] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [customFilterOpt, setCustomFilterOpt] = React.useState({});
  const [studyDetail, setStudyDetail] = React.useState();
  const [allDetails, setAllDetails] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [filterVal, setFilterVal] = React.useState(
    viewStudyText.SELECT_FILTER_TOPIC
  );
  const [versionOptions, setVersionOptions] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([]);
  const [isCohortModalOpen, setIsCohortModalOpen] = React.useState(false);
  const [newCohort, setNewCohort] = React.useState(false);
  const [disableCreateCohort, setDisableCreateCohort] = React.useState(true);
  const [selectAllCohort, setSelectAllCohort] = React.useState(false);
  const [studyAddCohort, setStudyAddCohort] = React.useState(false);
  const [newCohortName, setNewCohortName] = React.useState("");
  const [cohortStudyId, setCohortStudyId] = React.useState("");
  const [cohortList, setCohortList] = React.useState([]);
  const [space, setSpace] = React.useState(" i");
  
  const [studyAddMsg, setStudyAddMsg] = React.useState("");

  const navigate = useNavigate();

  function onOpenStudyDetails(i) {
    navigate(`${pageLinks.VW_STUDY}/${Object.values(i._id)}`, {
      state: { details: { ...i } },
    });
  }

  const handleChange = (value) => {
    let existingFilter = filters.filter((i) => i.filterName === value);
    if (existingFilter.length === 0) {
      setFilters([...filters, { filterName: value }]);
    }
    setFilterVal(viewStudyText.SELECT_FILTER_TOPIC);
  };

  const onSearch = (value) => {
    setSearchText(value);
    let updatedParams = {};
    updatedParams = paramsFunc(null);
    updatedParams.page_num = 0;
    apiCall(updatedParams, false, value);
  };

  function handleOperatorFilter(filterName, event) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        i.operator = event;
      }
      return i;
    });
    setFilters(newFilters);
  }

  function handleValue(filterName, value, event) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        if (value) {
          i.value1 = event.target.value;
        } else {
          i.value2 = event.target.value;
        }
      }
      return i;
    });
    setFilters(newFilters);
  }

  function handleValueDate(filterName, value, date, dateStr) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        if (value) {
          i.value1 = dateStr;
        } else {
          i.value2 = dateStr;
        }
      }
      return i;
    });
    setFilters(newFilters);
  }

  function handleDateRange(date, dateStr) {
    let removeExistingDate = filters.filter(
      (i) => i.filterName !== viewStudyText.DATE_RANGE
    );

    if (dateStr[0] !== "" && dateStr[1] !== "") {
      let stDate = dateStr[0]+'/01/01'
      let endDate = dateStr[1]+'/12/31'
      setDateRange([dayjs(stDate, 'YYYY/MM/DD'), dayjs(endDate, 'YYYY/MM/DD')])
      let newFilter = {};
      newFilter = {
        filterName: viewStudyText.DATE_RANGE,
        operator: constantValues.BETWEEN,
        value1: `${dateStr[0]}-01-01`,
        value2: `${dateStr[1]}-12-31`,
      };
      setFilters([...removeExistingDate, newFilter]);
      onClickApply(newFilter, true);
    } else {
      if (removeExistingDate.length === 0) {
        setDateRange([])
        setFilters([]);
        setFilteredCnt();
      } else {
        setDateRange([])
        setFilters(removeExistingDate);
      }
      onClickApply(null, false, true, removeExistingDate);
    }
  }

  function filterClick() {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    setFilterPage(true);
    let updatedParams = {};
    let config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
      params: updatedParams,
    };
    axios
      .get(`${apiUrl}/${urls.GET_CUSTOM_FILTER_URL}`, config)
      .then((response) => {
        if (response.status === 200) {
          let options = response.data
          let modalities = response.data.data_modalities
          modalities = modalities.flatMap(i => Object.values(i));
          let participants = response.data.participant_groups.flatMap(i => Object.values(i));
          options = {...options,data_modalities: modalities, participant_groups: participants}
          setCustomFilterOpt(options);
        }
        else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
  }

  function addNewClick() {
    navigate(`${pageLinks.VW_STUDY}${pageLinks.NEW_STUDY}`);
  }

  function onCloseModal() {
    setFilterPage(false);
  }

  function onCancel() {
    let cancelledCustomFilter = [];
    cancelledCustomFilter = filters.filter(
      (i) => i.filterName === viewStudyText.DATE_RANGE
    );
    setFilters(cancelledCustomFilter);
    setFilteredCnt();
    let updatedParams = {};
    if (cancelledCustomFilter.length > 0) {
      updatedParams = paramsFunc(cancelledCustomFilter, true);
    }
    updatedParams.page_num = 0;
    apiCall(updatedParams, false);
    setAppliedFilters(cancelledCustomFilter)
  }
  const sex = viewStudyText.SEX_OPTIONS;

  const paramsFunc = (newFilter, onCancel, removeDt, removedFilter) => {
    let consFilters = [];
    if (onCancel) {
      consFilters = [...newFilter];
    } else if (newFilter !== null && newFilter !== undefined) {
      consFilters = [newFilter];
    } else if (removeDt) {
      consFilters = removedFilter;
    } else {
      consFilters = [...filters];
    }
    setAppliedFilters(consFilters)
    let updatedParams = {};
    updatedParams =
      consFilters.length === 1 && consFilters[0] === undefined
        ? {}
        : consFilters
            .filter((value) => Object.keys(value).length !== 0)
            .reduce((obj, item) => {
              return item.operator === constantValues.BETWEEN
                ? Object.assign(obj, {
                    [item.filterName
                      ?.toLowerCase()
                      ?.replace(
                        / +/g,
                        ""
                      )]: `${item.operator}^${item.value1}^${item.value2}^${item.lastOperator}`,
                  })
                : Object.assign(obj, {
                    [item.filterName
                      ?.toLowerCase()
                      ?.replace(/ +/g, "")]: `${item.operator}^${item.value1}^${item.lastOperator}`,
                  });
            }, {});
    return updatedParams;
  };

  const apiCall = (updatedParams, fetchDt, searchTxt) => {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    if (searchTxt !== null && searchTxt !== undefined) {
      updatedParams.search = searchTxt;
    } else {
      updatedParams.search = searchText;
    }

    let config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
      params: updatedParams,
    };
    axios
      .get(`${apiUrl}/${urls.GET_ALL_DATASET_URL}`, config)
      .then((response) => {
        if (response.status === 200) {
          if (!fetchDt) {
            setPageNum(1);
          } else {
            setPageNum(pageNum + 1);
          }
          if (response.data.data.length > 0) {
            setMoreData(true);
            setTotalStudies(response.data.total);
            setFilteredCnt(response.data.filtered_count);
            let formattedData = response.data.data;
            if (fetchDt) {
              let allItems = items.concat(formattedData);
              setItems(allItems);
            } else {
              setItems(formattedData);
              setFilteredCnt(response.data.filtered_count);
            }
            if (response.data.data.length < 20) {
              setMoreData(false);
            }
          } else {
            setMoreData(false);
            if (!fetchDt) {
              setItems([]);
              setTotalStudies(response.data.total);
              setFilteredCnt(response.data.filtered_count);
            }
          }
        } else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
  };

  const onClickApply = (newFilter, dateRange, removeDt, removedFilter) => {
    let updatedParams = {};
    if (dateRange) {
      updatedParams = paramsFunc(newFilter);
    } else if (removeDt) {
      updatedParams = paramsFunc(null, false, true, removedFilter);
    } else {
      updatedParams = paramsFunc(null);
    }
    updatedParams.page_num = 0;
    apiCall(updatedParams, false);
    setFilterPage(false);
  };

  const fetchData = () => {
    let updatedParams = {};
    updatedParams = paramsFunc();
    updatedParams.page_num = pageNum;
    apiCall(updatedParams, true);
  };

  function handleGenderSelect(filterName, value) {
    let currentFilters = [...filters];
    let newFilters = currentFilters.map((i) => {
      if (i.filterName === filterName) {
        i.value1 = value.join("^");
      }
      return i;
    });
    setFilters(newFilters);
  }

  function updateStudy(study) {
    navigate(
      `${pageLinks.VW_STUDY}${pageLinks.UPDATE_STUDY}/${Object.values(
        study._id
      )}`
    );
  }

  const onChangeSearch = (event) => {
    setSearchText(event.target.value);
  };

  function onAddCohort(study) {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    let query = {
      user_id: localStorage.getItem("userObjId"),
      study_id: Object.values(study)[0],
    };
    let config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
      params: query,
    };
    axios.get(`${apiUrl}/${urls.GET_COHORT_LIST}`, config).then((response) => {
      if (response.status === 200) {
        setCohortList(response.data.cohort_list);
        if(response.data.cohort_list.filter((i) => i.selected === false).length === 0){
          setSelectAllCohort(true)
        }
        setIsCohortModalOpen(true);
        setCohortStudyId(study);
      } else {
        message.error(`${constantValues.ERR_MSG}`);
        setIsCohortModalOpen(false);
      }
    })
    .catch(() =>{
      console.log("error")
    })
    })
    .catch((e) => {
      navigate('/')
     })
  }

  const createCohort = () => {
    setNewCohort(true);
  };

  const createNewCohort = () => {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    let body = {
      user_id: localStorage.getItem("userObjId"),
      cohort_name: newCohortName,
    };
    let config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
    };
    axios
      .post(`${apiUrl}/${urls.ADD_NEW_COHORT}`, body, config)
      .then((response) => {
        setNewCohort(false);
        if (response.status === 200) {
          setCohortList([
            ...cohortList,
            { name: newCohortName, selected: false },
          ]);
          localStorage.setItem(
            "cohList",
            JSON.stringify(response.data.cohort_list)
          );
          props.getCohort([...response.data.cohort_list]);
        } else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
  };

  const onSelectCohort = (cohortName, e) => {
    let updateCohort = cohortList;
    updateCohort = updateCohort.map((i) => {
      if (i.name === cohortName) {
        i.selected = e.target.checked;
      }
      return i;
    });
    setCohortList(updateCohort);
    if (updateCohort.filter((i) => i.selected === false).length === 0) {
      setSelectAllCohort(true);
    } else {
      setSelectAllCohort(false);
    }
  };

  const onSelectAllCohort = (e) => {
    setSelectAllCohort(e.target.checked);
    let updateCohort = cohortList;
    updateCohort = updateCohort.map((i) => {
      i.selected = e.target.checked;
      return i;
    });
    setCohortList(updateCohort);
  };

  const onCloseCohortModal = () => {
    instance
    .acquireTokenSilent(tokRequest)
    .then((response) => {
    setIsCohortModalOpen(false);
    setNewCohort(false);
    let query = {
      user_id: localStorage.getItem("userObjId"),
    };
    let data = {
      study_id: Object.values(cohortStudyId)[0],
      cohort_ids: cohortList,
    };
    let config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
      params: query,
    };
    axios
      .put(`${apiUrl}/${urls.ADD_STUDY_COHORT}`, data, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message !== "") {
            setStudyAddCohort(true);
            setStudyAddMsg(response.data.message);
          }
        } else {
          message.error(`${constantValues.ERR_MSG}`);
        }
      })
      .catch(() =>{
        console.log("error")
      })
      })
      .catch((e) => {
        navigate('/')
       })
  };

  function cohortInput(e) {
    if (cohortList.filter((i) => i.name === e.target.value).length > 0)
      setDisableCreateCohort(true);
    else {
      setDisableCreateCohort(false);
    }
    setNewCohortName(e.target.value);
  }

  const onCloseFilter = (filter) => {
    let activeFilter = filters.filter(
      (i) => i.filterName !== filter.filterName
    );
    setFilters([...activeFilter]);
  };

  const onCloseCohort = () => {
    setIsCohortModalOpen(false);
    setNewCohort(false);
  }

  const hasNullOrUndefined = /* subGrp.some(subGroup => {
    return subGroup. */filters.some(filter => {
      const { operator, value1, value2, filterName } = filter;
      if (filterName!== "" && (!operator || operator && operator.trim() === "")) {
        return true; // Operator is null, undefined, or ""
      }
  
      if (filterName!== "" && (!value1 || value1 && value1.trim() === "")) {
        return true; // Value1 is null, undefined, or ""
      }
  
      if (filterName!== "" && operator === "BETWEEN") {
        if (!value2 || value2.trim() === "") {
          return true; // Value2 is null, undefined, or ""
        }
      }
  
      return false;
    });
  // });

  const itemDiv = (
    <Col xs={24} xl={24}>
      <div className="p-2">
        <Row>
          {items &&
            items.map((i) => (
              <Col span={12}>
                <div className="pt-2">
                  <Card
                    title={i.title}
                    extra={
                      <div className="d-flex flex-row">
                        <div className="mr-0">
                          v{i.release_version}&nbsp;
                        </div>
                        <div className="visibilityTextStyle">
                          {i.visibility &&
                            i.visibility.charAt(0).toUpperCase() +
                              i.visibility.slice(1)}
                        </div>
                      </div>
                    }
                    className="cardStyle"
                      style={{ width:"98%"
                    }}
                    headStyle={{  background: "#FAFAFA 0% 0% no-repeat padding-box",
                    border: "1px solid #D9D9D9",
                    font: "normal normal 600 14px/22px Arial",}}
                    bodyStyle={{  background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #D9D9D9",
                    paddingLeft: "0%",
                    paddingRight: "0%",
                    height: "10%"}}
                  >
                    <Row className="pl-3">
                      <Col xs={10} xl={10}>
                        <span className="marginRight contentHeadingTextStyle"
                        >
                          {newStudyText.SAMPLE_SIZE}:
                        </span>
                        <span className="marginRight">
                          {i.sample_size}
                        </span>
                      </Col>
                      <Col xs={7} xl={7}>
                        <span
                          className="marginRight contentHeadingTextStyle"
                        >
                          {newStudyText.START_DATE}
                          :
                        </span>
                          <span>{i.start_date}</span>
                      </Col>
                      <Col xs={7} xl={7}>
                        <span
                          className="marginRight contentHeadingTextStyle"
                        >
                          {newStudyText.END_DATE}
                          :
                        </span>
                          {i.end_date === null ? <span>Ongoing</span> :<span>{i.end_date}</span> }
                      </Col>
                    </Row>
                    <p
                      className="pl-3"
                    >
                      {i.description || ""}
                    </p>
                    <Row className="pl-3">
                      <Col xs={12} xl={12}>
                        <span className="contentHeadingTextStyle">
                          {newStudyText.DOMAINS}
                        </span>
                      </Col>
                      <Col xs={12} xl={12}>
                        <span className="contentHeadingTextStyle">
                          {newStudyText.MODALITIES}
                        </span>
                      </Col>
                    </Row>
                    <Row className="pl-3 mb-5"
                    >
                      <Col xs={12} xl={12}>
                        <div className="flexWrapStyle">
                          {i.domains.map((j) => (
                            <span className="marginRight5" style={{...NewStudyStyle.domainStyle,}}
                            >
                              {j}
                            </span>
                          ))}
                        </div>
                      </Col>
                      <Col xs={12} xl={12}>
                        <div className="flexWrapStyle">
                          {i.data_modalities.map((j) => (
                            <span className="marginRight5" style={{...NewStudyStyle.modalityStyle,}}
                            >
                              {j.modality}
                            </span>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    <Divider />
                    <Row xs={24} xl={24} className="pl-3">
                      <Col xs={12} xl={12}>
                        <Button>
                          <a href={i.release_doi} target="_blank">
                            {" "}
                            {i.release_doi} <SelectOutlined rotate={90} />{" "}
                          </a>
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        xl={12}
                        className="cardFooterBtnStyle"
                      >
                        {" "}
                        {props.details.role ? (
                          <Button
                            className="cohertStyle mb-2"
                            onClick={() => updateStudy(i)}
                          >
                            {viewStudyText.UPDATE}{" "}
                          </Button>
                        ) : (
                          <Button onClick={() => onAddCohort(i._id)} className="mb-2">
                            <span>
                              {viewStudyText.ADD_COHORT} <PlusSquareOutlined />
                            </span>{" "}
                          </Button>
                        )}
                        <Button
                          type={constantValues.PRIMARY}
                          className="ml-5"
                          onClick={() => onOpenStudyDetails(i)}
                        >
                          {viewStudyText.DETAILS}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </Col>
  );

  const filterPg = (
    <div>
      <div className="filterHdOuterStyle">
        <span className="filterSampleHdStyle">
          {viewStudyText.FILTERED_SAMPLES}:
        </span>
        <span className="filterCntStyle">
          {filters.length > 0 ? (
            <span>
              {filteredCnt === "" ||
              filteredCnt === null ||
              filteredCnt === undefined ? (
                <span>{totalStudies}</span>
              ) : (
                <span> {filteredCnt}</span>
              )} / {totalStudies}
            </span>
          ) : (
            <span>
              {totalStudies}/{totalStudies}
            </span>
          )}
        </span>
        <span>
          <Button onClick={onCloseModal}>
            <CloseOutlined />
          </Button>
        </span>
      </div>
      <Divider style={{ margin: "1% 0% 1% 0%" }} />
      <div
        className="filtersOutStyle filterOverFlowStyle"
      >
        {filters.length > 0 ? (
          <div>
            {" "}
            {filters
              .filter((x) => x.filterName !== viewStudyText.DATE_RANGE)
              .map((i) => ( 
              <div>
                <Row>
                <div className="filterNameOutStyle">
                  <div className="filterNmInnStyle">
                    <span className="filterNmTxtStyle">
                      {i.filterName}
                    </span>
                  </div>

                  {i.filterName === viewStudyText.AGE ||
                  i.filterName === viewStudyText.WEIGHT ||
                  i.filterName === viewStudyText.SAMPLE_SIZE ||
                  i.filterName === viewStudyText.ACQUISITION_DATE ? (
                    <div className="filterOperStyle">
                      <Select
                        className="filterOperSelectStyle"
                        onChange={(e) => handleOperatorFilter(i.filterName, e)}
                        options={viewStudyText.OPERATOR_OPTIONS}
                        value={i.operator}
                      />
                    </div>
                  ) : (
                    <div className="filterOperStyle">
                      <Select
                        className="filterOperSelectStyle"
                        onChange={(e) => handleOperatorFilter(i.filterName, e)}
                        options={viewStudyText.OPERATOR_INC_EXC_OPT}
                        value={i.operator}
                      />
                    </div>
                  )}
                  {i.filterName === viewStudyText.AGE ||
                  i.filterName === viewStudyText.WEIGHT ||
                  i.filterName === viewStudyText.SAMPLE_SIZE ||
                  i.filterName === viewStudyText.ACQUISITION_DATE ? (
                    <div
                      className="w-30 filterOperStyle"
                    >
                      {" "}
                      {i.filterName === viewStudyText.ACQUISITION_DATE ? (
                        <DatePicker
                          format={constantValues.YYYMMDD}
                          className="hw100"
                          style={{ height: "35px"}}
                          onChange={(date, dateStr) =>
                            handleValueDate(i.filterName, true, date, dateStr)
                          }
                          value={i.value1 ? dayjs(i.value1, 'YYYY-MM-DD'): ""}
                        />
                      ) : (
                        <Input
                          type={constantValues.NUMBER}
                          step="1"
                          className="filterVal1Style"
                          onChange={(e) => handleValue(i.filterName, true, e)}
                          value={i.value1}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className="filterOperStyle width42"
                    >
                      <Select
                        mode={constantValues.MULTIPLE}
                        allowClear
                        className="filterOperSelectStyle"
                        placeholder={constantValues.PLEASE_SELECT}
                        onChange={(e) => handleGenderSelect(i.filterName, e)}
                        options={(() => {
                          switch (i.filterName) {
                            case viewStudyText.SEX:
                              return sex.map((a) => ({
                                value: a,
                                label: a,
                              }));
                            case newStudyText.DATA_MODALITIES:
                              return (
                                customFilterOpt &&
                                customFilterOpt.data_modalities &&
                                customFilterOpt.data_modalities.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case viewStudyText.EXPERIMENTAL_COND:
                              return (
                                customFilterOpt &&
                                customFilterOpt.experimental_condition &&
                                customFilterOpt.experimental_condition.map(
                                  (a) => ({ value: a, label: a })
                                )
                              );
                            case viewStudyText.INSTRUMENT:
                              return (
                                customFilterOpt &&
                                customFilterOpt.instrument &&
                                customFilterOpt.instrument.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case viewStudyText.OTHER_DIAGNOSES:
                              return (
                                customFilterOpt &&
                                customFilterOpt.other_diagnoses &&
                                customFilterOpt.other_diagnoses.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case viewStudyText.PRIMARY_DIAGNOSIS:
                              return (
                                customFilterOpt &&
                                customFilterOpt.primary_diagnosis &&
                                customFilterOpt.primary_diagnosis.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case viewStudyText.RELEASE_VERSION:
                              return (
                                customFilterOpt &&
                                customFilterOpt.release_version &&
                                customFilterOpt.release_version.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case viewStudyText.PARTICIPANT_GROUPS:
                              return (
                                customFilterOpt &&
                                customFilterOpt.participant_groups &&
                                customFilterOpt.participant_groups.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            case newStudyText.TIMELINES:
                              return (
                                customFilterOpt &&
                                customFilterOpt.timelines &&
                                customFilterOpt.timelines.map((a) => ({
                                  value: a,
                                  label: a,
                                }))
                              );
                            default:
                              return null;
                          }
                        })()}
                        value={i.value1 ? i.value1.split("^") : []}
                      />
                    </div>
                  )}
                  {i.operator === constantValues.BETWEEN ? (
                    <div className="filterAmpOutStyle">
                      <span className="filterAmpSty"> &amp; </span>
                    </div>
                  ) : null}
                  {i.operator === constantValues.BETWEEN ? (
                    <div
                      className="filterOperStyle w-30"
                    >
                      {" "}
                      {i.filterName === viewStudyText.ACQUISITION_DATE ? (
                        <DatePicker
                          format={constantValues.YYYMMDD}
                          className="hw100"
                          style={{ height: "35px"}}
                          onChange={(date, dateStr) =>
                            handleValueDate(i.filterName, false, date, dateStr)
                          }
                          value={i.value2 ? dayjs(i.value2, 'YYYY-MM-DD'): ""}
                        />
                      ) : (
                        <Input
                          type={constantValues.NUMBER}
                          className="filterVal1Style"
                          onChange={(e) => handleValue(i.filterName, false, e)}
                          value={i.value2}
                        />
                      )}
                    </div>
                  ) : null}
                  <div style={{ marginLeft: "3%", marginTop: "1%" }}>
                    {" "}
                    <CloseOutlined onClick={() => onCloseFilter(i)} />{" "}
                  </div>
                </div>
                </Row>
              {(i.operator === null || i.operator === undefined || i.value1 === null || i.value1 === undefined) && 
              <div style={{color : "red"}}>Select / Enter proper value for all fields</div> } </div>
              ))}{" "}
          </div>
        ) : (
          <div className="filterNoneStyle">
            {" "}
            {constantValues.NO_FILTER_ADDED}{" "}
          </div>
        )}
      </div>
      <Divider style={{ margin: "1% 0% 1% 0%" }} />
      <div className="filterHdOuterStyle">
        <Select
        className="w-30"
          
          onChange={handleChange}
          defaultValue={filterVal}
          options={viewStudyText.CUSTOM_FILTER_OPTIONS}
          value={filterVal}
        />
        <Button className="marginLeft40" onClick={onCancel}>
          {constantValues.CLEAR}
        </Button>
        <Button
          type={constantValues.PRIMARY}
          className="marginLeft3" disabled={hasNullOrUndefined}
          onClick={() => onClickApply(null, false)}
        >
          {constantValues.APPLY}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Col xs={24} xl={24}>
        <div className="headerStyle">
          <Row>
            <Col xs={20} xl={20}>
              <div className="headerLineStyle">
                <span className="headerSpanStyle">
                  <Search
                    className="searchStyle"
                    placeholder={constantValues.SEARCH}
                    onSearch={onSearch}
                    onChange={onChangeSearch}
                    enterButton
                    value={searchText}
                  />
                </span>
                <span className="headerSpanStyle">
                  <RangePicker
                    picker={constantValues.YEAR}
                    onChange={(date, dateStr) => handleDateRange(date, dateStr)}
                    value={dateRange}
                  />
                </span>
              </div>
            </Col>
            <Col xs={4} xl={4}>
              <div className="marginTop10">
                <span>
                  {" "}
                  {props.details.role ? (
                    <span>
                      <Button
                        onClick={filterClick}
                        className="customFltrBtnStyle"
                      >
                        <FilterOutlined
                          className="customFltrStyle"
                        />
                      </Button>
                      <Button
                        type={constantValues.PRIMARY}
                        className="ml-2"
                        onClick={addNewClick}
                      >
                        <PlusOutlined />
                      </Button>
                    </span>
                  ) : (
                    <Button type={constantValues.PRIMARY} onClick={filterClick}>
                      {viewStudyText.CUSTOM_FILTER}
                      <FilterOutlined />
                    </Button>
                  )}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} xl={24}>
              {" "}
              {appliedFilters.length > 0 ? (
                <div className="studyCntStyle">
                  <span className="contentHeadingTextStyle}">
                    {viewStudyText.SHOWING}:{" "}
                  </span>{" "}
                  <span>
                    {filteredCnt === "" ||
                    filteredCnt === null ||
                    filteredCnt === undefined ? (
                      <span>{totalStudies}</span>
                    ) : (
                      <span>{filteredCnt}</span>
                    )}/{totalStudies} {viewStudyText.STUDIES_NOW}
                    {filteredCnt === "" ||
                    filteredCnt === null ||
                    filteredCnt === undefined
                      ? null
                      :  appliedFilters[0] !== undefined && appliedFilters.map((i) => (
                        (i!== undefined ? 
                          <span>
                            ,{" "}
                            <span
                              className="contentHeadingTextStyle"
                            >
                              {i.filterName}:{" "}
                            </span>{" "}
                            {i.operator}{" "}
                            {i.value1 && i.value1.replaceAll("^", ",")}{" "}
                            {i.value2 ? (
                              <span>
                                {constantValues.AND} {i.value2}
                              </span>
                            ) : null}
                          </span>  : null )
                        ))}
                    <span>.</span>
                  </span>
                </div>
              ) : (
                <div className="studyCntStyle">
                  <span className="contentHeadingTextStyle">
                    {viewStudyText.SHOWING}:{" "}
                  </span>{" "}
                  <span>
                    {filteredCnt === "" ||
                    filteredCnt === null ||
                    filteredCnt === undefined ? (
                      <span>{totalStudies}</span>
                    ) : (
                      <span> {filteredCnt}</span>
                    )}{" "}
                    / {totalStudies} {viewStudyText.STUDIES_NOW}
                    {viewStudyText.NO_FILTERS_ADDED}{" "}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          {studyAddCohort ? (
            <div className="cohortSuccessBg"
              style={{ paddingTop: "0.5%" }}
            >
              {" "}
              <Row>
                <Col xs={23} xl={23}>
                  <span>
                    {" "}
                    <CheckCircleFilled
                      className="cohortCheckStyle"
                    />
                    {studyAddMsg}{" "}
                  </span>
                </Col>
                <Col xs={1} xl={1}>
                  <span>
                    <Button
                      style={{ border: "none", background: "none" }}
                      onClick={() => {
                        setStudyAddCohort(false);
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </span>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
        <Row>
          <Col xs={24} xl={24}>
            <InfiniteScroll
              dataLength={items.length}
              next={fetchData}
              hasMore={moreData}
              loader={
                <h4 className="centreText" >{viewStudyText.LOADING}</h4>
              }
              endMessage={null}
            >
              {itemDiv}
            </InfiniteScroll>
          </Col>
        </Row>
      </Col>
      <Modal
        closable={false}
        width={350}
        centered={true}
        footer={null}
        title={
          <span>
            {viewStudyText.ADD_COHORT}{" "}
            <Button
              className="closeCohortStyle"
              onClick={onCloseCohort}
            >
              <CloseOutlined />{" "}
            </Button>{" "}
          </span>
        }
        open={isCohortModalOpen}
      >
        {" "}
        {cohortList.length > 0 ? (
          <div className="cohortStyle">
            <Checkbox checked={selectAllCohort} onChange={onSelectAllCohort}>
              {cohortList.length}{" "}
              {cohortList.length > 1 ? (
                <span> {constantValues.ITEMS}</span>
              ) : (
                <span> {constantValues.ITEM}</span>
              )}
            </Checkbox>
          </div>
        ) : null}
        <br />
        <div className="cohortListSize">
          {cohortList.map((i) => (
            <div>
              <Checkbox
                style={{ marginBottom: "3%" }}
                onChange={(e) => onSelectCohort(i.name, e)}
                checked={i.selected}
              >
                {i.name}
              </Checkbox>
            </div>
          ))}
        </div>
        <br />
        <div className="cohortFooter">
          {" "}
          {newCohort ? (
            <div>
              {" "}
              <span className="cohortNameStyle">
                {" "}
                {viewStudyText.COHORT_NAME}{" "}
              </span>{" "}
              <div>
                {" "}
                <Input
                  placeholder={viewStudyText.ENTER_COHORT_NAME}
                  onChange={(e) => cohortInput(e)}
                />{" "}
                <div>
                  {" "}
                  <Button
                    type="primary"
                    className="createCohortStyle"
                    onClick={createNewCohort}
                    disabled={disableCreateCohort}
                  >
                    {" "}
                    {constantValues.CREATE}{" "}
                  </Button>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : (
            <Button style={{ border: "none" }} onClick={createCohort}>
              <PlusOutlined /> {viewStudyText.CREATE_NEW_COHORT}
            </Button>
          )}
      <br />
        <div style={{width:"100%", textAlign:"right"}}>
          <Button type = "primary" onClick={onCloseCohortModal} > Save </Button>
        </div>
        </div>
      </Modal>
      <Modal
        closable={false}
        width={700}
        centered={true}
        footer={null}
        title={null}
        open={filterPage}
      >
        {filterPg}
      </Modal>
    </div>
  );
}
