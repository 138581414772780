export const NewStudyStyle = {
  headerStyle: {
    width: "100%",
    height: "30%",
    background: "#ffffff",
    border: "1px solid #D9D9D9",
    opacity: "1",
  },

  titleStyle: {
    textAlign: "left",
    font: "normal normal 600 20px/22px Arial",
    letterSpacing: "0px",
    color: "#262626",
    opacity: "1",
    paddingLeft: "1%",
    display: "flex",
    paddingTop: "30px",
  },

  userIconStyle: {
    textAlign: "left",
    height: "16px",
    display: "flex",
  },

  buttonStyle: {
    background: "#1890FF 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    opacity: "1",
    left: "70%",
    marginBottom:"0.5%",
  },

  leftTextStyle: {
    textAlign: "left !important",
    font: "normal normal 600 16px/24px Arial",
    letterSpacing: "0px",
    color: "#262626",
    opacity: "1",
    paddingLeft: "5%",
    paddingBottom: "2%",
    paddingTop: "5%",
    whiteSpace: "nowrap",
  },

  rightStyle: {
    background: "#F5F5F5 0% 0% no-repeat padding-box",
    height: "90vh",
    marginTop: "0%"
  },
  
  divTextStyle: {
    verticalAlign: "middle",
    textAlign: "left",
    font: "normal normal 600 16px/24px Arial",
    letterSpacing: "0px",
    color: "#262626",
    opacity: "1",
    whiteSpace: "nowrap",
    paddingLeft:"5%"
  },

  visibilityStyle: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D9D9D9",
    borderRadius: "3px",
    opacity: "1",
    width:"175px"
  },

  uploadBtn: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D9D9D9",
    borderRadius: "2px",
    opacity: "1",
  },

  uploadedDataStyle: {
    textAlign: "left",
    font: "normal normal normal 14px/22px Arial",
    letterSpacing: "0px",
    color: "#595959",
    opacity: "1",
    whiteSpace: "normal",
  },

  padding8: { paddingLeft: "8%" },

  domainStyle: {
    textAlign: "left",
    font: "normal normal normal 14px/22px Arial",
    letterSpacing: "0px",
    color: "#FA541C",
    opacity: "1",
    background: "#FFF2E8 0% 0% no-repeat padding-box",
    border: "1px solid #FFBB96",
    borderRadius: "2px",
    paddingLeft:"2%",
    paddingRight:"2%"
  },

  modalityStyle: {
    background: "#F6FFED 0% 0% no-repeat padding-box",
    border: "1px solid #B7EB8F",
    borderRadius: "2px",
    opacity: "1",
    textAlign: "left",
    font: "normal normal normal 14px/22px Arial",
    letterSpacing: "0px",
    color: "#52C41A",
    marginRight: "5%",
    whiteSpace: "noWrap",
    paddingLeft:"2%",
    paddingRight:"2%"
  },

  dispFlex: {
    display:"flex",
    flexDirection:"row"
  },

  updateBtnStyle: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    marginTop: "2%",
    border: "1px solid #D9D9D9",
    borderRadius: "2px",
    padding: "0% 4% 0% 4%",
    display: "block",
    whiteSpace: "nowrap",
    font: "normal normal normal 16px/24px Arial",
    color: "#595959",
  },

  updateDivStyle: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D9D9D9",
    borderRadius: "2px",
    padding: "1% 4% 1% 4%",
    display: "block",
  },
  versionStyle:{ left: "65%", marginBottom: "1%", height: "44px", width:"12%" },
  breadCrumbStyle:{paddingTop: "0.4%",paddingLeft:"1%",paddingRight:"0.5%"},

};
export default NewStudyStyle;
