export const sideBarStyle = {
  iconStyle: {
    left: "25px",
    height: "16px",
    opacity: "1",
  },

  logoStyle: {
    background: "transparent 0% 0% no-repeat padding-box",
    opacity: "1",
    top: "2%",
    height: "10%",
    paddingBottom: "10%",
    marginLeft: "10%",
    width: "80%",
    paddingTop:"7%"
  },

  menuStyle: {
    opacity: "1",
    left: "0.1%",
    paddingLeft: "5%",
    font: "normal normal normal 14px/24px Arial",
    letterSpacing: "0px",
    color: "#595959",
  },

  eachMenuStyle: {
    height: "2%",
    paddingLeft: "10%",
    paddingBottom: "10%",
  },

  paddingBottom5: {
    paddingBottom: "5%",
  },

  cohortMenuStyle: { paddingTop: "0px", paddingBottom: "0%" },

  paddingLeft0: { paddingLeft: "0%" },
  paddingLeft20: { paddingLeft: "20%" },
};

export default sideBarStyle;
