import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Select,
  Button,
  message,
  Modal,
  Checkbox,
  Input,
} from "antd";
import axios from "axios";
import {
  PlusSquareOutlined,
  UserOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { urls, pageLinks } from "../constants/urls";
import { NewStudyStyle } from "../styles/NewStudy";
import { viewStudyText } from "../constants/displayText";
import "../styles/ViewStudyStyle.css";
import "../styles/DetailedStudyStyle.css";
import StudyDetailComp from "../components/StudyDetail";
import VideoLinkComp from "../components/VideoLink";
import { useLocation, Link, useParams, useNavigate } from "react-router-dom";
import { constantValues } from "../constants/constantValues";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";

const apiUrl = process.env.REACT_APP_API_URL;

export default function DetailedStudy(props) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress, idToken } = useMsal();
  const params = useParams();

  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  React.useEffect(() => {
    if (tokRequest.account === null || tokRequest.account === undefined) {
      axios
        .get(`${apiUrl}/${urls.EXPLORE_DATASET}/${params.id}`)
        .then((response) => {
          if (response.status === 200) {
            if(Object.keys(response.data).length === 0 && response.data.constructor === Object){
              localStorage.setItem('studyId',params.id)
              message.error('Please login to view this page')
              setTimeout(function () {
                navigate(`/`);
              }, 2000); 
            }
            
            setAllDetails(response.data);

            setStudyDetail(response.data);
            let allVersions = response.data.release_version_list.map((i) => {
              return { value: i.release_version, label: i.release_version };
            });
            setVersionOptions(allVersions);
            setVersion(response.data.release_version);
          } else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
    } else {
      instance.acquireTokenSilent(tokRequest)
      .then((response) => {
        let config = {
          headers: { Authorization: `Bearer ${response.accessToken}` },
        };
        axios
          .get(`${apiUrl}/${urls.GET_STUDY_DETAIL_URL}${params.id}`, config)
          .then((response) => {
            if (response.status === 200) {
              setAllDetails(response.data);

              setStudyDetail(response.data);
              let allVersions = response.data.release_version_list.map((i) => {
                return { value: i.release_version, label: i.release_version };
              });
              setVersionOptions(allVersions);
              setVersion(response.data.release_version);
            } else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
      })
      .catch((e) => {
        navigate('/')
       })
    }
  }, [params]);

  const [studyDetail, setStudyDetail] = React.useState();
  const [allDetails, setAllDetails] = React.useState([]);
  const [version, setVersion] = React.useState("");
  const [isCohortModalOpen, setIsCohortModalOpen] = React.useState(false);
  const [cohortStudyId, setCohortStudyId] = React.useState();
  const [versionOptions, setVersionOptions] = React.useState([]);
  const [cohortList, setCohortList] = React.useState([]);
  const [newCohort, setNewCohort] = React.useState(false);
  const [studyAddCohort, setStudyAddCohort] = React.useState(false);
  const [selectAllCohort, setSelectAllCohort] = React.useState(false);
  const [disableCreateCohort, setDisableCreateCohort] = React.useState(true);
  const [newCohortName, setNewCohortName] = React.useState("");
  const location = useLocation();

  const handleChange = (value) => {
    setVersion(value);
    let selectedVersion = allDetails.release_version_list.filter(
      (i) => i.release_version === value
    );
    let slctedVersionId = selectedVersion[0]._id;
    navigate(`${pageLinks.VW_STUDY}/${Object.values(slctedVersionId)}`);
  };

  function onAddCohort() {
    if (tokRequest.account === null || tokRequest.account === undefined) { 
      localStorage.setItem('studyId',params.id)
      message.error('Please login to add the study to cohorts')
      setTimeout(function () {
        navigate(`/`);
      }, 2000); 
    }
    else {
    instance.acquireTokenSilent(tokRequest)
    .then((response) => {
      let query = {
        user_id: localStorage.getItem("userObjId"),
        study_id: Object.values(studyDetail._id)[0],
      };
      let config = {
        headers: { Authorization: `Bearer ${response.accessToken}` },
        params: query,
      };
      axios
        .get(`${apiUrl}/${urls.GET_COHORT_LIST}`, config)
        .then((response) => {
          if (response.status === 200) {
            setCohortList(response.data.cohort_list);
            if (
              response.data.cohort_list.filter((i) => i.selected === false)
                .length === 0
            ) {
              setSelectAllCohort(true);
            }
            setIsCohortModalOpen(true);
            setCohortStudyId(studyDetail);
          } else {
            message.error(`${constantValues.ERR_MSG}`);
            setIsCohortModalOpen(false);
          }
        })
        .catch(() =>{
          console.log("error")
        })
    })
    .catch((e) => {
      navigate('/')
     })
  }
  }

const onCloseCohort = () => {
  setIsCohortModalOpen(false);
  setNewCohort(false);
}

  const onCloseCohortModal = () => {
    setIsCohortModalOpen(false);
    setNewCohort(false);
    instance.acquireTokenSilent(tokRequest)
    .then((response) => {
      let query = {
        user_id: localStorage.getItem("userObjId"),
      };
      let stdyId = Object.values(cohortStudyId)[0];
      let data = {
        study_id: Object.values(stdyId)[0],
        cohort_ids: cohortList,
      };
      let config = {
        headers: { Authorization: `Bearer ${response.accessToken}` },
        params: query,
      };
      axios
        .put(`${apiUrl}/${urls.ADD_STUDY_COHORT}`, data, config)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.message !== "") {
              message.success(`${response.data.message}`);
            }
          } else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
    })
    .catch((e) => {
      navigate('/')
     })
  };

  const createCohort = () => {
    setNewCohort(true);
  };

  function cohortInput(e) {
    if (cohortList.filter((i) => i.name === e.target.value).length > 0)
      setDisableCreateCohort(true);
    else {
      setDisableCreateCohort(false);
    }
    setNewCohortName(e.target.value);
  }

  const createNewCohort = () => {
    instance.acquireTokenSilent(tokRequest)
    .then((response) => {
      let body = {
        user_id: localStorage.getItem("userObjId"),
        cohort_name: newCohortName,
      };
      let config = {
        headers: { Authorization: `Bearer ${response.accessToken}` },
      };
      axios
        .post(`${apiUrl}/${urls.ADD_NEW_COHORT}`, body, config)
        .then((response) => {
          setNewCohort(false);
          if (response.status === 200) {
            setCohortList([
              ...cohortList,
              { name: newCohortName, selected: false },
            ]);
            localStorage.setItem(
              "cohList",
              JSON.stringify(response.data.cohort_list)
            );
            props.getCohort([...response.data.cohort_list]);
          } else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
    })
    .catch((e) => {
      navigate('/')
     })
  };

  const onSelectCohort = (cohortName, e) => {
    let updateCohort = cohortList;
    updateCohort = updateCohort.map((i) => {
      if (i.name === cohortName) {
        i.selected = e.target.checked;
      }
      return i;
    });
    setCohortList(updateCohort);
    if (updateCohort.filter((i) => i.selected === false).length === 0) {
      setSelectAllCohort(true);
    } else {
      setSelectAllCohort(false);
    }
  };

  const onSelectAllCohort = (e) => {
    setSelectAllCohort(e.target.checked);
    let updateCohort = cohortList;
    updateCohort = updateCohort.map((i) => {
      i.selected = e.target.checked;
      return i;
    });
    setCohortList(updateCohort);
  };

  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <div style={NewStudyStyle.headerStyle}>
            <div>
              <Breadcrumb>
                <UserOutlined className="userOutlinedStyle" />{" "}
                <Breadcrumb.Item key="/studies">
                  <Link to={tokRequest.account === null || tokRequest.account === undefined ? "/explore" : "/studies"}>Browse Studies</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="details">Study Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Row>
              <Col xs={18} xl={18}>
                <div className="paddingTitle"
                  style={{
                    ...NewStudyStyle.titleStyle,
                  }}
                >
                  {studyDetail && studyDetail.title}
                </div>
              </Col>
              <Col xs={6} xl={6}>
                <div className="versionStyle">
                  <Select
                    onChange={handleChange}
                    className="marginRight10"
                    options={versionOptions}
                    value={version}
                  />
                  {localStorage.getItem("role") !== "admin" ? (
                    <Button
                      className="cohertStyle"
                      onClick={() => onAddCohort()}
                    >
                      {viewStudyText.ADD_COHORT} <PlusSquareOutlined />
                    </Button>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Row>

        <Row>
          <Col xs={24} xl={24}>
            <Row>
              <Col xs={18} xl={18}>
                <StudyDetailComp details={{ ...studyDetail }}></StudyDetailComp>
              </Col>
              <Col xs={6} xl={6}>
                <VideoLinkComp details={{ ...studyDetail }}></VideoLinkComp>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        closable={false}
        width={350}
        centered={true}
        footer={null}
        title={
          <span>
            Add to Cohort{" "}
            <Button
              style={{ border: "none", left: "50%" }}
              onClick={onCloseCohort}
            >
              <CloseOutlined />{" "}
            </Button>{" "}
          </span>
        }
        open={isCohortModalOpen}
      >
        {" "}
        {cohortList.length > 0 ? (
          <div
            style={{
              borderBottom: "1px solid #E9E9E9",
              borderTop: "1px solid #E9E9E9",
              paddingTop: "3%",
            }}
          >
            <Checkbox checked={selectAllCohort} onChange={onSelectAllCohort}>
              {cohortList.length}{" "}
              {cohortList.length > 1 ? <span> items</span> : <span> item</span>}
            </Checkbox>
          </div>
        ) : null}
        <br />
        <div style={{ overflowY: "auto", height: "150px" }}>
          {cohortList.map((i) => (
            <div>
              <Checkbox
                style={{ marginBottom: "3%" }}
                onChange={(e) => onSelectCohort(i.name, e)}
                checked={i.selected}
              >
                {i.name}
              </Checkbox>
            </div>
          ))}
        </div>
        <br />
        <div style={{ borderTop: "1px solid #E9E9E9", paddingTop: "5%" }}>
          {" "}
          {newCohort ? (
            <div>
              {" "}
              <span
                style={{
                  font: "normal normal 600 14px/22px Arial",
                  color: "#595959",
                }}
              >
                {" "}
                Cohort Name{" "}
              </span>{" "}
              <div>
                {" "}
                <Input
                  placeholder="Enter Cohort Name"
                  onChange={(e) => cohortInput(e)}
                />{" "}
                <div>
                  {" "}
                  <Button
                    type="primary"
                    style={{ marginTop: "3%", left: "80%" }}
                    onClick={createNewCohort}
                    disabled={disableCreateCohort}
                  >
                    {" "}
                    Create{" "}
                  </Button>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : (
            <Button style={{ border: "none" }} onClick={createCohort}>
              <PlusOutlined /> Create new Cohort
            </Button>
          )}
        </div>
        <br />
        <div style={{width:"100%", textAlign:"right"}}>
          <Button type = "primary" onClick={onCloseCohortModal} > Save </Button>
        </div>
      </Modal>
    </div>
  );
}
