import React from "react";
import SideBarComp from "../components/SideBar";
import ReviewCommentsComp from "../components/ReviewComments";
import { Row, Col } from "antd";
import "../styles/PageStyle.css"
import { Navigate, Route, useLocation } from 'react-router-dom'

export default function AdminRequest(props) {
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('role'));
  const location = useLocation()
  return (
    <div> {isAuth ? 
    (<div>
      <Row>
        {localStorage.getItem("userType") === "sc" ? null : 
        <Col xs={3} xl={3}>
          <div className="siderStyle">
            <SideBarComp pgName={{page:"requests"}}></SideBarComp>
          </div>
        </Col> }
        {localStorage.getItem("userType") === "sc" ? 
        <Col xs={24} xl={24}> 
        
          <div className="contentStyle">
            <ReviewCommentsComp />
          </div>
        </Col> :<Col xs={21} xl={21}> <div className="contentStyle">
            <ReviewCommentsComp />
          </div>
        </Col>}
      </Row>
      </div> ):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
