import React from "react";
import moment from "moment";
import {
  Row,
  Col,
  Select,
  Button,
  Divider,
  Input,
  Modal,
  DatePicker,
  Table,
  message
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {
  viewStudyText,
  admReqTxt,
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import "../styles/CommonStyles.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";

const { RangePicker } = DatePicker;
const { Search } = Input;

const { TextArea } = Input;

export default function MyReq(props) {
  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [respData, setRespData] = React.useState();
  const [respTblValues, setRespTblValues] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [dateRange, setDateRange] = React.useState("");
  const [selectStat, setSelectStat] = React.useState("");
  const [pageNum, setPageNum] = React.useState(0);
  const [moreData, setMoreData] = React.useState(true);
  const [filteredCnt, setFilteredCnt] = React.useState(0);
  const [totalCnt, setTotalCnt] = React.useState(0);
  const [statusUpd, setStatusUpd] = React.useState(false);
  const [updStatusName, setUpdStatusName] = React.useState("");

  React.useEffect(() => {
    apiCall();
    if (props && props.reqUpd && props.reqUpd.status) {
      setStatusUpd(true);
      setUpdStatusName(props.reqUpd.reqTitle);
    }
  }, []);

  const apiCall = (searchValue, dateFilter, selectedStatus, fetchData) => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let config = {
          headers: { Authorization: `Bearer ${response.accessToken}` },
          params: {},
        };
        config.params.search =
          searchValue !== null && searchValue !== undefined
            ? searchValue
            : searchText !== ""
              ? searchText
              : null;
        config.params.daterange =
          dateFilter !== null && dateFilter !== undefined
            ? dateFilter
            : dateRange !== ""
              ? dateRange
              : null;
        config.params.req_status =
          selectedStatus !== null && selectedStatus !== undefined
            ? selectedStatus
            : selectStat !== ""
              ? selectStat
              : null;
        if (fetchData) {
          config.params.page_num = pageNum;
        } else {
          config.params.page_num = 0;
          setPageNum(0);
        }
        config.params.user_id = localStorage.getItem('userObjId')
        axios.get(`${apiUrl}/${urls.USER_REQ_URL}`, config).then((response) => {
          if (response.status === 200) {
            setPageNum(pageNum + 1);
            setRespData(response.data);
            if (response.data.data.length > 0) {
              setMoreData(true);
              let respTblVal = response.data.data.map((i) => {
                let dateTime = Object.values(i.req_time)[0]
                let date = dateTime.split('T')
                i.reqDate =
                  i.req_time &&
                  moment(date[0]).format("YYYY-MM-DD");
                i.status = i.status.charAt(0).toUpperCase() + i.status.slice(1);
                return i;
              });
              setFilteredCnt(response.data.filtered_count);
              setTotalCnt(response.data.total);
              setRespTblValues(respTblVal);
              if (fetchData) {
                let addedValues = respTblValues.concat(respTblVal);
                setRespTblValues(addedValues);
              }
              else {
                setPageNum(1)
                setRespTblValues(respTblVal)
              }
              if (fetchData && (respTblValues.length + response.data.data.length) === response.data.total) {
                setMoreData(false);
              }
              else if (!fetchData && response.data.data.length === response.data.filtered_count) {
                setMoreData(false);
              }
            } else {
              setMoreData(false);
              setFilteredCnt(response.data.filtered_count);
              setTotalCnt(response.data.total);
              setRespTblValues([]);
            }
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
      })
      .catch((e) => {
        navigate('/')
       })
  };


  const columns = [
    {
      title: "Request Id",
      dataIndex: "_id",
      key: "_id",
      render: (text) =>
        <span>
          {Object.values(text)[0]}
        </span>
    },
    {
      title: "Request Title",
      dataIndex: "req_title",
      key: "req_title",
    },

    {
      title: "Request Date",
      dataIndex: "reqDate",
      key: "reqDate",
    },
    {
      title: "Cohort Used ",
      dataIndex: "cohort_name",
      key: "cohort_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        <span style={{
          padding: "3% 3% 3% 3%", background: text === 'Approved' ?
            "#F6FFED" : text === "Rejected" ? "#FFF2E8" : text === "Pending" ?
              "#D2CECE80" : "#FDBCA18A", color: text === 'Approved' ?
                "#006633" : text === "Rejected" ? "#FF0000" : text === "Pending" ?
                  "#808080" : "#FF8000", borderRadius: "2px",
          border: text === 'Approved' ?
            "1px solid #B7EB8F" : text === "Rejected" ? "1px solid #FFBB96" : text === "Pending" ?
              "1px solid #80808080" : "1px solid #FF4A0080"
        }}>
          {text}
        </span>
    },
  ];

  const onSearch = (value) => {
    setSearchText(value);
    apiCall(value);
  };

  const handleDateRange = (date, dateStr) => {
    let dateFilter = "";
    if (dateStr[0] !== "" && dateStr[1] !== "") {
      dateFilter = "between^" + dateStr[0] + "^" + dateStr[1];
      setDateRange(dateFilter);
    } else {
      setDateRange("");
    }
    apiCall(null, dateFilter);
  };

  const fetchData = () => {
    apiCall(null, null, null, true);
  };

  const handleStatusChange = (value) => {
    let selectedStatus = value.join("^");
    setSelectStat(selectedStatus);
    apiCall(null, null, selectedStatus);
  };

  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <Col xs={20} xl={20}>
            <div className="headerLineStyle b-0">
              <span className="headerSpanStyle">
                <Search
                  className="searchStyle"
                  placeholder={constantValues.SEARCH}
                  onSearch={onSearch}
                  enterButton
                />
              </span>
              <span className="headerSpanStyle">
                <RangePicker
                  picker={constantValues.DATE}
                  onChange={(date, dateStr) => handleDateRange(date, dateStr)}
                />
              </span>
            </div>
          </Col>
          <Col xs={4} xl={4}>
            <div className="marginTop10" style={{ height: "10%" }}>
              <Select
                mode={constantValues.MULTIPLE}
                allowClear
                placeholder="Select Status"
                options={admReqTxt.ADM_REQ_STATUS_OPTIONS}
                className="expCondStyle"
                style={{ width: "50%" }}
                onChange={(e) => handleStatusChange(e)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div style={{ marginLeft: "1%" }}>
            {" "}
            <span className="showingTextStyle">
              {viewStudyText.SHOWING}:
            </span>{" "}
            <span
              style={{
                textAlign: "left",
                font: "normal normal normal 14px/22px Arial",
                letterSpacing: "0px",
                color: "#8C8C8C",
              }}
            >
              {filteredCnt}/{totalCnt} requests.{" "}
            </span>
            {dateRange === "" && selectStat === "" ? (
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 14px/22px Arial",
                  letterSpacing: "0px",
                  color: "#8C8C8C",
                }}
              >
                No custom filters added
              </span>
            ) : (
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 14px/22px Arial",
                  letterSpacing: "0px",
                  color: "#8C8C8C",
                }}
              >
                {dateRange !== "" ? (
                  <span>
                    Date Range{" "}
                    {dateRange
                      .replace("between^", "between ")
                      .replace("^", " and ")} ,
                  </span>
                ) : (
                  null
                )}
                {selectStat !== "" ? (
                  <span> Status: {selectStat.replaceAll("^", ", ")}</span>) : null}
              </span>
            )}
          </div>
        </Row>
        <Divider className="dividerStyle" > </Divider>
        <Row>
          <Col xs={24} xl={24}>
            <InfiniteScroll
              dataLength={respTblValues.length}
              next={fetchData}
              hasMore={moreData}
              loader={
                <h4 className="centreText">{viewStudyText.LOADING}</h4>
              }
              endMessage={null}
            >
              <Row>
                <Table
                  columns={columns}
                  dataSource={respTblValues}
                  pagination={false}
                  headerSpanStyle={{ background: "#0065A4" }}
                  style={{ width: "100%" }}
                />
              </Row>
            </InfiniteScroll>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
