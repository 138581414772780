import React from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Modal,
  message,
  Spin,
  Tabs
} from "antd";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import ReqDetailComponent from "./ReqDetailComponent";
import {
  statisticsText
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate, useParams } from "react-router-dom";
import ReviewComments from "./ReviewComments";

const apiUrl = process.env.REACT_APP_API_URL;
const { TextArea } = Input;

export default function SteeringRequest() {

  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  const navigate = useNavigate();
  const params = useParams()
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [scComments, setScComments] = React.useState();
  const [mailVerified, setMailVerified] = React.useState(true);
  const [emailId, setEmailId] = React.useState("");

  React.useEffect(() => {
    getScComments()
        let query = {
          request_id: params.id,
        };
        let config = {
          params: query,
        };
        axios.get(`${apiUrl}/${urls.DAC_REQ_DETAILS}`, config).then((response) => {
          if (response.status === 200) {
            setLoading(false)
            setReqData(response.data);
            if (response.data.data) {
              if (response.data.user_data) {
                setUserDet(response.data.user_data)
              }
              if (
                response.data.filter !== null &&
                response.data.filter !== undefined
              ) {
                let prevFilter = response.data.filter;
                let prevFilterArray = Object.entries(prevFilter);
                let newArr = prevFilterArray.map((i) => {
                  let val = i[1].split("^");
                
                  let commonValues = {
                    filterName: i[0],
                    operator: val[0],
                  };
                
                  if (val.length === 2) {
                    return {
                      ...commonValues,
                      value1: val[1],
                    };
                  }
                
                  if (val.length > 2 && (val[0] === "include" || val[0] === "exclude")) {
                    return {
                      ...commonValues,
                      value1: val.slice(1).join("^"),
                    };
                  }
                
                  return {
                    ...commonValues,
                    value1: val[1],
                    value2: val[2],
                  };
                });
                let formattedNewArr = [...newArr];
                formattedNewArr = formattedNewArr.map((i) => {
                  i.filterName = statisticsText.FILTER_NAMES[i.filterName];
                  return i;
                });
                setFilters(formattedNewArr);
              }
            }
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
      // })
      
  }, []);

  const [reqData, setReqData] = React.useState();
  const [userDet, setUserDet] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [decisionNote, setDecisionNote] = React.useState("");

  const [openAddReview, setOpenAddReview] = React.useState(false);

  const onInpDesc = (event) => {
    setDecisionNote(event.target.value)
  }

  const getScComments = () => {
    axios.get(`${apiUrl}/user/get-sc-comments/${params.id}/${params.userId}`)
    .then((response) => { 
        if(response.status === 200) {
          setScComments(response.data)
          setDecisionNote(response.data.comments)
        }
    })
    .catch(() =>{
      console.log("error")
    })
  }

  const onSubmit = (selectedStatus) => {
    let payload ={
      "sc_comments": [
        {
          "user_id": params.userId,
          "comment": decisionNote,
          "release_status": selectedStatus,
        }
      ]
    }
    axios.put(`${apiUrl}/user/submit-sc-comments/${params.id}`, payload)
    .then((response) => {
      if(response.status === 200) {
        message.success("Steering committee comments submitted successfully")
      }
      else {
        message.error("Could not submit Steering committee comments")
      }
      setOpenAddReview(false)
      getScComments()
    })
    .catch(() =>{
      console.log("error")
    })

  }

  const verifyMail = () => {
    try {
    axios.get(`${apiUrl}/user/verify-email?request_id=${params.id}&user_id=${params.userId}&email=${emailId}&member_type=SC`)
    .then((response) => {
      if(response && response.data && response.data.verify_email === true) {
        setMailVerified(false)
     } 
     else if(response && response.data && response.data.verify_email === false) {
      message.error("Please enter valid email id")
     }
    })
    .catch(() =>{
      console.log("error")
    })
  }
    catch (error) {
      message.error("Please enter valid email id")
    }
  }

  return (
    <div>
      <Col xs={24} xl={24}>
      {!mailVerified && reqData && reqData.request_data && reqData.request_data.sc_link === true ?
        <div>
          <Row>
            <Col xs={16} xl={16}>
              <div
                className="titleStyle pt-2"
              >
                <span> {reqData && reqData.request_data && reqData.request_data.req_title} </span>
              </div>
            </Col>

            {/* <Col xs={8} xl={8} style={{ marginBottom: "2%", marginTop: "2%" }}>
                <div className="d-flex flex-row justify-content-end">
                <Button
              style={{
                border: "1px solid #1890FF",
                borderRadius: "2px", color: "#1890FF", marginLeft: "4%", marginRight:"2%"
              }}
              onClick={() => {                    
                  localStorage.setItem("prev-page", window.location.href)
                  navigate(`/review-comments/${params.id}/${params.userId}`)                    
              }}
            >
              Review DAC Comments
            </Button>                
              <span style={{ paddingTop: "1%", paddingLeft: "2%", paddingRight:"2%" }}>                    
                {scComments && scComments.release_status === true ?
                  "Recommended" : scComments && scComments.release_status === false ? "Not Recommended" : null} </span> 
                </div>
            </Col> */}
          </Row>
          <Divider className="dividerStyle m-0"> </Divider>
          <Row>
            <Col span={8}>
            <div className="mt-2 ml-2">
            <TextArea style={{ width: "100%", marginBottom:"4%" }}
              placeholder="Add comments"
              onChange={onInpDesc}
              rows={5}
              defaultValue={scComments && scComments.comments}
              />
          </div>
            <div className="d-flex flex-row justify-content-end m-2">
              <div className="p-2" style={{
                background: "#F6FFED", color: "#006633", borderRadius: "2px",
                border: "1px solid #B7EB8F", marginRight: "4%", cursor: "pointer", textWrap: "wrap"
              }}
                onClick={() => {
                    setStatus(true)
                    onSubmit(true)
                    // setOpenAddReview(true)                     
                }}
              >
                Agree to Release the data
              </div> 
            
              <div className="p-2" style={{
                background: "#FFF2E8", color: "#FF0000", borderRadius: "2px",
                border: "1px solid #FFBB96", cursor: "pointer", textWrap: "wrap"
              }}
                onClick={() => {
                    setStatus(false)
                    onSubmit(false)
                    // setOpenAddReview(true)
                }}
              >
                Do not agree to release the data
              </div> 
           
          </div>
          <ReviewComments requestId={params.id} userId={params.userId} steering={true}/> 
            </Col>
            <Col span={16}>
              <ReqDetailComponent userDet={userDet} reqData={reqData} id={params.id} filters={filters}/>
            </Col>
          
          </Row>
        </div> :
        <div style={{textAlign:"center"}}><h4>The link is closed</h4></div>
        }

        <Modal
          // closable={false}
          width={600}

          centered={true}
          footer={null}
          title={
            <div>
              <Col xs={24} xl={24}>
                <Row>
                  <Col xs={20} xl={20}>
                    <div><span className="filterSampleHdStyle">
                      Add Comments
                    </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          }
          open={openAddReview}
          onCancel={()=>{setOpenAddReview(false)}}
        >
          {" "}
          
          <div style={{justifyContent:"end", display:"flex"}}><Button onClick={onSubmit} type="primary">Send</Button></div>
        </Modal>
        <Modal
          width="30%"
          // centered={true}
          footer={null}
          title="Email Id verification"
          closable={false}
          // open={mailVerified && reqData && reqData.request_data && reqData.request_data.dac_link === true}
          open={mailVerified}
        >
          <div>
              <div><Input type="email" placeholder="Enter Email id" onChange={(e) => setEmailId(e.target.value)}/></div>
              <div style={{marginTop:"2%", justifyContent:"end", display:"flex"}}><Button type="primary" onClick={verifyMail}>Verify Email Id</Button></div>
            </div>
        </Modal>
        <Modal
          closable={false}
          width={null}
          centered={true}
          footer={null}
          title={null}
          open={loading}
        >
          <Spin />
        </Modal>
      </Col>
    </div>
  );
}
