import React from "react";
import SideBar from "../components/sideBarUser";
import MyReqComp from "../components/MyRequests";
import { Row, Col } from "antd";
import "../styles/PageStyle.css"
import { Navigate, Route, useLocation } from 'react-router-dom'

export default function MyRequest(props) {
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('role'));
  const [cohortList, setCohortList] = React.useState(JSON.parse(localStorage.getItem('cohList')));

  const getCohortDetails = (param) => {
    setCohortList(param);
  };

  return (
    <div> {isAuth ? 
    (<div>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle">
            <SideBar pgName={{page:"my-req"}} getCohort={getCohortDetails} cohortList={{cohort: cohortList}}></SideBar>
          </div>
        </Col>
        <Col xs={21} xl={21}>
          <div className="contentStyle">
            <MyReqComp getCohort={getCohortDetails}></MyReqComp>
          </div>
        </Col>
      </Row>
      </div> ):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
