import React from "react";
import SideBarComp from "../../components/SideBar";
import NewStudyComp from "../../components/NewStudy";
import { Row, Col } from "antd";
import "../../styles/PageStyle.css"
import { Navigate} from 'react-router-dom';

export default function UpdateStudyPage(props) {
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('role'));
  return (
    <div> {isAuth === 'admin' ? 
      (
    <div style={{height:"100%vh"}}>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle">
            <SideBarComp></SideBarComp>
          </div>
        </Col>
        <Col xs={21} xl={21}>
          <div className="contentStyle">
            <NewStudyComp details={{updStudy:true}}></NewStudyComp>
          </div>
        </Col>
      </Row>
    </div>):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
