import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ViewStudyList from "./pages/BrowseStudies/ViewStudyList";
import ExploreStudy from "./pages/BrowseStudies/ExploreStudy";
import ExploreDetailedStudy from "./pages/BrowseStudies/ExploreDetailedStudy";
import AdmReqDetails from "./pages/AdmReqDetail";
import VwStdy from "./pages/VwStdy";
import DetailedStudy from "./pages/BrowseStudies/DetailedStudy";
import ReqBasket from "./pages/ReqBasket";
import NewStudy from "./pages/BrowseStudies/NewStudy"
import UpdateStudy from "./pages/BrowseStudies/UpdateStudy"
import NewRelease from "./pages/BrowseStudies/NewRelease"
import AdminRequest from "./pages/AdmRequest"
import MyRequest from "./pages/MyRequests"
import MyCohort from "./pages/MyCohort"
import { pageLinks } from "./constants/urls"
import Login from "./pages/Login"
import ReviewComments from "./pages/ReviewComments"
import DacReqDetail from "./pages/DacReqDetail"

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import SteeringCommitteePage from './pages/SteeringCommitteePage';

 function App() {
    const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <BrowserRouter>
      <Routes>
        <Route path={pageLinks.VW_STUDY} element={<MsalProvider instance={msalInstance}><ViewStudyList /></MsalProvider>}> </Route> 
        <Route path={pageLinks.LOGIN} element={<MsalProvider instance={msalInstance}><Login /></MsalProvider>}> </Route>
        <Route path={pageLinks.VIEW_STUDY} element={<VwStdy />}> </Route>
        <Route path={`${pageLinks.VW_STUDY}/:id`} element={<MsalProvider instance={msalInstance}><DetailedStudy /></MsalProvider>}> </Route>
        <Route path={`${pageLinks.VW_STUDY}${pageLinks.UPDATE_STUDY}/:id`} element={<MsalProvider instance={msalInstance}><UpdateStudy /></MsalProvider>}> </Route>
        <Route path={`${pageLinks.VW_STUDY}${pageLinks.NEW_RELEASE}`} element={<MsalProvider instance={msalInstance}><NewRelease /></MsalProvider>}> </Route>
        <Route path={`${pageLinks.MY_COHORTS}/:cohortId`}element={<MsalProvider instance={msalInstance}><MyCohort /></MsalProvider>}> </Route>
        <Route path={`${pageLinks.VW_STUDY}${pageLinks.NEW_STUDY}`} element={<MsalProvider instance={msalInstance}><NewStudy /></MsalProvider>}></Route>
        <Route path={`${pageLinks.REQ_BASKET}`} element={<MsalProvider instance={msalInstance}><ReqBasket /></MsalProvider>}></Route>            
        <Route path={`${pageLinks.EXPLORE_STUDY}`} element={<MsalProvider instance={msalInstance}><ExploreStudy /></MsalProvider>}></Route>           
        <Route path={`${pageLinks.EXPLORE_STUDY}/:id`} element={<MsalProvider instance={msalInstance}><ExploreDetailedStudy /></MsalProvider>}></Route>           
        <Route path={`${pageLinks.ADMIN_REQUEST}`} element={<MsalProvider instance={msalInstance}><AdminRequest /></MsalProvider>}></Route>           
        <Route path={`${pageLinks.MY_REQUESTS}`} element={<MsalProvider instance={msalInstance}><MyRequest /></MsalProvider>}></Route>           
        <Route path={`${pageLinks.ADMIN_REQUEST}/:id`} element={<MsalProvider instance={msalInstance}><AdmReqDetails /></MsalProvider>}></Route>         
        <Route path={`${pageLinks.REVIEW_COMMENTS}/:requestId/:userId`} element={<MsalProvider instance={msalInstance}><ReviewComments /></MsalProvider>}></Route>         
        <Route path={`${pageLinks.DAC_REQUEST}/:id/:userId`} element={<MsalProvider instance={msalInstance}><DacReqDetail /></MsalProvider>}></Route>         
        <Route path={`${pageLinks.STEERING_REQUEST}/:id/:userId`} element={<MsalProvider instance={msalInstance}><SteeringCommitteePage /></MsalProvider>}></Route>         
        </Routes>      
        </BrowserRouter>
  );
} 


export default App;
