import React from "react";
import ExpStudy from "../../components/ExploreStudy";
import { Row, Col } from "antd";
import "../../styles/PageStyle.css"
import { pageLinks, urls } from "../../constants/urls";
import { Space, Image, Button } from "antd";
import SideBarStyle from "../../styles/SideBar"
import { ProfileOutlined } from "@ant-design/icons";
import { sideBarText } from "../../constants/displayText";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

export default function ExploreStudy(props) {
  const navigate = useNavigate()
  
  const onImage = () => {
    navigate('/')
  }
  return (
  
    <div>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle">
          <Image style={{...SideBarStyle.logoStyle, cursor: "pointer" }} preview={false} src={process.env.PUBLIC_URL+"/static/media/logo.png"} alt="Brain Logo"  onClick={onImage} />
      <div style={SideBarStyle.eachMenuStyle}>
        <Space>
          <ProfileOutlined style={SideBarStyle.iconStyle} />
        </Space>
        <a href={pageLinks.EXPLORE_STUDY} style={SideBarStyle.menuStyle}>{sideBarText.BROWSE_STUDIES} </a>
      </div> 
          </div>
        </Col>       
        <Col xs={21} xl={21}>
          <div className="contentStyle">
            <ExpStudy ></ExpStudy>
          </div>
        </Col> 
      </Row>
    </div> 

  );
}
