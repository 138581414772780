export const sideBarText = {
  BROWSE_STUDIES: "Browse Studies",
  REQUEST: "Request",
  LOGOUT: "Logout",
  MY_REQUESTS: "My Requests",
  MY_COHORTS: "My Cohorts",
  REQUEST_BASKET: "Draft Requests"
};

export const newStudyText = {
  ADD_NEW_STUDY: "Add new Study",
  SAVE: "Save",
  VISIBILITY: "Access Level",
  SELECT_VISIBILITY: "Select Access Level",
  VISIBILITY_OPTIONS: [
    { value: "admin-only", label: "Admin-only" },
    { value: "controlled", label: "Controlled" },
    { value: "public", label: "Public" },
  ],
  UPLOAD_DESC_JSON_FILE: "Upload Description JSON File",
  UPLOAD_SUB_JSON_FILE: "Upload Participants JSON File",
  UPLOAD_SAMPLE_JSON_FILE: "Upload Sample JSON File",
  CLICK_TO_UPLOAD: "Click to Upload",
  SAMPLE_SIZE: "Sample Size",
  VERSION: "Version",
  DESCRIPTION: "Description",
  CONTRIBUTORS: "Contributors",
  DOMAINS: "Domains",
  DATA_MODALITIES: "Data Modalities",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  ONGOING_DATE: "Ongoing Date",
  TIMELINES: "Timelines",
  TOTAL_FILES: "Total Files",
  TOTAL_FILE_SIZE: "Total File Size",
  MODALITIES: "Modalities",
  DIFF_VERSIONS: "File Versions Do Not Match",
  NEW_VERSION_MSG: "File Contains New Version",
  BREADCRUMBVALUES: {
    '/studies': 'Browse Studies',
    '/studies/update': 'Update Study',
    '/studies/new-release': 'New Release',
    '/studies/new': 'Add New Study'
  }
};

export const viewStudyText = {
  CUSTOM_FILTER:"Custom Filter",
  DETAILS: "Details",
  ADD_COHORT: "Add to Cohort",
  DOI: "DOI",
  SHOWING: "Showing",
  STUDIES_NOW: "studies now",
  NO_FILTERS_ADDED:". No custom filters added.",
  SCROLL_END_MSG: "Yay! You have seen it all",
  LOADING: "Loading...",
  SEX_OPTIONS: ['Male','Female','Other'],
  FILTERED_SAMPLES: "Filtered studies",
  AGE: "Age",
  WEIGHT: "Weight",
  SAMPLE_SIZE: "Sample Size",
  ACQUISITION_DATE: "Acquisition Date",
  OPERATOR_OPTIONS:[
    { value: "over", label: "Over" },
    { value: "between", label: "Between" },
    { value: "under", label: "Under" },
  ],
  OPERATOR_INC_EXC_OPT:[
    { value: "include", label: "Include" },
    { value: "exclude", label: "Exclude" },
  ],
  SEX:"Sex",
  EXPERIMENTAL_COND: "Experimental Condition",
  INSTRUMENT: "Instrument",
  OTHER_DIAGNOSES: "Other Diagnoses",
  PRIMARY_DIAGNOSIS: "Primary Diagnosis",
  PARTICIPANT_GROUPS: "Participant Groups",
  RELEASE_VERSION: "Release Version",
  CUSTOM_FILTER_OPTIONS:[
    { value: "Age", label: "Age" },
    { value: "Weight", label: "Weight" },
    { value: "Sex", label: "Sex" },
    { value: "Acquisition Date", label: "Acquisition Date" },
    { value: "Data Modalities", label: "Data Modalities" },
    { value: "Participant Groups", label: "Participant Groups" },
    { value: "Timelines", label: "Timelines" },
    { value: "Release Version", label: "Release Version" },
    { value: "Primary Diagnosis", label: "Primary Diagnosis" },
    { value: "Other Diagnoses", label: "Other Diagnoses" },
    { value: "Instrument", label: "Instrument" },
    {
      value: "Experimental condition",
      label: "Experimental condition",
    },
  ],
  SELECT_FILTER_TOPIC: "Select Filter",
  ANOTHER_FILTER: "Another Filter",
  DATE_RANGE: "Daterange",
  UPDATE: "Update",
  STUDY_ADDED_COHORT: "Study added to cohort(s)",
  COHORT_NAME:"Cohort Name",
  ENTER_COHORT_NAME:"Enter Cohort Name",
  CREATE_NEW_COHORT:"Create new Cohort"
};

export const detailedStudyText = {
  STUDY_DETAILS: "Study Details",
  READ_MORE: "...Read More",
  READ_LESS: "Read Less",
  DATA_FORMATS: "Data Formats",
  SAMPLE_SIZE: "Sample Size",
  TOTAL_BYTES: "Total Bytes",
  ONGOING: "On Going",
  PENDING: "Pending"
};

export const detailedStudyVideoText ={
  EXCLUSION_CRITERIA: "Exclusion Criteria",
  INCLUSION_CRITERIA: "Inclusion Criteria",
  VIDEO_LINK: "Video Link",
  PUBLICATIONS: "Publications",
  PARTICIPANT_GROUPS: "Participant Groups",
  SUB_PER_GRP_CNT:"Subjects per group count",
  NO_DATA: "No Data",
  DEFAULT_VIDEO_LINK:"https://www.youtube.com/embed/"
};

export const statisticsText = {
  GRP: "Group",
  ADD_TO_BASKET: "Proceed to Request",
  CHART_OPTIONS:{
    layout: {
      padding: {
        left: 2
      }
    },
    scales:{
      y:{
        ticks: {
          mirror: true,
          padding: 2
        },
      }
    },
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio:false,
    aspectRatio: 2,
     plugins: {
      legend: {
        display: false,
      },
    }, 
  },
  BAR:"bar",
  chartBgColour: "rgba(255, 99, 132, 0.5)",
  barColor: "rgb(255, 99, 132)",
  MODALITY: "Modality",
  PARTICIPANTS_BY_NUMERICAL: "Demographics (numerical)",
  AGE: "Age",
  MIN: "Min",
  MAX:"Max",
  AVG:"Avg",
  SD:"SD",
  WEIGHT:"Weight",
  GRPS_TIMELINES:"Groups and Timelines",
  CLINICAL_MODALITY_OPTIONS: [
    { value: "timelines", label: "Participants per timelines" },
    { value: "group", label: "Participants per subject group" },
  ],
  EXP_COND:"Experimental Condition",
  DATA_MOD:"Data Modalities",
  COHORT_INSTR:"Cohort Instruments",
  TIMELINES_PARTICIPANTS:"Participants per timelines",
  GROUP_PARTICIPANTS:"Participants per subject group",
  FILTER_NAMES: {
     samplesize: "Sample Size",
     age: "Age",
     datamodalities: "Data Modalities",
     acquisitiondate: "Acquisition Date",
     participantgroups: "Participant Groups",
     releaseversion: "Release Version",
     primarydiagnosis: "Primary Diagnosis",
     otherdiagnoses: "Other Diagnoses",
     experimentalcondition: "Experimental Condition",
     instrument: "Instrument",
     timelines: "Timelines",
     sex: "Sex",
     weight: "Weight"
  },
};

export const drftReqText = { 
  FILTERED: "Filtered",
  SAMPLES:"samples",
  RETRIEVE_COHORT:"Retrieve Cohort",
  REQUEST:"Request",
  NO_REQ: "No requests added in basket",
  REQ_DESC: "Request Description",
  REQ_TITLE: "Request Title",
  DESC_REQ: "Describe the request",
  USE_DATA: "How will you use the data ?",
  OBI_COMPUTE:"Would you like to use OBI analytics computing for this project?",
  OBI_TERMS: "I agree to OBI's ",
}

export const admReqTxt = {
  ADM_REQ_STATUS_OPTIONS: [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "review", label: "Review" },
    { value: "rejected", label: "Rejected" },
  ],
}