import React,{useEffect} from "react";
import SideBar from "../components/SideBar";
import AdmReqDetail from "../components/AdmReqDetail";
import { Row, Col, Divider } from "antd";
import "../styles/PageStyle.css"
import { Navigate, Route } from 'react-router-dom'

export default function AdmFileDetails(props) {

  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('authenticated'));
  useEffect(() => {
    setIsAuth(localStorage.getItem('authenticated'))
  }, [localStorage.getItem('authenticated')])
  return (
    <div>       
    {isAuth === "true" ? 
    (<div>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle" style={{border:"none"}}>
            <SideBar pgName={{page:"requests"}}></SideBar>
          </div>
          
        </Col>
        <Col xs={1} xl={1} style={{flexBasis:"0%"}}><Divider type="vertical" style={{ background: "#E8E8E8 0% 0% no-repeat padding-box", margin:"0px" }} /></Col>
        <Col xs={20} xl={20}>
          <div className="contentStyle" style={{border:"none"}}>
            <AdmReqDetail></AdmReqDetail>
          </div>
        </Col>
      </Row>
    </div> ):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
