export const constantValues={
    SUCCESS_SAVE_MSG: "Saved successfully",
    SUCCESS_SEND_MSG: "Request sent successfully",
    ERROR: "error",
    SUCCESS_FILE_UPLOAD_MSG: "File uploaded successfully",
    SUCCESS_FILE_UPDATE_MSG: "File updated successfully",
    DESCRIPTION: "description",
    SAMPLES: "samples",
    SUBJECTS: "participants",
    DONE: "done",
    BETWEEN: "between",
    PLEASE_SELECT: "Please select",
    NO_FILTER_ADDED: "No filter added",
    APPLY: "Apply",
    CLEAR: "Clear",
    SEARCH: "Search",   
    YYYMMDD: "YYYY-MM-DD" ,
    NUMBER: "number",
    MULTIPLE: "multiple",
    PRIMARY: "primary",
    AND: "and",
    TOP_RIGHT: "topRight",
    ERR_MSG: "Something went wrong, Try Again",
    VERSION: "Version",
    UPDATE: "Update",
    YEAR: "year",
    SAVE: "Save",
    Y:"y",
    ITEMS: "items",
    ITEM: "item",
    CREATE:"Create",
    PDF_FILE_ERROR: "Please upload PDF file",
    EXISTING_FILE_NAME:"File with the same name already exists. Do you want to replace it ?"
}