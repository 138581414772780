import React from "react";
import SideBarComp from "../../components/SideBar";
import SideBarUser from "../../components/sideBarUser";
import DetailedStudyComp from "../../components/DetailedStudy";
import { Row, Col, Space, Image } from "antd";
import "../../styles/PageStyle.css"
import {useLocation, useNavigate} from 'react-router-dom';
import "../../styles/CommonStyles.css";
import SideBarStyle from "../../styles/SideBar"
import { ProfileOutlined } from "@ant-design/icons";
import { sideBarText } from "../../constants/displayText";
import { pageLinks, urls } from "../../constants/urls";


export default function DetailedStudyPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole, setuserRole] = React.useState(localStorage.getItem('role'));
  const [cohortList, setCohortList] = React.useState(JSON.parse(localStorage.getItem('cohList')));
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('authenticated'));

  const getCohortDetails = (param) => {
    setCohortList(param)
 }

 const onImage = () => {
  navigate('/')
}

  return (
    <div> {/* {isAuth  ?   */}       
    <div>      
       <Row>
       {userRole === 'admin' ? 
        <Col xs={3} xl={3}>
          <div className="siderStyle" style={{height:"120%"}}>
            <SideBarComp pgName={{ page: "browseStudies" }}></SideBarComp>
          </div>
        </Col> : (userRole === 'user' ? <Col xs={3} xl={3}>
          <div className="siderStyle" style={{height:"120%"}}>
            <SideBarUser cohortList={{cohort: cohortList}} pgName={{ page: "browseStudies" }}></SideBarUser>
          </div>
        </Col>: 
                <Col xs={3} xl={3}>
                <div className="siderStyle" style={{ height: "120%" }}>
                  <Image style={{...SideBarStyle.logoStyle, cursor: "pointer" }} preview={false} src={process.env.PUBLIC_URL+"/static/media/logo.png"} alt="Brain Logo" onClick={onImage}/>
                  <div style={SideBarStyle.eachMenuStyle}>
                    <Space>
                      <ProfileOutlined style={SideBarStyle.iconStyle} />
                    </Space>
                    <a href={pageLinks.EXPLORE_STUDY} style={SideBarStyle.menuStyle}>
                      {sideBarText.BROWSE_STUDIES}{" "}
                    </a>
                  </div>
                </div>
              </Col>)}
        <Col xs={21} xl={21}>
          <div className="height120 contentStyle">
            <DetailedStudyComp  getCohort={getCohortDetails} ></DetailedStudyComp>
          </div>
        </Col>
      </Row> 
    </div>{/*  :(<Navigate to={{ pathname: '/' }} />)} */}
    </div>
  );
}
