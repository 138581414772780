import React from "react";
import moment from "moment";
import {
  Row,
  Col,
  Select,
  Button,
  Divider,
  Input,
  Modal,
  DatePicker,
  Table,
  message,
  Checkbox,
  Spin
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import axios from "axios";
import {
  CloseOutlined,
  MessageOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  viewStudyText,
  admReqTxt,
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Search } = Input;

const { TextArea } = Input;

export default function AdmReq(props) {
  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [respData, setRespData] = React.useState();
  const [respTblValues, setRespTblValues] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [dateRange, setDateRange] = React.useState("");
  const [selectStat, setSelectStat] = React.useState("");
  const [pageNum, setPageNum] = React.useState(0);
  const [moreData, setMoreData] = React.useState(true);
  const [filteredCnt, setFilteredCnt] = React.useState(0);
  const [totalCnt, setTotalCnt] = React.useState(0);
  const [isDecisionOpen, setIsDecisionOpen] = React.useState(false);
  const [decisionText, setDecisionText] = React.useState("");
  const [statusUpd, setStatusUpd] = React.useState(false);
  const [updStatusName, setUpdStatusName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    apiCall();
    if (props && props.reqUpd && props.reqUpd.status) {
      setStatusUpd(true);
      setUpdStatusName(props.reqUpd.reqTitle);
    }
  }, []);

  const decisionClick = (event, text) => {
    event.stopPropagation();
    setDecisionText(text);
    setIsDecisionOpen(true);
  };

  const onCloseDecision = () => {
    setIsDecisionOpen(false);
  };

  const apiCall = (searchValue, dateFilter, selectedStatus, fetchData) => {
    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: {},
          };
          config.params.search =
            searchValue !== null && searchValue !== undefined
              ? searchValue
              : searchText !== ""
                ? searchText
                : null;
          config.params.daterange =
            dateFilter !== null && dateFilter !== undefined
              ? dateFilter
              : dateRange !== ""
                ? dateRange
                : null;
          config.params.req_status =
            selectedStatus !== null && selectedStatus !== undefined
              ? selectedStatus
              : selectStat !== ""
                ? selectStat
                : null;
          if (fetchData) {
            config.params.page_num = pageNum;
          } else {
            config.params.page_num = 0;
            setPageNum(0);
          }

          axios.get(`${apiUrl}/${urls.ADM_REQ_URL}`, config)
          .then((response) => {
            if (response.status === 200) {
              setPageNum(pageNum + 1);
              setRespData(response.data);
              if (response.data.data.length > 0) {
                setMoreData(true);
                let respTblVal = response?.data?.data?.map((i) => {
                  let dateTime = Object.values(i?.req_time)[0]
                  let date = dateTime.split('T')
                  i.reqDate =
                    i.req_time &&
                    moment(date[0]).format("YYYY-MM-DD");
                  i.decisionNote = i.decision_note;
                  i.status = i.status.charAt(0).toUpperCase() + i.status.slice(1);
                  return i;
                });
                setFilteredCnt(response.data.filtered_count);
                setTotalCnt(response.data.total);
                setRespTblValues(respTblVal);
                if (fetchData) {
                  let addedValues = respTblValues.concat(respTblVal);
                  setRespTblValues(addedValues);
                }
                else {
                  setPageNum(1)
                  setRespTblValues(respTblVal)
                }
                if (fetchData && (respTblValues.length + response.data.data.length) === response.data.total) {
                  setMoreData(false);
                }
                else if (!fetchData && response.data.data.length === response.data.filtered_count) {
                  setMoreData(false);
                }
              } else {
                setMoreData(false);
                setFilteredCnt(response.data.filtered_count);
                setTotalCnt(response.data.total);
                setRespTblValues([]);
              }
            }
            else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
        })
        .catch((e) => {
          localStorage.setItem('page', window.location.href)
          instance.loginRedirect(accessTokenRequest).catch((e) => {
            console.log(e);
          });
          localStorage.setItem("session", true) 
         })
    }
    catch (error) {

    }
  };

  const updateRequest = async (dta, req, reqId) => {

    try {
      instance
        .acquireTokenSilent(tokRequest)
        .then((response) => {
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: {},
          };

          let data = {
            dta_executed: dta,
            request_fullfilled: req
          }
          setLoading(true)
          axios.put(`${apiUrl}/admin/update-request?request_id=${reqId}`, data, config).then((response) => {
            if (response.status === 200) {
              apiCall()
            }
            setLoading(false)
          })
        })
        .catch((e) => {
          navigate('/')
         })
    }
    catch (error) {

    }

  }

  const columns = [
    {
      title: "Request Id",
      dataIndex: "_id",
      key: "_id",
      render: (text) =>
        <span>
          {Object.values(text)[0]}
        </span>
    },
    {
      title: "Request Title",
      dataIndex: "req_title",
      key: "req_title",
    },
    {
      title: "Requester ",
      dataIndex: "requester",
      key: "requester",
    },
    {
      title: "Requester Name",
      dataIndex: "requester_name",
      key: "requester_name",
    },
    {
      title: "Request Date",
      dataIndex: "reqDate",
      key: "reqDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        <span style={{
          textWrap: "noWrap", textTransform: "capitalize", padding: "3% 3% 3% 3%", background: text === 'Approved' ?
            "#F6FFED" : text === "Rejected" ? "#FFF2E8" : text === "Pending" ?
              "#D2CECE80" : "#FDBCA18A", color: text === 'Approved' ?
                "#006633" : text === "Rejected" ? "#FF0000" : text === "Pending" ?
                  "#808080" : "#FF8000", borderRadius: "2px",
          border: text === 'Approved' ?
            "1px solid #B7EB8F" : text === "Rejected" ? "1px solid #FFBB96" : text === "Pending" ?
              "1px solid #80808080" : "1px solid #FF4A0080"
        }}>
          {text}
        </span>
    },
    {
      title: "Decision Note",
      dataIndex: "decisionNote",
      key: "decisionNote",
      render: (text) =>
        text !== null && text !== undefined && text !== "" ? (
          <Button className="border-0 bg-transparent">
            <MessageOutlined onClick={(event) => decisionClick(event, text)} />
          </Button>
        ) : null,
    },
    {
      title: "DTA Executed",
      dataIndex: "dta_executed",
      key: "dta_executed",
      render: (text, row) => <Checkbox defaultChecked={row.dta_executed} onClick={(event) => {
        event.stopPropagation();
        let req = row && row.request_fullfilled
        if (req === null || req === undefined) {
          req = false
        }
        updateRequest(event.target.checked, req, Object.values(row._id)[0])
      }} />
    },
    {
      title: "Requests Fulfilled",
      dataIndex: "request_fullfilled",
      key: "request_fullfilled",
      render: (text, row) => <Checkbox defaultChecked={row.request_fullfilled} onClick={(event) => {
        event.stopPropagation();
        let dta = row && row.dta_executed
        if (dta === null || dta === undefined) {
          dta = false
        }
        updateRequest(dta, event.target.checked, Object.values(row._id)[0])
      }} />
    }
  ];

  const onSearch = (value) => {
    setSearchText(value);
    apiCall(value);
  };

  const handleDateRange = (date, dateStr) => {
    let dateFilter = "";
    if (dateStr[0] !== "" && dateStr[1] !== "") {
      dateFilter = "between^" + dateStr[0] + "^" + dateStr[1];
      setDateRange(dateFilter);
    } else {
      setDateRange("");
    }
    apiCall(null, dateFilter);
  };

  const fetchData = () => {
    apiCall(null, null, null, true);
  };

  const handleStatusChange = (value) => {
    let selectedStatus = value.join("^");
    setSelectStat(selectedStatus);
    apiCall(null, null, selectedStatus);
  };

  return (
    <div>
      <Col xs={24} xl={24}>
        <Row>
          <Col xs={20} xl={20}>
            <div className="headerLineStyle b-0">
              <span className="headerSpanStyle">
                <Search
                  className="searchStyle"
                  placeholder={constantValues.SEARCH}
                  onSearch={onSearch}
                  enterButton
                />
              </span>
              <span className="headerSpanStyle">
                <RangePicker
                  picker={constantValues.DATE}
                  onChange={(date, dateStr) => handleDateRange(date, dateStr)}
                />
              </span>
            </div>
          </Col>
          <Col xs={4} xl={4}>
            <div className="marginTop10" style={{ height: "10%" }}>
              <Select
                mode={constantValues.MULTIPLE}
                allowClear
                placeholder="Select Status"
                options={admReqTxt.ADM_REQ_STATUS_OPTIONS}
                className="expCondStyle"
                style={{ width: "50%" }}
                onChange={(e) => handleStatusChange(e)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div style={{ marginLeft: "1%" }}>
            {" "}
            <span className="showingTextStyle">
              {viewStudyText.SHOWING}:
            </span>{" "}
            <span
              style={{
                textAlign: "left",
                font: "normal normal normal 14px/22px Arial",
                letterSpacing: "0px",
                color: "#8C8C8C",
              }}
            >
              {filteredCnt}/{totalCnt} requests.{" "}
            </span>
            {dateRange === "" && selectStat === "" ? (
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 14px/22px Arial",
                  letterSpacing: "0px",
                  color: "#8C8C8C",
                }}
              >
                No custom filters added
              </span>
            ) : (
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 14px/22px Arial",
                  letterSpacing: "0px",
                  color: "#8C8C8C",
                }}
              >
                {dateRange !== "" ? (
                  <span>
                    Date Range{" "}
                    {dateRange
                      .replace("between^", "between ")
                      .replace("^", " and ")} ,
                  </span>
                ) : (
                  null
                )}
                {selectStat !== "" ? (
                  <span> Status: {selectStat.replaceAll("^", ", ")}</span>) : null}
              </span>
            )}
          </div>
        </Row>
        <Divider className="dividerStyle"> </Divider>
        {statusUpd ? (
          <Row>
            <div
              style={{
                background: "#F6FFED 0% 0% no-repeat padding-box",
                border: "1px solid #B7EB8F",
                borderRadius: "2px",
                width: "100%",
                paddingTop: "0.4%"
              }}
            ><Row>
                <Col xs={23} xl={23}>
                  <span>
                    {" "}
                    <CheckCircleFilled
                      style={{
                        color: "#0dbd0d",
                        paddingLeft: "0.5%",
                        paddingRight: "0.5%",
                      }}
                    />{" "}
                    {updStatusName} : Status Updated
                  </span>
                </Col>
                <Col xs={1} xl={1}>
                  <span >
                    <Button
                      style={{ border: "none", background: "none" }}
                      onClick={() => {
                        setStatusUpd(false);
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </span>
                </Col>
              </Row>
            </div>
          </Row>
        ) : null}
        <Row>
          <Col xs={24} xl={24}>
            <InfiniteScroll
              dataLength={respTblValues.length}
              next={fetchData}
              hasMore={moreData}
              loader={
                <h4 className="centreText">{viewStudyText.LOADING}</h4>
              }
              endMessage={null}
            >
              <Row>
                <Table
                  columns={columns}
                  dataSource={respTblValues}
                  pagination={false}
                  headerSpanStyle={{ background: "#0065A4" }}
                  style={{ width: "100%" }}
                  onRow={(r) => ({
                    onClick: () =>
                      navigate(
                        `${pageLinks.ADMIN_REQUEST}/${Object.values(r._id)}`
                      ),
                  })}
                />
              </Row>
            </InfiniteScroll>
          </Col>
        </Row>
        <Modal
          closable={false}
          width={600}
          centered={true}
          footer={null}
          title={
            <div>
              <Col xs={24} xl={24}>
                <Row>
                  <Col xs={20} xl={20}>
                    <div>
                      <span className="filterSampleHdStyle">
                        Decision note
                      </span>
                    </div>
                  </Col>
                  <Col xs={4} xl={4}>
                    <Button
                      onClick={onCloseDecision}
                      style={{ border: "none" }}
                    >
                      {" "}
                      <CloseOutlined />{" "}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          }
          open={isDecisionOpen}
        >
          {" "}
          <div>
            <span
              style={{ width: "75%" }}
            >
              {" "}
              {decisionText}{" "}
            </span>
          </div>
          <Divider />
        </Modal>
        <Modal
          closable={false}
          width={null}
          centered={true}
          footer={null}
          title={null}
          open={loading}
        >
            <Spin />
        </Modal>
      </Col>
    </div>
  );
}
