import React from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Modal,
  message,
  Spin,
  Radio,
} from "antd";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import {
  statisticsText
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate, useParams } from "react-router-dom";
import ReqDetailComponent from "./ReqDetailComponent"
import DacSteeringLogin from "./DacSteeringLogin"

const apiUrl = process.env.REACT_APP_API_URL;
const { TextArea } = Input;

export default function DacReqDetail() {

  const { instance, accounts } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  const navigate = useNavigate();
  const params = useParams()
  const [loading, setLoading] = React.useState(false);
  const [dacQuestions, setDacQuestions] = React.useState([]);
  const [mailVerified, setMailVerified] = React.useState(true);
  const [emailId, setEmailId] = React.useState("");

  React.useEffect(() => {
    let query = {
      request_id: params.id,
    };
    let config = {
      params: query,
    };
    setLoading(true)
    axios.get(`${apiUrl}/${urls.DAC_REQ_DETAILS}`, config)
    .then((response) => {
      setLoading(false)
      if (response.status === 200) {            
        setReqData(response.data.request_data);
        if (response.data.user_data) {
          setUserDet(response.data.user_data)
        }
      }
      else {
        message.error(`${constantValues.ERR_MSG}`);
      }
    })
    .catch(() =>{
      console.log("error")
    })
  fetchMemberQues()
  }, [])

  const fetchMemberQues = async () => {

    const response = await axios.get(`${apiUrl}/user/get-questions/${params.id}/${params.userId}`)
    setDacQuestions(response.data)
    await fetchAnswers(response.data)
  }

  const fetchAnswers = (dacQues) => {
    try {
    axios.get(`${apiUrl}/user/get-dac-answer/${params.id}/${params.userId}`)
    .then((response) => {
      if(response && response.data && response.data.dac_comments && response.data.dac_comments.length > 0) {
        const updatedDacQuestions = dacQues.map((question, index) => {
        return { ...question, answer: response.data.dac_comments[index].answer };
      });
      setDacQuestions(updatedDacQuestions)
    }
    })
    .catch(() =>{
      console.log("error")
    })
  }
  
  catch (error) {

  }
  }

  const onRadioChange = (e, index) => {
    const updatedDacQuestions = [...dacQuestions]; // Create a new array reference
    updatedDacQuestions[index] = {
      ...updatedDacQuestions[index],
      answer: e.target.value,
    };
  
    setDacQuestions(updatedDacQuestions);
  }

  const onTextChange = (e, index) => {
    const updatedDacQuestions = [...dacQuestions]; // Create a new array reference
    updatedDacQuestions[index] = {
      ...updatedDacQuestions[index],
      answer: e.target.value,
    };
  
    setDacQuestions(updatedDacQuestions);
  }

  const onSubmitReview = () => {
    let payload= { 
      dac_type: "",
      dac_user_id: params.userId,
      dac_comments: dacQuestions
    }

    axios.put(`${apiUrl}/user/submit-dac-comments/${params.id}`, payload)
    .then((response) => {
      if(response.status === 200) {
        message.success("DAC comments submitted successfully")
        navigate('/')
      }
      else {
        message.error("Could not submit DAC comments")
      }
    })
    .catch(() =>{
      console.log("error")
    })
  }

  const verifyMail = () => {
    try {
      axios.get(`${apiUrl}/user/verify-email?request_id=${params.id}&user_id=${params.userId}&email=${emailId}&member_type=DAC`)
      .then((response) => {
        if(response && response.data && response.data.verify_email === true) {
           setMailVerified(false)
        }  
        else if(response && response.data && response.data.verify_email === false) {
          message.error("Please enter valid email id")
         }       
      })
      .catch(() =>{
        console.log("error")
      })
    }
    catch (error) {
      message.error("Please enter valid email id")
    }
  }

  const [reqData, setReqData] = React.useState();
  const [userDet, setUserDet] = React.useState();

  return (
    <div>
      <Col xs={24} xl={24}>
        {!mailVerified && reqData  && reqData.dac_link === true ? 
        <div>
          <Row>
            <Col xs={12} xl={12}>
              <div className="titleStyle pt-2"
              >
                <span> {reqData && reqData.req_title} </span>
              </div>
            </Col>

         </Row>
          <Divider className="dividerStyle m-0"> </Divider>
          {/* <DacSteeringLogin /> */}
          <Row>
            <Col span={9}>
            <div className="mt-2 ml-3">
              {dacQuestions.map((i, index) => (
                <div key={i.id} style={{ marginBottom: "2%" }}>
                  <div>{i.order}.{i.question}</div>
                  <div>{i.question_type === 'radio' ? (
                    <Radio.Group value={i.answer} onChange={(e) => onRadioChange(e,index)}>
                      {i.options.map((o) =>
                        <Radio value={o} style={{textTransform:"capitalize"}}>{o}</Radio>)}
                    </Radio.Group>
                  ) : (
                    <TextArea rows={5} onChange={(e) =>  onTextChange(e, index)} value={i.answer}/>
                  )}</div>
                </div>
              ))}
              <div className="mr-3" style={{display:"flex", justifyContent:"end"}}>
                <Button type="primary" onClick={onSubmitReview}>Submit</Button></div>
            </div>
            </Col>
            <Col span={15}>
            <ReqDetailComponent userDet={userDet} reqData={reqData} id={params.id} />
            </Col>
          </Row>
          
        </div> :
        <div style={{textAlign:"center"}}><h4>The link is closed</h4></div>
        }

        <Modal
          width="30%"
          // centered={true}
          footer={null}
          title="Email Id verification"
          closable={false}
          open={mailVerified}
        >
          <div>
              <div><Input type="email" placeholder="Enter Email id" onChange={(e) => setEmailId(e.target.value)}/></div>
              <div style={{marginTop:"2%", justifyContent:"end", display:"flex"}}>
                <Button type="primary" onClick={verifyMail}>Verify Email Id</Button></div>
            </div>
        </Modal>
        <Modal
          closable={false}
          width={null}
          centered={true}
          footer={null}
          title={null}
          open={loading}
        >
          <Spin />
        </Modal>
      </Col>
    </div>
  );
}
