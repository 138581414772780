import React from "react";
import SideBarComp from "../components/sideBarUser";
import Statistics from "../components/Statistics";
import { Row, Col } from "antd";
import "../styles/PageStyle.css"
import {useLocation, Navigate} from 'react-router-dom';

export default function MyCohort(props) {
  const location = useLocation();
  React.useEffect(() =>{
    if(location && location.state && location.state.details){
      setCohortId(Object.values(location.state.details.selectedCohort.id))
      setSelectedCohort(location.state.details.selectedCohort)
    } 
   
  },[location])
  const [cohortList, setCohortList] = React.useState(JSON.parse(localStorage.getItem('cohList')));
  const [cohortId, setCohortId] = React.useState('');
  const [selectedCohort, setSelectedCohort] = React.useState();
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('role'));

  const getCohortDetails = (param) => {
    setCohortList(param)
 }
  
  return (
    <div>{isAuth ? 
      (
    <div style={{height:"100%vh"}}>
      <Row>
        <Col xs={3} xl={3}>
          <div className="siderStyle">
            <SideBarComp cohortList={{cohort: cohortList}} pgName={{page:`my-cohorts`}} getCohort={getCohortDetails}></SideBarComp>
          </div>
        </Col>
        <Col xs={21} xl={21}>
          <div className="contentStyle">
            <Statistics sltdCohort={{slctedCohort: selectedCohort}} getCohort={getCohortDetails}></Statistics>
          </div>
          
        </Col>
      </Row>
    </div> ):(<Navigate to={{ pathname: '/' }} />)}
    </div>
  );
}
