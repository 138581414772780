import React, { useState } from "react";
import { Button, Tabs, message } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function ReviewComments(props) {

  const apiUrl = process.env.REACT_APP_API_URL;
  const params = useParams()

  const userType = localStorage.getItem("userType")

  React.useEffect(() => {
    if(params.requestId !== null && params.requestId !== undefined) {
      setReqId(params.requestId)
      setUserId(params.userId)
      fetchComments(params.requestId, params.userId)
    }
    else {
      setReqId(props.reqId)
      setUserId(props.userId)
      fetchComments(props.requestId, props.userId)
    }

  }, [props.reqId, props.userId, params.requestId, params.userId]);

  const { TabPane } = Tabs
  const previousPage = localStorage.getItem("prev-page")
  const [comments, setComments] = useState()
  const [reqId, setReqId] = useState("")
  const [userId, setUserId] = useState("")

  const fetchComments = (request, user) => {
    axios.get(`${apiUrl}/user/fetch-comments?request_id=${request}&user_id=${user}`).then((response) => {
      setComments(response.data)
    })
    .catch(() =>{
      console.log("error")
    })
  }

  const onLinkClick = () => {
    axios.put(`${apiUrl}/user/dac-link?request_id=${reqId}`).then((response) => {
      message.success("Link status is updated successfully")
      fetchComments(reqId,userId)
    })
    .catch(() =>{
      console.log("error")
    })
  }

  const onDacDelete = (userId) => {
    axios.delete(`${apiUrl}/user/delete-dac-comments/${userId}?request_id=${reqId}`).then((response) => {
      message.success("Comments deleted successfully")
      fetchComments(reqId,userId)
    })
    .catch(() =>{
      console.log("error")
    })
  }

  const onScDelete = (userId) => {
    console.log("userId", userId)
    axios.delete(`${apiUrl}/user/delete-sc-comments/${userId}?request_id=${reqId}`).then((response) => {
      message.success("Comments deleted successfully")
      fetchComments(reqId,userId)
    })
  }

  const onScLinkClick = () => {
    axios.put(`${apiUrl}/user/toggle-sc-link?request_id=${reqId}`).then((response) => {
      message.success("Link status is updated successfully")
      fetchComments(reqId,userId)
    })
    .catch(() =>{
      console.log("error")
    })
  }

  return (
    <div style={{ height: "90vh", overflowY: "auto", color: "#000000", paddingLeft: "2%", paddingRight: "2%" }}>
      { props.steering === true? null : <div style={{ marginTop: "2%" }}>
        <a href={previousPage}>Go Back to requests</a>
      </div> }
      <Tabs>
        <TabPane key="1" tab="DAC Reviews">
          <div><div style={{ textAlign: "right" }}>
          {comments && comments.admin_member === true ?
            <Button type="primary" style={{ marginBottom: "2%" }} onClick={onLinkClick}>
              {comments && comments.dac_link === true ? "Close DAC link" : "Open DAC link"}</Button> : null}
          </div>
            <div style={{ marginBottom: "4%" }}>
              {comments && comments.dac_comments.map((i) => (
                <div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div><b>Reviewer name : </b>{i.user_email}</div>
                    {comments && comments.admin_member === true ?<div onClick={() => onDacDelete(i.dac_user_id)}>
                        <DeleteOutlined /></div> : null}
                    </div>
                  {i.dac_comments.map((j, index) => (
                    <div>
                      <div><b>Q{index + 1}. {j.question} </b></div>
                      <div style={{ marginBottom: "1%" }}>{j.answer} </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
            
        </TabPane>
        {comments && comments.admin_member === true ?
        <TabPane key="2" tab="SC Reviews">
           <div><div style={{ textAlign: "right" }}>
            <Button type="primary" style={{ marginBottom: "2%" }} onClick={onScLinkClick}>
              {comments && comments.sc_link === true ? "Close SC link" : "Open SC link"}</Button></div>
            {comments && comments.sc_comments.map((i) => (
              <div style={{ marginBottom: "4%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}><div><b>Reviewer name :</b>{i.user_email}</div>
                   <div  onClick={() => onScDelete(i.user_id)}><DeleteOutlined /></div></div>
                <div><b>Recommendations : </b>{i.release_status === true ? "Recommends release of data" : "Do not recommend release of data"}</div>
                <div><b>Comments: </b>{i.comments}</div>
              </div>
            ))}</div>
        </TabPane>
      : null}
      </Tabs>
    </div>

  );
}
