// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .breakWordWrap { word-wrap: break-word }
  .width42{ width:42%}
  .height60 { height:60px}
  .centreText { text-align: centre }
  .height120 { height:120% }
  .hw100 {height: 100%; width: 100% }
  .marginLeft3 { margin-left: 3% }
  .marginLeft40 { margin-left: 40% }
  .paddingTop2 {padding-top: 2%}
  .noBorder {border:none !important}
  .height100{height:100px}`, "",{"version":3,"sources":["webpack://./src/styles/CommonStyles.css"],"names":[],"mappings":"EAAE,iBAAiB,sBAAsB;EACvC,UAAU,SAAS;EACnB,YAAY,WAAW;EACvB,cAAc,mBAAmB;EACjC,aAAa,YAAY;EACzB,QAAQ,YAAY,EAAE,YAAY;EAClC,eAAe,gBAAgB;EAC/B,gBAAgB,iBAAiB;EACjC,cAAc,eAAe;EAC7B,WAAW,sBAAsB;EACjC,WAAW,YAAY","sourcesContent":["  .breakWordWrap { word-wrap: break-word }\n  .width42{ width:42%}\n  .height60 { height:60px}\n  .centreText { text-align: centre }\n  .height120 { height:120% }\n  .hw100 {height: 100%; width: 100% }\n  .marginLeft3 { margin-left: 3% }\n  .marginLeft40 { margin-left: 40% }\n  .paddingTop2 {padding-top: 2%}\n  .noBorder {border:none !important}\n  .height100{height:100px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
