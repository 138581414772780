import React from "react";
import {
  InboxOutlined,
  ShoppingOutlined,
  ProfileOutlined,
  LikeOutlined,
  LogoutOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
  QuestionOutlined
} from "@ant-design/icons";
import { sideBarText } from "../constants/displayText";
import { Space, Image, Menu, Button, Modal } from "antd";
import SideBarStyle from "../styles/SideBar";
import { pageLinks, urls } from "../constants/urls";
import { useMsal } from "@azure/msal-react";
import { BrowserRouter as Router, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { accessTokenRequest } from "../authConfig";

const { confirm } = Modal;

const { SubMenu } = Menu;

export default function SideBarUser(props) {
  const { instance, accounts, inProgress, idToken } = useMsal();

  const apiUrl = process.env.REACT_APP_API_URL;
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };
  React.useEffect(() => {
    if (props && props.cohortList && props.cohortList.cohort.length > 0) {
      let activeCohorts = props.cohortList.cohort.filter(
        (i) => i.in_request_basket === false
      );
      setCohList(activeCohorts);

      let drftReq = props.cohortList.cohort.filter(
        (i) => i.in_request_basket === true
      );
      if (drftReq.length > 0) {
        setdrftReq(true);
      }
    } else {
      let cohortList = JSON.parse(localStorage.getItem("cohList"));
      if (cohortList && cohortList.length > 0) {
        let activeCohorts = cohortList.filter(
          (i) => i.in_request_basket === false
        );
        setCohList(activeCohorts);
        let drftReq = cohortList.filter((i) => i.in_request_basket === true);
        if (drftReq.length > 0) {
          setdrftReq(true);
        }
      }
    }
  }, [props, localStorage.getItem("cohList")]);

  const [cohList, setCohList] = React.useState([]);
  const [drftReq, setdrftReq] = React.useState(false);

  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    localStorage.setItem("session", true)
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/", 
    });
  };

  function cohortClick(cohort) {
    navigate(`${pageLinks.MY_COHORTS}/${Object.values(cohort.id)}`);
  }

  const onImage = () => {
    navigate(`${pageLinks.VW_STUDY}`);
  };

  const deleteCohort = (cohort) => {    
      confirm({
        title: "Delete Cohort",
        icon: <ExclamationCircleFilled />,
        content: `Are you sure want to delete Cohort ${cohort.name} ?`,
        onOk() {
          instance
          .acquireTokenSilent(tokRequest)
          .then((response) => {
          let query = {
            cohort_id: Object.values(cohort.id)[0],
            user_id: localStorage.getItem('userObjId')
          };
          let config = {
            headers: { Authorization: `Bearer ${response.accessToken}` },
            params: query,
          };
            axios.delete(`${apiUrl}${urls.DELETE_COHORT}`, config).then((response) => {
              if(response.status === 200){
               setCohList(response.data.cohort_list)
               localStorage.setItem("cohList", JSON.stringify(response.data.cohort_list))
               props.getCohort([...response.data.cohort_list]);
              }
            })
            .catch(() =>{
              console.log("error")
            })
          })
          .catch((e) => {
            navigate('/')
           })
        },
        onCancel() {},
        width: 700,
        centered: true,
      });
}

  return (
    <div>
      <Image
        style={{...SideBarStyle.logoStyle, cursor: "pointer" }}
        preview={false}
        src={process.env.PUBLIC_URL + "/static/media/logo.png"}
        alt="Brain Logo"
        onClick={onImage}
      />
      <div style={{ paddingLeft: "1.2%", paddingBottom:"6%" }}>
        <a
          href={urls.VIEW_STUDY}
          style={{
            font: "normal normal normal 14px/24px Arial",
            color:
              props.pgName && props.pgName.page === "browseStudies"
                ? "#0065A4"
                : "#595959",
            paddingTop: "5%",
            paddingBottom: "5%",
            borderRadius: "8px",
          }}
        >
          <ProfileOutlined
            style={{
              ...SideBarStyle.iconStyle,
              paddingLeft: "9%",
              paddingRight: "2%",
            }}
          />{" "}
          {sideBarText.BROWSE_STUDIES}{" "}
        </a>
      </div>

      <div style={{ paddingBottom: "0%" }}>
        <Menu
          inlineIndent={0}
          style={SideBarStyle.cohortMenuStyle}
          mode="inline"
        >
          <SubMenu
            style={{ padding: "0%" }}
            key="sub1"
            title={
              <span style={SideBarStyle.eachMenuStyle}>
                <InboxOutlined style={SideBarStyle.iconStyle} />
                <span
                  style={{
                    ...SideBarStyle.menuStyle,
                    ...SideBarStyle.paddingLeft0,
                    color:
                      props.pgName && props.pgName.page === "my-cohorts"
                        ? "#0065A4"
                        : "#595959",
                  }}
                >
                  {sideBarText.MY_COHORTS}
                </span>
              </span>
            }
          >
            <Menu.ItemGroup key="g1" style={{ ...SideBarStyle.paddingLeft20 }}>
              {cohList && cohList.length > 0
                ? cohList.map((i) => (
                    <Menu.Item key={Object.values(i.id)} style={{}}>
                      <div
                        style={{ border: "none", font: "normal normal normal 12px/24px Arial", width:"100%" }}    
                      >
                        <Button style={{border:"none", padding:"0.5% 0.5% 0.5% 0.5%"}} onClick={()=>deleteCohort(i)}>
                        <CloseOutlined /></Button>
                       <span style={{paddingLeft:"2%"}}> </span>
                       <span onClick={() => cohortClick(i)}> {i.name}{" "} </span>
                      </div>
                    </Menu.Item>
                  ))
                : null}
            </Menu.ItemGroup>
          </SubMenu>
        </Menu>
      </div>

      <div style={{ paddingBottom: "0%", paddingTop:"0%" }}>
        <Menu
          inlineIndent={0}
          style={SideBarStyle.cohortMenuStyle}
          mode="inline"
        >
          <SubMenu
            style={{ padding: "0%" }}
            key="sub1"
            title={
              <span style={SideBarStyle.eachMenuStyle}>
                <LikeOutlined style={SideBarStyle.iconStyle} />
                <span
                  style={{
                    ...SideBarStyle.menuStyle,
                    ...SideBarStyle.paddingLeft0,
                    color:
                      props.pgName && props.pgName.page === "my-req"
                        ? "#0065A4"
                        : "#595959",
                  }}
                >
                  My Requests
                </span>
              </span>
            }
          >
            <Menu.ItemGroup
              key="g1"
              style={{ ...SideBarStyle.paddingLeft20, marginBottom: "0%" }}
            >
              <Menu.Item
                key="I1"
                style={{
                  marginTop: "0%",
                  font: "normal normal normal 12px/24px Arial",
                }}
              >
                <Link to={pageLinks.REQ_BASKET}>
                <ShoppingOutlined
                  style={{ paddingRight: "4%" }}
                />
                {drftReq ? (
                  <span
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "#008000",
                      borderRadius: "50%",
                      display: "inline-block",
                      marginLeft: "0%",
                    }}
                  ></span>
                ) : null}
                {sideBarText.REQUEST_BASKET}
                </Link>
              </Menu.Item>
              <Menu.Item
                key="I2"
                style={{
                  marginTop: "0%",
                  font: "normal normal normal 12px/24px Arial",
                }}
              >
                <Link to={pageLinks.MY_REQUESTS}>
                <LikeOutlined
                  style={{ paddingRight: "4%" }}
                />
                Requests
                </Link>
              </Menu.Item> 
            </Menu.ItemGroup>
          </SubMenu>
        </Menu>
      </div>

      <div style={{ paddingLeft: "1.2%", paddingTop:"6%" }}>
        <a
          style={{
            font: "normal normal normal 14px/24px Arial",
            color: "#595959",
            paddingTop: "4%",
            paddingBottom: "5%",
            borderRadius: "8px",
            width: "83%",
          }}
        >
          <LogoutOutlined
            style={{ ...SideBarStyle.iconStyle, paddingLeft: "9%" }}
          />{" "}
          <span
            onClick={() => onLogout()}
            style={{
              ...SideBarStyle.menuStyle,
              paddingLeft: "1%",
              border: "none",
              backgroundColor: "none",
            }}
          >
            {sideBarText.LOGOUT}
          </span>
        </a>
      </div>

      <div style={{ paddingLeft: "1.2%", paddingTop:"14%" }}>
        <a
          style={{
            font: "normal normal normal 14px/24px Arial",
            color: "#595959",
            paddingTop: "6%",
            paddingBottom: "5%",
            borderRadius: "8px",
            width: "83%",
          }}
          href = "https://ddpstaticweb.z9.web.core.windows.net/"
          target="blank"
        >
          <QuestionOutlined
            style={{ ...SideBarStyle.iconStyle, paddingLeft: "9%" }}
          />{" "}
          <span
            //onClick={() => onLogout()}
            style={{
              ...SideBarStyle.menuStyle,
              paddingLeft: "1%",
              border: "none",
              backgroundColor: "none",
              color: "none"
            }}
          >
            Help
          </span>
          
        </a>
      </div>
    </div>
  );
}
