import React from "react";
import {
  Row,
  Col,
  Button,
  Divider,
  Card,
  Input,
  Modal,
  message,
  Upload,
  Checkbox,
  Spin
} from "antd";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { accessTokenRequest } from "../authConfig";
import {
  ExclamationCircleFilled,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  newStudyText,
  viewStudyText,
  drftReqText,
  statisticsText
} from "../constants/displayText";
import { urls, pageLinks } from "../constants/urls";
import "../styles/StatisticsStyle.css";
import "../styles/ViewStudyStyle.css";
import "../styles/DrftReqStyles.css";
import "../styles/CommonStyles.css";
import { constantValues } from "../constants/constantValues";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;
const { confirm } = Modal;
const { TextArea } = Input;
export default function ReqBask(props) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress, idToken } = useMsal();
  const tokRequest = {
    ...accessTokenRequest,
    account: accounts[0],
  };

  React.useEffect(() => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let query = {
          user_id: localStorage.getItem("userObjId"),
        };
        let config = {
          headers: { Authorization: `Bearer ${response.accessToken}` },
          params: query,
        };
        setLoading(true)
        axios.get(`${apiUrl}/${urls.REQ_BASKET}`, config).then((response) => {
          setLoading(false)
          if (response.status === 200) {
            setReqData(response.data);
            if (Object.keys(response.data).length !== 0 &&
              Object.getPrototypeOf(response.data) === Object.prototype) {
              setNewCohortName(response.data.cohort_name);
              if (response.data.data) {
                setCohortId(response.data.cohort_id);
                let activeStdy =
                  response.data &&
                  response.data.data &&
                  response.data.data.filter((i) => i.disabled !== true);
                setEnabledStdyCnt(activeStdy.length);
                if (response.data.request_data) {
                  setUpdatedFileName(response.data.request_data.file_list);
                  setReqTitle(response.data.request_data.req_title);
                  setReqDesc(response.data.request_data.req_desc);
                  setReqDataUse(response.data.request_data.data_usage);
                  setReqObi(response.data.request_data.analytics);
                  setReqTerms(response.data.request_data.terms);
                  setMobNum(response.data.request_data.mobile_number);
                  setReqRole(response.data.request_data.project_role);
                }

              }
              if (response.data.filter !== null && response.data.filter !== undefined) {
                const updatedArray = response.data.filter.map(group => ({
                  filters: group.filters.map(filter => ({
                    ...filter,
                    filterName: statisticsText.FILTER_NAMES[filter.filterName] || filter.filterName
                  }))
                }));
                console.log("updatedArray", updatedArray)
                setFilters(updatedArray);

              }
            }
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
      })
      .catch((e) => {
        navigate('/')
      })
  }, [props]);

  const [editTitle, setEditTitle] = React.useState(false);
  const [newCohortName, setNewCohortName] = React.useState();
  const [cohortId, setCohortId] = React.useState("");
  const [reqData, setReqData] = React.useState();
  const [reqTitle, setReqTitle] = React.useState("");
  const [reqDesc, setReqDesc] = React.useState("");
  const [reqDataUse, setReqDataUse] = React.useState("");
  const [reqObi, setReqObi] = React.useState(false);
  const [reqTerms, setReqTerms] = React.useState(false);
  const [enabledStdyCnt, setEnabledStdyCnt] = React.useState(0);
  const [updatedFile, setUpdatedFile] = React.useState([]);
  const [updatedFileName, setUpdatedFileName] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [mobNum, setMobNum] = React.useState("");
  const [reqRole, setReqRole] = React.useState("");

  const onSave = (methodName) => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let urlName;
        if (methodName === "save") {
          urlName = urls.SAVE_REQ;
        } else {
          urlName = urls.SEND_REQ;
        }
        const formData = new FormData();
        setLoading(true);
        updatedFile && updatedFile.map((i) => {
          formData.append("files", i);
        });
        formData.append("user_id", localStorage.getItem("userObjId"));
        formData.append("cohort_id", cohortId);
        formData.append("req_title", reqTitle);
        formData.append("req_desc", reqDesc);
        formData.append("data_usage", reqDataUse);
        formData.append("analytics", reqObi);
        formData.append("terms", reqTerms);
        formData.append("mobile_number", mobNum);
        formData.append("project_role", reqRole);
        updatedFileName && updatedFileName.map((i) => {
          formData.append("file_list", i);
        });

        axios
          .post(`${apiUrl}/${urlName}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${response.accessToken}`,
            },
          })
          .then((response) => {
            setLoading(false)
            if (response.status === 200) {
              if (methodName === "save") {
                message.success(`${constantValues.SUCCESS_SAVE_MSG}`);
              } else {
                setReqData({});
                localStorage.setItem('cohList', JSON.stringify(response.data.cohort_list))
                message.success(`${constantValues.SUCCESS_SEND_MSG}`);
                setTimeout(function () {
                  navigate(`${pageLinks.MY_REQUESTS}`);
                }, 3000);
              }
            }
            else {
              message.error(`${constantValues.ERR_MSG}`);
            }
          })
          .catch(() =>{
            console.log("error")
          })
      })
      .catch((e) => {
        navigate('/')
      })
  };

  const onRetrieve = () => {
    instance
      .acquireTokenSilent(tokRequest)
      .then((response) => {
        let config = {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
          params: {
            cohort_id: cohortId,
          },
        };
        axios.delete(`${apiUrl}/${urls.RETRIEVE_COHORT}`, config).then((response) => {
          if (response.status === 200) {
            localStorage.setItem(
              "cohList",
              JSON.stringify(response.data.cohort_list)
            );
            navigate(`${pageLinks.MY_COHORTS}/${cohortId}`);
          }
          else {
            message.error(`${constantValues.ERR_MSG}`);
          }
        })
        .catch(() =>{
          console.log("error")
        })
      })
      .catch((e) => {
        navigate('/')
      })
  };

  const onInpTitle = (event) => {
    setReqTitle(event.target.value);
  };
  const onInpDesc = (event) => {
    setReqDesc(event.target.value);
  };
  const onInpDataUse = (event) => {
    setReqDataUse(event.target.value);
  };
  const onChangeObi = (event) => {
    setReqObi(event.target.checked);
  };
  const onChangeTerms = (event) => {
    setReqTerms(event.target.checked);
  };
  const cardClick = (study) => {
    navigate(`${pageLinks.VW_STUDY}/${Object.values(study._id)}`, {
      state: { details: { ...study } },
    });
  };

  function deleteFile(file) {
    if (updatedFile.length > 0) {
      let fileList = updatedFile.filter((i) => i.name !== file);
      setUpdatedFile([...fileList]);
      let fileListName = updatedFileName.filter((i) => i !== file);
      setUpdatedFileName([...fileListName]);
    } else {
      let fileListName = updatedFileName.filter((i) => i !== file);
      setUpdatedFileName([...fileListName]);
    }
  }
  const uploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },

    beforeUpload: (file) => {
      /*       if (file.type === "application/pdf") { */
      let existingFile = [];
      existingFile = [...updatedFileName];
      let duplicateFile = existingFile.filter((i) => i === file.name);
      if (duplicateFile.length === 0) {
        let existingFileVal = [...updatedFile, file];
        setUpdatedFile(existingFileVal);
        setUpdatedFileName([...existingFile, file.name]);
      } else {
        showConfirm(file);
      }
      /*       } else {
              message.error(constantValues.PDF_FILE_ERROR);
            } */
    },
    onChange: (info) => {
      if (info.file.status === constantValues.DONE) {
        console.log("success");
      }
    },
  };
  const showConfirm = (file) => {
    confirm({
      title: "Overwrite File",
      icon: <ExclamationCircleFilled />,
      content: constantValues.EXISTING_FILE_NAME,
      onOk() {
        let fileList = updatedFile.filter((i) => i.name !== file.name);
        setUpdatedFile([...fileList, file]);
      },
      onCancel() { },
      width: 700,
      centered: true,
    });
  };
  return (
    <div>
      <Col xs={24} xl={24}>
        {reqData &&
          Object.keys(reqData).length === 0 &&
          Object.getPrototypeOf(reqData) === Object.prototype ? (
          <div
            className="showingTextStyle ml-1"
          >
            {" "}
            {drftReqText.NO_REQ}{" "}
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={20} xl={20}>
                <div
                  className="titleStyle pt-1"
                >
                  <span>
                    {" "}
                    {drftReqText.REQUEST} - {newCohortName}{" "}
                  </span>
                </div>
              </Col>

              <Col xs={4} xl={4}>
                <Button
                  type={constantValues.PRIMARY}
                  className="ml-2 mt-8"
                  onClick={() => onSave("save")}
                >
                  {constantValues.SAVE}
                </Button>
                <Button
                  type={constantValues.PRIMARY}
                  className="ml-2 mt-8"
                  onClick={() => onRetrieve()}
                >
                  {drftReqText.RETRIEVE_COHORT}
                </Button>
              </Col>
            </Row>
            <Divider className="dividerStyle m=0" >
              {" "}
            </Divider>

            <Col xs={24} xl={24}>
              <Row>
                <Col xs={12} xl={12}>
                  <Row>
                    <div className="filterStyleReq" >
                      <span className="filterTextStyle" >
                        {drftReqText.FILTERED}:{" "}
                      </span>
                      <span className="studyCntStyle">
                        {reqData ? (
                          <span>
                            {reqData?.filter?.length > 0 ||
                              reqData?.filter_datamodalities?.length > 0 ||
                              reqData?.filter_instrument?.length > 0 ? (
                              <span>
                                {reqData?.filtered_count === "" ||
                                  reqData?.filtered_count === null ||
                                  reqData?.filtered_count === undefined ? (
                                  <span>{reqData?.total_count}</span>
                                ) : (
                                  <span>{reqData?.filtered_count}</span>
                                )}/{reqData?.total_count}{" "}
                                {viewStudyText.STUDIES_NOW}{" "}
                                {reqData?.filtered_count === "" ||
                                  reqData?.filtered_count === null ||
                                  reqData?.filtered_count === undefined ? null : (
                                  <span>
                                    {filters && filters.map((filterGroup, index) => (
                                      <span key={index}>
                                        {index !== 0 ? 'or ' : null}
                                        {filterGroup.filters.map((filter, innerIndex) => (
                                          <span key={innerIndex} className="contentHeadingTextStyle">
                                            {innerIndex !== 0 ? 'and ' : null}
                                            {filter.filterName && <span>{filter.filterName}: </span>}
                                            {filter.operator}{" "}
                                            {filter.value1 && filter.value1.toString().replaceAll("^", ",")}{" "}
                                            {filter.value2 && (
                                              <span>
                                                {constantValues.AND} {filter.value2}
                                              </span>
                                            )}
                                          </span>
                                        ))}
                                      </span>
                                    ))}
                                    <span>
                                      {reqData && reqData.filter_datamodalities && Array.isArray(reqData.filter_datamodalities) ? (
                                        <span>
                                          <span
                                            className="contentHeadingTextStyle"
                                          >
                                            Data Modalities:{" "}
                                          </span>
                                          <span>include </span>
                                          {reqData && reqData.filter_datamodalities.map((i) => (
                                            <span>{i},</span>
                                          ))}{" "}
                                        </span>
                                      ) : null}
                                    </span>
                                    <span>
                                      {reqData && reqData.filter_instrument && Array.isArray(reqData.filter_instrument) ? (
                                        <span>
                                          <span
                                            className="contentHeadingTextStyle"
                                          >
                                            Instrument:{" "}
                                          </span>
                                          <span>include </span>
                                          {reqData && reqData.filter_instrument && reqData.filter_instrument.map((i) => (
                                            <span> {i},</span>
                                          ))}{" "}
                                        </span>
                                      ) : null}
                                    </span>
                                  </span>
                                )}
                                <span>.</span>
                              </span>
                            ) : (
                              <span>
                                {reqData?.total_count}/
                                {reqData?.total_count}{" "}
                                {viewStudyText.STUDIES_NOW}.{" "}
                              </span>
                            )}
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    {reqData?.data.map((i) => (
                      <Col span={12}>
                        <div>
                          {" "}
                          <Card
                            title={i.title}
                            className="cardStyleStatistics"
                            style={{
                              cursor: "pointer",
                              opacity: i.disabled ? 0.3 : 1,
                              border: i.disabled
                                ? "none"
                                : "1px solid #1890FF",
                              marginLeft: "5%",
                              marginTop: "2%",
                              paddingRight: "0%",
                              width: "90%",
                            }}
                            headStyle={{
                              font: "normal normal 600 14px/22px Arial",
                              paddingLeft: "5%",
                              color: "#262626"
                            }}
                            onClick={() => cardClick(i)}
                          >
                            <div className="height100">
                              <div>
                                {" "}
                                <span className="cardSampleStyle">
                                  {reqData?.filters?.length > 0 ||
                                    reqData?.filter_datamodalities?.length > 0 ||
                                    reqData?.filter_instrument?.length > 0 ? "Filtered Sample Size" : newStudyText.SAMPLE_SIZE}
                                </span>{" "}
                                <span>{reqData?.filters?.length > 0 ||
                                  reqData?.filter_datamodalities?.length > 0 ||
                                  reqData?.filter_instrument?.length > 0 ?
                                  reqData?.sample_ids_count
                                    ?.filter(p => p.dataset_id === i.dataset_id && p.release_version === i.release_version)
                                    ?.length > 0 ?
                                    reqData?.sample_ids_count
                                      ?.filter(p => p.dataset_id === i.dataset_id && p.release_version === i.release_version)[0]
                                      ?.participant_count :
                                    i.sample_size :
                                  i.sample_size}</span>
                              </div>
                              {i.description.length > 50 ? (
                                <span>
                                  {i.description.substring(0, 50)}...
                                </span>
                              ) : (
                                <span>{i.description}</span>
                              )}
                            </div>
                            <div className="mt-5">
                              {" "}
                              <span>v{i.release_version}</span>
                              <span
                                className="clinicalInnerStyle"
                              >
                                {i.visibility &&
                                  i.visibility.charAt(0).toUpperCase() +
                                  i.visibility.slice(1)}
                              </span>
                            </div>
                          </Card>{" "}
                        </div>{" "}
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xs={12} xl={12}>
                  <div className="cardOuterStyle">
                    <div className="reqHeaderStyle" style={{ paddingTop: "2%" }}>
                      {drftReqText.REQ_TITLE}
                    </div>
                    <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                      <Input
                        placeholder="Enter request title*"
                        onChange={onInpTitle}
                        value={reqTitle}
                      />
                    </div>
                    <div className="reqHeaderStyle">
                      {drftReqText.REQ_DESC}
                    </div>
                    <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                      <TextArea
                        placeholder={drftReqText.DESC_REQ}
                        onChange={onInpDesc}
                        value={reqDesc}
                      />
                    </div>
                    {/* <div className="reqHeaderStyle">
                      {drftReqText.USE_DATA}
                    </div>
                    <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                      <TextArea
                        placeholder="eg.for pharmaceutical development"
                        onChange={onInpDataUse}
                        value={reqDataUse}
                      />
                    </div> */}
                    <div className="reqHeaderStyle">
                      Mobile Number
                    </div>
                    <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                      <Input
                        placeholder="mobile number"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regex to allow only numeric characters and limit to 10 digits
                          const regex = /^[0-9]{0,20}$/;
                          if (regex.test(inputValue)) {
                            setMobNum(inputValue);
                          }
                        }}
                        value={mobNum}
                      />
                    </div>
                    <div className="reqHeaderStyle">
                      What is your role in the project?
                    </div>
                    <div style={{ marginTop: "1%", marginBottom: "2%" }}>
                      <TextArea
                        placeholder="role in the project"
                        onChange={(e) => setReqRole(e.target.value)}
                        value={reqRole}
                      />
                    </div>
                    <div
                      style={{
                        font: "normal normal 600 16px/24px Arial",
                        color: "#262626",
                        marginBottom: "1%",
                      }}
                    >
                      Please upload the Ethics Approval and your CV
                    </div>
                    <Upload {...uploadProps} showUploadList={false}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <div>
                      {" "}
                      {updatedFileName && updatedFileName.length > 0
                        ? updatedFileName.map((i) => (
                          <div style={{ marginTop: "2%" }}>
                            <Row>
                              <Col
                                xs={20}
                                xl={20}
                                style={{ paddingTop: "2%" }}
                              >
                                <span
                                  style={{
                                    color: "#262626",
                                  }}
                                >
                                  {i}
                                </span>
                              </Col>
                              <Col xs={4} xl={4}>
                                <span>
                                  <Button
                                    onClick={() => deleteFile(i)}
                                    style={{
                                      border: "none",
                                      background: "#F5F5F5",
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        ))
                        : null}
                    </div>
                    <div style={{ color: "#262626", marginTop: "2%" }}>
                      <Checkbox onChange={onChangeObi} checked={reqObi} />{" "}
                      {drftReqText.OBI_COMPUTE}
                    </div>
                    <div style={{ color: "#262626", marginTop: "2%" }}>
                      <Checkbox onChange={onChangeTerms} checked={reqTerms} />{" "}
                      {drftReqText.OBI_TERMS}
                      <a href="https://www.braincode.ca/content/platform-terms-use-agreement" target="_blank">Data Use Agreement</a>
                    </div>
                    <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                      <Button
                        type="primary"
                        disabled={
                          !reqTerms ||
                          /* !reqObi || */
                          reqTitle === "" ||
                          enabledStdyCnt === 0 ||
                          !updatedFile
                        }
                        onClick={() => onSave("send")}
                      >
                        Send Request
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        )}
      </Col>
      <Modal
        closable={false}
        width={null}
        centered={true}
        footer={null}
        title={null}
        open={loading}
      >
        <Spin />
      </Modal>
    </div>
  );
}
